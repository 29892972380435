
import React from 'react';
import { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from '../../components/loading';
import BillForm from './bill-form';
import { useEffect } from 'react';
import { useState } from 'react';
import { getOrder } from '../../resources/api/orders';
import { errorAlert, successAlert } from '../../components/toastr';

const bill = {
    vendor_id: "",
    vendor_reference: "",
    bill_date: '',
    due_date: "",
    purchase_order_id: "",
    isTaxInclusive: true,
    file_id: '',
    client_id: "",
    job_id: "",
    items: [],
}

const NewBill = () => {

    const nav_items = [
        { title: 'Bills', href: '/app/bills' },
        { title: 'New Bill' }
    ];

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(bill);
    const [loaded, setLoaded] = useState(false);
    // const [lockPO, setLockPO] = useState(false);

    const { profile } = useContext(AppUserContext);

    useEffect(() => {
        let vendor_id = searchParams.get('vendor') || "",
            client_id = searchParams.get('client') || "",
            job_id = searchParams.get('job') || "",
            bill_date = searchParams.get('date') || "",
            purchase_order_id = searchParams.get('purchase_order') || "";

        const _details = { ...bill, bill_date, vendor_id, client_id, job_id, purchase_order_id };

        if (purchase_order_id) {

            getOrder(purchase_order_id, ['items'])
                .then(({ order }) => {
                    const _items = order.items.map(i => ({
                        category: i.category,
                        notes: i.notes,
                        unit_price: i.unit_price,
                        quantity: i.quantity,
                        tax_percent: i.tax_percent,
                    }));

                    setDetails({
                        ..._details,
                        isTaxInclusive: order.isTaxInclusive,
                        items: _items, vendor_id: order.vendor_id
                    });

                    successAlert("Loaded the purchase order details");
                    // setLockPO(true);
                })
                .catch(e => errorAlert(`Could not load the purchase order details. Error: ${e}`))
                .finally(() => setLoaded(true))

            //get details of purchase order and load the bill items here including vendor
        } else {
            setDetails(_details);
            setLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (details) => {
        let job_id = searchParams.get('job') || "";

        if (job_id) {
            navigate(`/app/jobs/${job_id}`);
        } else {
            navigate(`/app/bills/${details.id}`);
        }
    }

    if (!loaded) return <PageLoading />;

    if (profile.permission_level !== 1)
        return <ErrorLoading>You do not have the permission to create bills.</ErrorLoading>


    return (
        <>
            <Nav items={nav_items} />
            <BillForm
                details={details}
                action="create"
                onSubmit={handleSubmit}
            />
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/bills').BillObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/bills').BillObject>>} props.setDetails 
 */
const EditBill = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Bills', href: '/app/bills' },
        { title: details.vendor_reference, href: `/app/bills/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/bills/${details.id}`);
    }

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level !== 1) {
        return <ErrorLoading>You do not have the permission to edit bills.</ErrorLoading>
    }


    return (

        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <BillForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { EditBill };
export default NewBill;