import { useContext, useEffect, useState } from 'react';
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
import { HTMLEditor } from '../../components/tinymce';
import { EmailWrapperBottom, EmailWrapperTop, PaymentEmailTemplate } from '../../assets/emails';
import { AppUserContext } from '../../App';
import { PageLoading } from '../../components/loading';
import moment from 'moment';
import cur from '../../components/currency';
import SelectizeInput from '../../components/selectize';
import { getClientContacts } from '../../resources/api/clients';
import { Form, InputGroup } from 'react-bootstrap';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { SubmitButton } from '../../components/btns';
import { useNavigate } from 'react-router-dom';
import { sendPaymentEmail } from '../../resources/api/payments';



/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/payments').PaymentObject} props.details 
 * @returns 
 */
const SendPayment = ({ details }) => {


    const nav_items = [
        { title: 'Payments', href: '/app/payments' },
        { title: details.reference, href: `/app/payments/${details.id}` },
        { title: "Send Email" }
    ];

    const { profile } = useContext(AppUserContext);
    const navigate = useNavigate();
    const [isLoaded, setLoaded] = useState(false);

    const [isSubmitting, setSubmitting] = useState(false);
    const [subject, setSubject] = useState(`Payment Receipt ${details.receipt_no} - Legacy Productions`);
    const [emails, setEmails] = useState([]);
    const [client_contacts, setClientContacts] = useState([]);
    const [ccs, setCCs] = useState([`created_${profile.email}`]);
    const [other_contacts, setOtherContacts] = useState([]);
    const [html, setHTML] = useState(PaymentEmailTemplate);

    useEffect(() =>
        setOtherContacts([{ title: profile.email, id: `created_${profile.email}` }]),
        [profile.email]
    );


    useEffect(() => {

        const inv_replacements = {
            // client_name: "[Client&#39;s Name]",
            receipt_no: "[Insert ID]",
            payment_date: "[Insert Date]",
            method: "[Insert Method]",
            total_amount: "[Insert Amount]",

        }

        const con_replacements = {
            display_name: "[Your Name]",
            job_title: "[Your Position]",
        }
        const _dates = ['payment_date'];
        const _money = ['total_amount'];

        setLoaded(false);

        let _html = PaymentEmailTemplate;

        _html = _html.replace("[Recipient Name]", details.client.title);

        Object.keys(inv_replacements).forEach(e => {

            if (_dates.indexOf(e) !== -1) {
                _html = _html.replace(inv_replacements[e], moment(details[e]).format('DD MMMM YYYY'))
            } else if (_money.indexOf(e) !== -1) {
                _html = _html.replace(inv_replacements[e], cur(details[e], 0).format())
            } else {
                _html = _html.replace(inv_replacements[e], details[e] || inv_replacements[e])
            }

        });

        Object.keys(con_replacements).forEach(e => {
            _html = _html.replace(con_replacements[e], profile[e] || con_replacements[e])
        });

        setHTML(_html);

        getClientContacts(details.client_id)
            .then(({ contact_persons }) => {
                setClientContacts(
                    contact_persons
                        .filter(c => !!c.email)
                        .map(c => ({ title: c.display_name, id: c.id, email: c.email }))
                );
                setEmails(
                    contact_persons
                        .filter(i => i.contact_id === details.job.client_contact_id)
                        .map(c => c.id)
                )
            })
            .finally(() => setLoaded(true));


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.invoice_no, profile.id])

    const submitEmail = () => {

        let _ids = emails.filter(e => !e.startsWith("created_")),
            _emails = client_contacts.filter(c => (_ids.indexOf(c.id) !== -1)).map(c => c.email),
            _created_emails = emails.filter(e => e.startsWith("created_")).map(e => e.replace("created_", ""));


        let _ccs = ccs.filter(e => e.startsWith("created_")).map(e => e.replace("created_", ""));

        const data = {
            to: [..._emails, ..._created_emails],
            cc: _ccs,
            html: `${EmailWrapperTop}${html}${EmailWrapperBottom}`,
            subject
        }

        setSubmitting(true);
        infoAlert("Sending email...");
        sendPaymentEmail(data, details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate(`/app/payments/${details.id}`);
            })
            .catch(e => {
                setSubmitting(false);
                errorAlert(e);
            })


    }

    if (!isLoaded) return <PageLoading />;

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.invoice_no}
                </Title>
            </PageHeader>

            <div className="max-800 p-1">
                {/* <Form.Label className='h5'>To:</Form.Label> */}
                <InputGroup className='my-1 my-sm-2'>
                    <InputGroup.Text className='border-0 bg-white'>To</InputGroup.Text>
                    <SelectizeInput
                        placeholder='Select Emails or create'
                        items={emails}
                        options={client_contacts}
                        onChange={val => setEmails(val.split(","))}
                        addOption={option => setClientContacts(c => c.concat(option))}
                        maxItems={5}
                        create
                    />
                </InputGroup>
                <InputGroup className='my-1 my-sm-2'>
                    <InputGroup.Text className='border-0 bg-white'>CC</InputGroup.Text>
                    <SelectizeInput
                        placeholder='Select Emails or create'
                        items={ccs}
                        options={other_contacts}
                        onChange={val => setCCs(val.split(","))}
                        addOption={option => setOtherContacts(c => c.concat(option))}
                        maxItems={5}
                        create
                    />
                </InputGroup>
                <InputGroup className='my-1 my-sm-2'>
                    <InputGroup.Text className='border-0 bg-white'>Subject</InputGroup.Text>
                    <Form.Control size="sm" className='border-0 bg-white' value={subject} onChange={e => setSubject(e.currentTarget.value)} />
                </InputGroup>

                <hr />
                {/* <Form.Label className='h5'>Email:</Form.Label> */}
                <HTMLEditor
                    value={html}
                    onChange={t => setHTML(t)}
                />

                <hr />

                <SubmitButton isSubmitting={isSubmitting} onClick={submitEmail}>
                    Send Email
                </SubmitButton>

            </div>
        </>
    )


}

export default SendPayment;