import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppUserContext } from '../App';
import { errorAlert } from '../components/toastr';
import { logoutUser } from '../resources/api/auth';

/**
 * 
 * @param {{
 * title: string
 * link: string
 * active: boolean
 * }} param0
 */
const NavItem = ({ title, link, children, active }) => (
    <li className={"nav-item" + (active ? " active" : "")}>
        <Link to={link} className="nav-link" title={title}>
            {children}
            <span className="nav-text">{title}</span>
        </Link>
    </li>
)

const MobileNavItem = ({ to, active, children }) => (
    <Link className={"flex-fill text-center nav-link" + (active ? " active" : "")} to={to}>
        {children}
    </Link>
)

/**
 * menu of the app
 */
const Menu = ({ logoutUser: signOut }) => {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuExpanded, setMenuExpanded] = useState(true);
    const [active, setActive] = useState('');

    const { profile } = useContext(AppUserContext);
    const navigate = useNavigate();


    const location = useLocation();

    const handleClick = e => {
        e.preventDefault();
        setMenuOpen(d => !d);
    }

    const handleExpand = e => {
        e.preventDefault();
        setMenuExpanded(d => !d);
    }

    const handleLogout = (e) => {
        e.preventDefault();
        logoutUser()
            .then(() => {
                signOut();
                navigate("/");
            })
            .catch((err) => errorAlert(err))
    }

    /**
     * change the status of the side nav whenever toggled. 
     */
    useEffect(() => {
        if (!isMenuExpanded) {
            document.body.classList.add('sidenav-toggled')
        }
        else document.body.classList.remove('sidenav-toggled');
    }, [isMenuExpanded])

    /**
     * close the nav whenver a navigation takes place.
     */
    useEffect(() => {
        setActive(location.pathname.split('/')[2] || '');
        setMenuOpen(false);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [location.pathname])

    /**
     * addition class when toggled. 
     */
    const additionalClass = isMenuOpen ? ' toggled' : '';

    const imgsrc = profile.image ?
        "" :
        `https://eu.ui-avatars.com/api/?name=${profile.other_names}+${profile.last_name}&size=128`

    return (
        <div>
            <nav className="nav nav-pills flex-row fixed-bottom menubar">
                <MobileNavItem active={(active === 'dashboard') || !active} to="/app">
                    <i className="fas fa-home fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'inventory'} to="/app/inventory">
                    <i className="fas fa-store fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'jobs'} to="/app/jobs">
                    <i className="fas fa-briefcase fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'invoices'} to="/app/invoices">
                    <i className="fas fa-file-invoice fa-fw"></i>
                </MobileNavItem>
                <MobileNavItem active={active === 'payments'} to="/app/payments">
                    <i className="fas fa-credit-card fa-fw"></i>
                </MobileNavItem>
                <a className="flex-fill text-center nav-link text-white" href="#." id="toggleMenu" onClick={handleClick}>
                    <i className="fas fa-bars fa-fw"></i>
                </a>
            </nav>

            <nav className={`vertical-nav ${additionalClass}`} id="sidebar">

                <div className='d-flex flex-column justify-content-center align-items-center logo'>
                    <img src="/logo/logo-white.png" width={65} alt="company logo" />
                </div>

                <div className="d-flex profile text-light align-items-center" style={{ backgroundColor: "rgba(255,255,255,0.2)" }}>
                    <img src={imgsrc} alt="..." width="60" className="rounded-circle img-thumbnail shadow-sm" />
                    <div className="ms-2 media-body">
                        <h5 className="m-0 font-weight-bold">{profile.display_name}</h5>
                    </div>
                </div>

                <ul className="nav link-container d-flex flex-column mb-0">

                    <NavItem link="/app"
                        title="Dashboard"
                        active={['dashboard', ''].indexOf(active) !== -1}
                    >
                        <i className="fas fa-home fa-fw" />
                    </NavItem>

                    <div className="my-2" />

                    <h6 className='mx-2 mb-1'>Sales</h6>

                    <NavItem link="/app/clients"
                        title="Clients"
                        active={active === 'clients'}
                    >
                        <i className="fas fa-users fa-fw" />
                    </NavItem>

                    <NavItem link="/app/jobs"
                        title="Jobs"
                        active={active === 'jobs'}
                    >
                        <i className="fas fa-briefcase fa-fw" />
                    </NavItem>

                    <NavItem link="/app/invoices"
                        title="Invoices"
                        active={active === 'invoices'}
                    >
                        <i className="fas fa-file-invoice fa-fw" />
                    </NavItem>

                    <NavItem link="/app/payments"
                        title="Payments Received"
                        active={active === 'payments'}
                    >
                        <i className="fas fa-credit-card fa-fw" />
                    </NavItem>

                    <div className="my-2" />
                    <h6 className='mx-2 mb-1'>Purchases</h6>

                    <NavItem link="/app/vendors"
                        title="Vendors"
                        active={active === 'vendors'}
                    >
                        <i className="fas fa-helmet-safety fa-fw" />
                    </NavItem>

                    <NavItem link="/app/expenses"
                        title="Expenses"
                        active={active === 'expenses'}
                    >
                        <i className="fas fa-coins fa-fw" />
                    </NavItem>

                    <NavItem link="/app/purchase-orders"
                        title="Purchase Orders"
                        active={active === 'purchase-orders'}
                    >
                        <i className="fas fa-shop fa-fw" />
                    </NavItem>

                    <NavItem link="/app/bills"
                        title="Bills"
                        active={active === 'bills'}
                    >
                        <i className="fas fa-file-invoice-dollar fa-fw" />
                    </NavItem>

                    <NavItem link="/app/bill-payments"
                        title="Payments Made"
                        active={active === 'bill-payments'}
                    >
                        <i className="fas fa-receipt fa-fw" />
                    </NavItem>


                    <div className="my-2" />
                    <h6 className='mx-2 mb-1'>Operations</h6>


                    <NavItem link="/app/requisitions"
                        title="Requisitions"
                        active={active === 'requisitions'}
                    >
                        <i className="fas fa-circle-dollar-to-slot fa-fw" />
                    </NavItem>


                    <NavItem link="/app/payroll"
                        title="Payroll"
                        active={active === 'payroll'}
                    >
                        <i className="fas fa-wallet fa-fw" />
                    </NavItem>

                    <NavItem link="/app/accounts"
                        title="Accounts"
                        active={active === 'accounts'}
                    >
                        <i className="fas fa-vault fa-fw" />
                    </NavItem>


                    <div className="my-2" />

                    <h6 className='mx-2 mb-1'>Inventory</h6>

                    <NavItem link="/app/inventory"
                        title="Inventory"
                        active={active === 'inventory'}
                    >
                        <i className="fas fa-store fa-fw" />
                    </NavItem>

                    <NavItem link="/app/pricelist"
                        title="Price List"
                        active={active === 'pricelist'}
                    >
                        <i className="fas fa-list fa-fw" />
                    </NavItem>

                    <div className="my-2" />
                    <h6 className='mx-2 mb-1'>Admin</h6>

                    <NavItem link="/app/files"
                        title="Resource Centre"
                        active={active === 'files'}
                    >
                        <i className="far fa-folder-open fa-fw" />
                    </NavItem>

                    <NavItem link="/app/users"
                        title="Users"
                        active={active === 'users'}
                    >
                        <i className="fas fa-users fa-fw" />
                    </NavItem>

                    <NavItem link="/app/me"
                        title="My Profile"
                        active={active === 'me'}
                    >
                        <i className="fas fa-user-cog fa-fw" />
                    </NavItem>

                    <div className="my-2" />

                    <li className="nav-item">
                        <a href="#." onClick={handleLogout} className="nav-link" title="Log Out">
                            <i className="fas fa-sign-out-alt fa-fw" />
                            <span className="nav-text">Log Out</span>
                        </a>
                    </li>

                </ul>

                <div className="nav-toggler">
                    <a href="#." className="nav-link w-100 text-center px-0 text-light" id="sidenavToggler" onClick={handleExpand}>
                        <i className="fas fa-arrow-circle-left fa-fw fa-2x"></i>
                    </a>
                </div>
            </nav>
        </div>
    )
}

export default Menu;