import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { ListGroup, Table } from 'react-bootstrap';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import style from './expenses.scss';
import { useEffect, useState, useContext } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
import { fileIcons, nonChoiceExpenseCategories } from '../../assets/constants';
import { changeRequisitionStatus, deleteDocument, getRequisition, uploadDocument } from '../../resources/api/requisitions';
import { EditRequisition } from './requisition-manipulation';
import { ConfirmAction } from '../../components/prompt';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { AppUserContext } from '../../App';
import { APIURL } from '../../resources/fetch';
import { FileUpload } from '../../components/files';
// import { APIURL } from '../../resources/fetch';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/requisitions').RequisitionObject} props.details
 * 
 */
const ViewRequisition = ({ details, setDetails }) => {

    const { profile } = useContext(AppUserContext);

    const nav_items = [
        { title: 'Requisitions', href: '/app/requisitions' },
        { title: details.reference }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    const changeStatus = (action) => {

        let status;

        switch (action) {
            case "approve":
                status = "approved"
                break;

            case "reject":
                status = "draft"
                break;

            case "submit":
                status = "awaiting-approval"
                break;

            default:
                errorAlert("unknown action...");
                return;
        }

        infoAlert("Changing the requisition status ...");

        changeRequisitionStatus(details.id, status)
            .then(({ requisition, message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, ...requisition }));
            })
            .catch(e => errorAlert(e))
    }


    const handleDocUpload = (document) => setDetails(d => ({ ...d, documents: d.documents.concat(document) }))

    const handleDocDelete = id => setDetails(d => ({ ...d, documents: d.documents.filter(dd => dd.id !== id) }));

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.reference} {!!details.user_reference && <>({details.user_reference})</>}
                </Title>
                <ActionBar>

                    {/* <ButtonToolbar>
                        <Btn title="Download PDF" href={`${APIURL}/requisitions/${details.id}/pdf`} isExternalLink>
                            <i className="fas fa-file-pdf" />
                        </Btn>
                    </ButtonToolbar> */}

                    {(details.status === "draft") &&
                        <ButtonToolbar>
                            <ConfirmAction
                                as={Btn}
                                title="Submit for Approval"
                                handleAction={() => changeStatus('submit')}
                                confirmationText='Are you sure you want to submit this requisition for approval?'
                            >
                                <i className="fas fa-upload" />
                            </ConfirmAction>
                        </ButtonToolbar>
                    }

                    {((details.status === "awaiting-approval") && (profile.permission_level < 2)) &&
                        <ButtonToolbar>
                            <ConfirmAction
                                as={Btn}
                                title="Revert to Draft"
                                handleAction={() => changeStatus('reject')}
                                confirmationText='Are you sure you want to reject & revert this requisition to draft status?'
                            >
                                <i className="fas fa-times-circle" />
                            </ConfirmAction>

                            <ConfirmAction
                                as={Btn}
                                title="Approve"
                                handleAction={() => changeStatus('approve')}
                                confirmationText='Are you sure you want to approve this requisition?'
                            >
                                <i className="fas fa-check-circle" />
                            </ConfirmAction>
                        </ButtonToolbar>
                    }

                    <ButtonToolbar>
                        {(details.status === "draft") &&
                            <Btn href={`/app/requisitions/${details.id}/edit`} title="Edit Requisition">
                                <i className="fas fa-pencil-alt" />
                            </Btn>}
                        <Btn href="/app/requisitions/new" title="New Requisition">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-800 p-1 expenses">

                <div className="text-end">
                    USER: <span style={style1}>{details.contact.display_name}</span> <br />
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    REQUISITION DATE: <span style={style1}>{moment(details.requisition_date).format("DD MMMM YYYY")}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span><br />
                </div>

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <div className="mt-3">
                    <h6>Description</h6>
                    <p>{details.description}</p>
                </div>



                <Table size="sm" borderless style={{ maxWidth: '600px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "40%" }} />
                        <col span="1" style={{ width: "60%" }} />
                    </colgroup>

                    <tbody>
                        {(!!details.client_id) &&
                            <tr>
                                <td>Client</td>
                                <td>
                                    <Link to={`/app/clients/${details.client_id}`}>{details.client.title}</Link>
                                </td>
                            </tr>
                        }
                        {(!!details.job_id) &&
                            <tr>
                                <td>Job</td>
                                <td>
                                    <Link to={`/app/jobs/${details.job_id}`}>{`${details.job.job_no} - ${details.job.location}`}</Link>
                                </td>
                            </tr>
                        }
                        <tr>
                            <td>Approved</td>
                            <td>
                                {!details.isApproved ?
                                    "Not Approved" :
                                    <span>By {details.approver.display_name} on {moment(details.date_approved).format("DD MMMM YYYY")}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Paid</td>
                            <td>
                                {!details.isPaid ?
                                    "Not Paid" :
                                    <span>On {moment(details.date_paid).format("DD MMMM YYYY")}</span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>

                {(details.items.length > 0) && <RequisitionItems items={details.items} />}

                {(nonChoiceExpenseCategories.indexOf(details.category) !== -1) &&
                    <p className='lead text-info fw-bold'>To view the breakdown go to payroll.</p>}




                <ViewDocuments documents={details.documents || []} onDocUpload={handleDocUpload} onDocDelete={handleDocDelete} requisition_id={details.id} />

            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/requisitions').RequisitionItemObject[]} props.items 
 * @returns 
 */
const RequisitionItems = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Items</h5>

        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "45%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "22%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Category</th>
                    <th className='text-start'>Notes</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td className='text-start'>{UCWords(i.category.replace(/-/g, " "))}</td>
                        <td className='text-start' style={{ fontSize: "95%", lineHeight: "1.1" }}>{i.notes}</td>
                        <td>{i.quantity}</td>
                        <td>{cur(i.unit_price).format()}</td>
                        <td>{cur(i.amount).format()}</td>
                    </tr>
                ))}
            </tbody>

        </Table>

    </div>
)

const ViewDocuments = ({ documents, onDocUpload, onDocDelete, requisition_id }) => {

    const deleteDoc = (id) => {
        infoAlert("Deleting Document...");

        deleteDocument(requisition_id, id)
            .then(({ message }) => {
                successAlert(message);
                onDocDelete(id);
            })
            .catch(errorAlert)
    }

    const uploadDoc = doc => {

        infoAlert("Attaching document to expense...");

        uploadDocument(requisition_id, { file_id: doc.id })
            .then(({ document }) => {
                onDocUpload(document);
            })
            .catch(errorAlert)
    }


    return (
        <>
            <h4 className="mt-3 mt-md-5">Documents</h4>

            {(documents.length === 0) &&
                <>
                    <p className="lead">
                        No documents have been uploaded for this expense.
                    </p>
                </>
            }
            <ListGroup variant="flush">
                {documents.map(d => (
                    <ListGroup.Item key={d.id} className="d-flex align-items-center">

                        <i className={`${fileIcons[d.file_group]} fa-3x me-3 me-sm-4`} />
                        <div>
                            <h6 className="mb-1">{d.file_title}</h6>
                            {/* <p className="mb-0 text-muted">
                                {UCWords(d.category.replace(/-/g, " "))}
                            </p> */}
                            <p className="mb-1 text-muted">
                                {d.display_name} | {moment(d.date_added).format("DD MMM YYYY, HH:mm")}
                            </p>
                            <div className="hstack gap-3 small">
                                <a href={`${APIURL}/files/${d.file_slug}/view`} className="text-secondary" target={`_blank`}>
                                    <i className="fas fa-eye me-1" />View
                                </a>
                                <a href={`${APIURL}/files/${d.file_id}/download`} className="text-primary" target={`_blank`}>
                                    <i className="fas fa-download me-1" />Download
                                </a>
                                <ConfirmAction
                                    as={"a"}
                                    handleAction={() => deleteDoc(d.id)}
                                    href="#."
                                    className="text-danger"
                                    target="_blank"
                                    confirmationText="Are you sure you want to delete this document?"
                                >
                                    <i className="fas fa-trash-alt me-1" />Delete
                                </ConfirmAction>
                            </div>
                        </div>

                    </ListGroup.Item>
                ))}
            </ListGroup>

            <div className="my-2">
                <FileUpload
                    size="sm"
                    variant=""
                    className="rounded-pill"
                    onUpload={uploadDoc}
                >
                    <i className='fas fa-upload me-1' />Upload Document
                </FileUpload>
            </div>
        </>
    )
}

const RequisitionDetails = () => {

    const { requisitionid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getRequisition(requisitionid, ['client', 'job', 'items', 'contact', 'approver', 'documents'])
            .then(({ requisition }) => {
                setDetails(requisition);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [requisitionid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading requisition details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditRequisition details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewRequisition details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default RequisitionDetails;