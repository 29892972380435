import Nav from '../../components/breadcrumb';
// import PageHeader, { Title } from './../../components/pageheader';
import PaymentForm from "./payment-form";

import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { PageLoading } from '../../components/loading';

const paymentObject = {
    client_id: "", payment_date: "", items: [], account_id: "", method: "", remarks: "", payee: "", paid_to: "", currency: "UGX", exchange_rate: 1
}

const NewPayment = () => {

    const nav_items = [
        { title: 'Payments', href: '/app/payments' },
        { title: 'New Payment' }
    ];

    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(paymentObject);
    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        let client_id = searchParams.get('client') || "",
            job_id = searchParams.get('job') || "";
        const _details = { ...details, client_id, job_id };

        setDetails(_details);
        setLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (details) => {

        let _return = searchParams.get('return') || "";

        if (_return) {
            const [module, id] = _return.split('|');
            navigate(`/app/${module}/${id}`);
        } else {
            navigate(`/app/payments/${details.id}`);
        }
    }


    if (!loaded) return <PageLoading />;

    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <PaymentForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/payments').PaymentObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/payments').PaymentObject>>} props.setDetails 
 */
const EditPayment = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Payments', href: '/app/payments' },
        { title: details.reference, href: `/app/payments/${details.id}` },
        { title: 'Edit Details' }
    ];


    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/payments/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            {/* <PageHeader maxWidth="800">
                <Title>{details.payment_no}</Title>
            </PageHeader> */}

            <div className="max-800 p-2">
                <PaymentForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewPayment, EditPayment };