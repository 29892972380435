import { APIURL, postData, getData, deleteData } from "../fetch";

/**
 * 
 * @typedef {{
 *  id: string
 *  allowance_id: string
 *  staff_id: string
 *  display_name: string 
 *  amount: number 
 *  transaction_date: string
 * }} AllowanceItemObject 
 */


/**
 * 
 * @typedef {{
 *  id: string,
 *  reference: string
 *  transaction_date: string
 *  expense_id: string 
 *  expense_date: string|null
 *  period_date: string
 *  period_year: number
 *  period_month: number
 *  total_amount: number 
 *  category: string 
 *  isTaxed: boolean 
 *  status: string
 *  items: AllowanceItemObject[]
 * }} AllowanceObject 
 */




/**
 * 
 * @param {AllowanceObject} data 
 * @returns {Promise<{code: number, message: string, allowance: AllowanceObject}>}
 */
const createAllowance = (data) => {
    return postData({
        url: `${APIURL}/allowances`,
        data
    })
}

/**
 * 
 * @param {AllowanceObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, allowance: AllowanceObject}>}
 */
const updateAllowance = (data, id) => {
    return postData({
        url: `${APIURL}/allowances/${id}`,
        data
    })
}

/**
 * 
 * @param {AllowanceObject} data 
 * @returns {Promise<{code: number, message: string, allowances: AllowanceObject[]}>}
 */
const getAllowances = () => getData({ url: `${APIURL}/allowances` })



/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, allowance: AllowanceObject}>}
 */
const getAllowance = (id, includes = null) => {

    const obj = { url: `${APIURL}/allowances/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {"draft"|"awaiting-approval"|"approved"} status 
 * @returns  {Promise<{code: number, message: string, allowance: AllowanceObject}>}
 */
const changeAllowanceStatus = (id, status) => postData({ url: `${APIURL}/allowances/${id}/status/${status}` });


/**
 * 
 * @param {{account_id: string, method: string}} data 
 * @param {string} id idof the allowance
 * @returns {Promise<{code: number, message: string, allowance: AllowanceObject}>}
 */
const executePayments = (data, id) => postData({ url: `${APIURL}/allowances/${id}/pay`, data })


/**
 * @param {string} id ID 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteAllowance = id => deleteData({ url: `${APIURL}/allowances/${id}` });


export {
    createAllowance,
    getAllowance,
    getAllowances,
    updateAllowance,
    changeAllowanceStatus,
    executePayments,
    deleteAllowance
}