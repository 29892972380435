import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from './../../components/pageheader';
import { Form, Table, Card, Row, Col } from 'react-bootstrap';
import { SortButton } from './../../components/btns';
import { useState, useEffect, createElement, Fragment } from 'react';
import PagePagination, { usePagination } from './../../components/pagination';
import moment from 'moment';
import cur, { UGX } from './../../components/currency';
import { Link, useSearchParams } from 'react-router-dom';
import { sortFunction, UCWords } from './../../components/resources';
import { useScreenType } from './../../components/custom-hooks';
import { getExpenses } from '../../resources/api/expenses';
import { ErrorLoading, PageLoading } from '../../components/loading';

/**
 * View table items on screens larger than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/expenses').ExpenseObject[]
 * currentPage: number
 * noPageItems: number
 * totals: number
 * }} param0 
 */
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems, totals }) => {
    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            <colgroup>
                {/* <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "17%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "18%" }} /> */}
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Ref <SortButton field="reference" {...srt} handleSort={handleSort} /></th>
                    <th>Date <SortButton field="expense_date" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Tax <SortButton field="tax_amount" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Amount <SortButton field="total_amount" {...srt} handleSort={handleSort} /></th>
                    <th>Account <SortButton field="account" {...srt} handleSort={handleSort} /></th>
                    <th>Client <SortButton field="client" {...srt} handleSort={handleSort} /></th>
                    <th>Job <SortButton field="job" {...srt} handleSort={handleSort} /></th>
                    <th>Vendor <SortButton field="vendor" {...srt} handleSort={handleSort} /></th>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td><Link to={`/app/expenses/${e.id}`}>{e.reference}</Link></td>
                        <td>{moment(e.expense_date).format("DD MMM YYYY")}</td>
                        <td style={{ textAlign: 'right' }}>{cur(e.tax_amount, 0).format()}</td>
                        <td style={{ textAlign: 'right' }}>{cur(e.total_amount, 0).format()}</td>
                        <td>{UCWords(e.account.replace(/-/g, " "))}</td>
                        <td>{!!e.client_id ?
                            <Link to={`/app/clients/${e.client_id}`}>{e.client}</Link> : 'n/a'}</td>
                        <td>{!!e.job_id ?
                            <Link to={`/app/jobs/${e.job_id}`}>{e.job}</Link> : 'n/a'}</td>
                        <td>{!!e.vendor ?
                            createElement(
                                !!e.vendor_id ? Link : Fragment,
                                !!e.vendor_id ? { to: `/app/vendors/${e.vendor_id}` } : "",
                                e.vendor
                            ) : 'n/a'}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={4} />
                    <th className='text-end'>
                        {cur(totals, 0).format()}
                    </th>
                    <th colSpan={4} />
                </tr>
            </tfoot>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/expenses').ExpenseObject[]
 * }} param0 
 */
const ViewMobile = ({ srt, handleSort, items }) => {
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Reference <SortButton field="reference" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Date <SortButton field="expense_date" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Tax <SortButton field="tax_amount" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Total <SortButton field="total_amount" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Client <SortButton field="client" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Job <SortButton field="job" {...srt} handleSort={handleSort} />
                </span>
                <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                    Vendor <SortButton field="vendor" {...srt} handleSort={handleSort} />
                </span>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-3">
                            {!!e.vendor ?
                                createElement(
                                    !!e.vendor_id ? Link : "span",
                                    { className: "text-muted", to: !!e.vendor_id ? `/app/vendors/${e.vendor_id}` : "" },
                                    e.vendor
                                ) : <span />}
                            <span className="text-muted">{moment(e.expense_date).format("DD MMM YYYY")}</span>
                        </div>

                        <div>
                            <Link to={`/app/expenses/${e.id}`} className="lead text-secondary fw-normal stretched-link">
                                {e.reference} {!!e.user_reference ? <>({UCWords(e.user_reference)})</> : ""}
                            </Link>
                            <h4 className="h4">{UGX(e.total_amount).format()}</h4>
                            <span className="d-block">
                                {UCWords(e.account.replace(/-/g, " "))} via {UCWords(e.method.replace(/-/g, " "))}
                            </span>
                        </div>

                        {!!e.client &&
                            <div className="d-flex justify-content-between mt-3">
                                <span className="text-muted fw-normal">
                                    <Link to={`/app/clients/${e.client_id}`}>{e.client}</Link> | <Link to={`/app/jobs/${e.job_id}`}>{e.job}</Link>
                                </span>
                            </div>
                        }
                    </Card.Body>
                </Card>
            ))
            }
        </div >
    )
}


const ViewExpenses = () => {


    const [srt, setSrt] = useState({
        currentField: 'expense_date',
        currentOrder: 'desc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'reference' });

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const [searchParams] = useSearchParams();
    const [navItems, setNavItems] = useState([{
        title: 'Expenses'
    }]);


    const [totals, setTotals] = useState(0);

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);


    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") {
            setTotals(orginalItems.reduce(
                (p, c) => p + c.total_amount, 0
            ));
            return setItems(orginalItems);
        };

        let view_items = [...orginalItems.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)];
        setItems(view_items);

        setTotals(view_items.reduce(
            (p, c) => p + c.total_amount, 0
        ));

    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        setLoaded(false);
        setError(null);

        let conditions = null,
            vendor_id = searchParams.get("vendor");

        if (vendor_id) conditions = { vendor_id };

        getExpenses(true, conditions)
            .then(({ expenses }) => {

                if (expenses.length > 0) {

                    if (vendor_id) {
                        let vendor = expenses[0].vendor;
                        setNavItems([{ title: "Expenses", href: `/app/expenses` }, { title: vendor }]);
                    }
                    else {
                        setNavItems([{ title: "Expenses" }]);
                    }

                    expenses.sort((a, b) => sortFunction(a, b, "expense_date", "desc"));
                    expenses = expenses.map(e => ({ ...e, vendor: (e.vendor || e.vendor_old) }));
                    setOriginalItems(expenses);
                    setItems(expenses);
                }


            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [searchParams])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => {
            if (srt.currentOrder === 'asc') {
                return sortFunction(a, b, srt.currentField, "asc");
            }
            return sortFunction(a, b, srt.currentField, "desc");;
        }

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }


    if (!loaded) return <PageLoading>Loading all expenses...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1200">
                <Title>Expenses</Title>
                <ActionBar>
                    {/* <ButtonToolbar>
                        <Btn onClick={onDownloadClick}>
                            <i className="fas fa-download me-1" />Download
                        </Btn>
                    </ButtonToolbar> */}
                    <ButtonToolbar>
                        <Btn href="/app/expenses/new">
                            <i className="fas fa-plus-circle me-1" />New Expense
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1200">

                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="category">Category</option>
                            <option value="reference">Reference</option>
                            <option value="user_reference">User Reference</option>
                            <option value="status">Status</option>
                            <option value="expense_date">Date</option>
                        </Form.Select>
                    </Col>

                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} totals={totals} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>

        </>
    )
}


export default ViewExpenses;