export const unconfirmedJobStatuses = [
    "open",
    'processing',
    "awaiting-client",
    "awaiting-deposit",
    "awaiting-confirmation"
]

export const uncompletedJobStatuses = [
    "confirmed",
    "processed",
    "delievered"
]

export const completedJobStatuses = [
    "completed",
    "closed",
]


export const confirmedJobStatuses = [
    ...uncompletedJobStatuses,
    ...completedJobStatuses
]

export const cancelledJobStatuses = [
    'cancelled',
]


export const JobStatuses = [
    ...unconfirmedJobStatuses,
    ...confirmedJobStatuses,
    ...cancelledJobStatuses
]

export const EventCategories = [
    "awards",
    "breakfast-meeting",
    "cocktail",
    "concert",
    "conference",
    "corporate",
    "dinner",
    "events-management",
    "exhibition",
    "function-hire",
    "launch",
    "luncheon",
    "meeting",
    "online-broadcast",
    "party",
    "private",
    "wedding",
]

export const PricelistCategories = [
    "PA Systems",
    "Delegates Mics",
    "LED Screens",
    "Video Livefeed",
    "Plasma TV",
    "Stage Platform",
    "Lighting and Effects",
    "Trussing",
    "Podium",
    "Lounge Seats",
    "Live Stream",
    "Graphics and Animations",
    "Fuel and Generator",
    "Exhibition Booths",
    "Projector and Screens",
    "Crew and Transport",
    "Photo and Video",
    "Decor",
    "Ushers",
    "Tents"
]


export const EventTypes = [
    "audio-production",
    "visual-production",
    "audio-visual",
    "broadcast",
    "event-equipment-hire",
    "tents",
    "events-management",
    "other"
]

export const InventoryCategories = [
    "projection",
    "sound",
    "lighting",
    "stage"
]

export const InventoryUnits = [
    "pieces",
    "boxes",
    "cans"
]

export const AdjustmentTypes = [
    "retirement",
    "loss"
]


export const EventColourMaping = {
    "green": [25, 135, 84],
    "yellow": [255, 193, 7],
    "orange": [253, 126, 20],
    "teal": [32, 201, 151],
    "purple": [111, 66, 193],
    "blue": [13, 110, 253],
    "sky_blue": [13, 202, 240],
    // "gray": [108, 117, 125],
    "red": [220, 53, 69],
    "pink": [214, 51, 132],
    "indigo": [102, 16, 242]
}

export const EventColours = Object.keys(EventColourMaping).sort();

export const expenseCategories = [
    "airtime-and-commuunication",
    "bank-charges",
    "capital-investments",
    "cleaning",
    "commissions",
    "consultations",
    "detergents",
    "donations",
    "education-and-training",
    "electricity",
    "facilitation",
    "food",
    "fuel",
    "gifts-and-donations",
    "insurance",
    "internet",
    "IT-services",
    "medical",
    "miscellaneous",
    "non-business",
    "office",
    "other",
    "printing",
    "product-hire",
    "promotions",
    "refund",
    "renovations",
    "rent",
    "repairs",
    "stationary",
    "statutory-dues",
    "taxes",
    "tent-repairs",
    "toilet-cleaning",
    "training-and-seminars",
    "transport-facilitation",
    "utilities",
    "vehicle-rentals",
    "vehicle-repairs",
    "vehicle-service",
    "water"
]

export const nonChoiceExpenseCategories = [
    "allowances",
    "advances",
    "wages-salaries",
]

export const allExpenseCategories = [
    ...expenseCategories,
    ...nonChoiceExpenseCategories
];

export const expenseStatuses = [
    "non-billable",
    "unbilled",
    "invoiced"
];

export const paymentMethods = [
    "bank-transfer",
    "cash",
    "cheque",
    "credit-card",
    "direct-debit",
    "mobile-payment"
]

export const taxes = [
    { title: "VAT", value: 18 }
]

export const financialAccounts = [
    "DFCU",
    "BoA",
    "stanbic",
    "centenary",
    "petty-cash",
    "undeposited-funds"
]

export const AccountTypes = [
    "bank",
    "mobile-money",
    "cash"
]

export const assortedPaymentMethods = {
    "bank-transfer": ["bank", "mobile-money"],
    "cash": ["cash"],
    "cheque": ["bank"],
    "credit-card": ["bank"],
    "direct-debit": ["bank"],
    "mobile-payment": ["bank", "mobile-money"]
}


export const DefaultTerms = [
    "Accounts are due on demand.",
    "All payments made to LEGACY PRODUCTIONS LIMITED.",
    "The booking will be confirmed after 50% deposit payment is done.",
    "Event activation will only be done after 100% payment is made by the event day.",
    "This quotation is not inclusive of any taxes.",
    "Event service delivery only after issuance of an LPO from the client.",
    "Invoice must be paid within 7 days after tax invoicing."
]

export const DefaultNotes = [
    "Payments to be made to: \nAccount Name: Legacy Productions Limited (UGX). \nBank: Diamond Trust Bank, Oasis Branch. \nAccount No: 0026463001",
    "Payments to be made to: \nAccount Name: Legacy Productions Limited (UGX). \nBank: Equity Bank, Bugolobi Branch. \nAccount No: 1009201254092",
    "Payments to be made to: \nAccount Name: Legacy Productions Limited (USD). \nBank: Equity Bank, Bugolobi Branch. \nAccount No: 1009201359375"
]

export const jobDocumentCategories = [
    'effris',
    'invoice',
    'lpo',
    'confirmation',
    'other'
];

export const fileIcons = {
    pdfs: "far fa-file-pdf",
    images: "fas fa-file-image",
    sheets: "fas fa-file-excel",
    docs: "fas fa-file-word",
    slides: "fas fa-file-powerpoint",
    videos: "fas fa-file-video",
    audios: "fas fa-file-audio",
}

export const staffTypes = [
    "casual",
    "permanent",
    "consultant"
]

export const allowanceCategories = [
    "overtime",
    "operational",
    "weekend"
]

export const payrollTypes = {
    wages: {
        isManual: true,
        requiresDuration: false,
        definedRates: true,
        title: "Wages",
        doOvertime: false,
        expenseCategory: "wages-salaries"
    },
    salary: {
        isManual: true,
        requiresDuration: false,
        definedRates: true,
        title: "Monthly Salaries",
        doOvertime: false,
        expenseCategory: "wages-salaries"
    },
    allowance: {
        isManual: true,
        requiresDuration: false,
        definedRates: false,
        title: "Allowances",
        doOvertime: false,
        expenseCategory: "allowances"
    },
    advance: {
        isManual: true,
        requiresDuration: true,
        definedRates: false,
        title: "Advances",
        doOvertime: false,
        expenseCategory: "advances"
    },
}


export const payrollPaymentStatus = [
    "open", "paritally-processed", "processed", "paid"
]


export { PricelistCategories as JobDeliverables }