
import React from 'react';
import { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from '../../components/loading';
import OrderForm from './order-form';
import { useEffect } from 'react';
import { useState } from 'react';

const order = {
    order_date: '',
    isTaxInclusive: true,
    quotation_file_id: '',
    vendor_id: "",
    items: [],
}

const NewOrder = () => {

    const nav_items = [
        { title: 'Purchase Orders', href: '/app/purchase-orders' },
        { title: 'New Order' }
    ];

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(order);
    const [loaded, setLoaded] = useState(false);

    const { profile } = useContext(AppUserContext);

    useEffect(() => {
        let vendor_id = searchParams.get('vendor') || "";
        const _details = { ...order, vendor_id };

        setDetails(_details);
        setLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (details) => navigate(`/app/purchase-orders/${details.id}`);



    if (!loaded) return <PageLoading />;

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to create orders.</ErrorLoading>
    }

    return (
        <>
            <Nav items={nav_items} />

            <OrderForm
                details={details}
                action="create"
                onSubmit={handleSubmit}
            />
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/orders').OrderObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/orders').OrderObject>>} props.setDetails 
 */
const EditOrder = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Purchase Orders', href: '/app/purchase-orders' },
        { title: details.order_no, href: `/app/purchase-orders/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/purchase-orders/${details.id}`);
    }

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level !== 1) {
        return <ErrorLoading>You do not have the permission to edit orders.</ErrorLoading>
    }


    return (

        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <OrderForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { EditOrder };
export default NewOrder;