/* eslint-disable react-hooks/exhaustive-deps */
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.bootstrap3.css';
import $ from 'jquery';
import 'selectize';
import { useEffect, useRef } from 'react';

/**
 * Issues. 
 * 1. Only uses options provided on rendering. If options changes then it does not add the new options. 
 * i.e options must be fetched before rendering the items.
 * 2. Same as above, when an option is created, it passes it up. But since it does not re-render 
 * on option change therefore other inputs on the page using the same options will not get the new options.
 * @param {{
 * items: string[],
 * options: {id: string, title: string, description: string, [any: string]: any}[],
 * onChange: (value: string) => void,
 * maxItems: number,
 * required: boolean,
 * placeholder: string, 
 * create: boolean | (value: string) => void, 
 * addOption: (item: {id: string, title: string}) => void
 * }} props 
 */
const SelectizeInput = ({ items, options, onChange, maxItems, create, addOption, required, placeholder }) => {

    const element = useRef(null);

    useEffect(() => {

        if ((options.length === 0 && !create) || element == null) return;

        let q = $(element.current).selectize({
            persist: true,
            maxItems: maxItems || 1,
            options: options,
            items: items || [],
            labelField: 'title',
            sortField: 'title',
            valueField: 'id',
            searchField: ['title', 'description'],
            onChange: onChange,
            // onOptionAdd: (value, data) => addOption(data),
            render: {
                item: function (item, escape) {
                    return '<div>' +
                        (item.title ? '<span class="name">' + escape(item.title) + '</span>' : '') +
                        '</div>';
                },
                option: function (item, escape) {
                    var label = item.title;
                    var caption = item.description ? item.description : null;
                    return '<div class="p-1">' +
                        '<span class="label">' + escape(label) + '</span>' +
                        (caption ? '<span class="caption d-block small text-muted">' + caption + '</span>' : '') +
                        '</div>';
                }
            },
            onOptionAdd: (value, data) => {
                addOption({ id: data.id, title: data.title })
            },
            create: create ? function (input) {
                let item = {
                    id: "created_" + input.replace(/ /g, '_'),
                    title: input
                };
                return item;

            } : false
        })


        return () => {

            let sel = q[0].selectize;
            sel.off('change', onChange);
            sel.destroy();
        }

    }, [create]);

    //later use compare two arrays, options to determine if the options have change. 
    //then either add the options or remove options.

    return (
        <>
            <input type="text" className="form-control"
                placeholder={placeholder || "Images"} ref={element} required={required || false} disabled={options.length === 0 && !create} />
        </>
    )
}

export default SelectizeInput;


