import moment from "moment";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { UCWords, sortFunction } from "../../components/resources";
import { getInventoryItem } from "../../resources/api/inventory";
import { AdjustmentForm, PurchaseForm } from "./inventory-forms";
import { EditInventory } from "./inventory-manipulation";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { deletePurchase } from "../../resources/api/inventory-purchases";
import { deleteAdjustment } from "../../resources/api/inventory-adjustments";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/inventory").InventoryItem} props.details
 * @param {(purchase: import("../../resources/api/inventory").PurchaseItem) => void} props.onPurchaseUpload
 * @param {(id: string) => void} props.onPurchaseDelete
 * @param {(purchase: import("../../resources/api/inventory").AdjustmentObject) => void} props.onAdjustmentUpload
 * @param {(id: string) => void} props.onAdjustmentDelete
 */
const ViewDetails = ({ details, onPurchaseUpload, onPurchaseDelete, onAdjustmentUpload, onAdjustmentDelete }) => {

    const nav_items = [
        { title: 'Inventory', href: "/app/inventory" },
        { title: details.name }
    ]

    const [purcahse_show, setPurchaseShow] = useState(false);
    const [item_details, setItemDetails] = useState({});
    const [purchase_action, setPurchaseAction] = useState("create");



    const [adjustmentShow, setAdjustmentShow] = useState(false);
    const [aitem_details, setAItemDetails] = useState({});
    const [a_action, setAAction] = useState("create");

    const handleNewClick = () => {
        setItemDetails({ expense_id: "", delivery_date: "", quantity: 0 });
        setPurchaseAction("create");
        setPurchaseShow(true);
    }

    const handleEditClick = id => {
        let _item = details.purchases.find(p => p.id === id);
        setItemDetails(_item);
        setPurchaseAction("edit");
        setPurchaseShow(true);
    }

    const handleNewAClick = () => {
        setAItemDetails({ adjustment_date: "", job_id: "", quantity: 0, adjustment_type: "" });
        setAAction("create");
        setAdjustmentShow(true);
    }

    const handleEditAClick = id => {
        let _item = details.adjustments.find(p => p.id === id);
        setAItemDetails(_item);
        setAAction("edit");
        setAdjustmentShow(true);
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.name}</Title>
                <ActionBar>
                    <ButtonToolbar >
                        <Btn title="Add Purchase" onClick={handleNewClick}>
                            <i className="fas fa-cart-plus" />
                        </Btn>
                        <Btn title="Make Adjusment" onClick={handleNewAClick}>
                            <i className="fas fa-minus-circle" />
                        </Btn>
                    </ButtonToolbar>
                    {/* <ButtonToolbar >
                        <Btn title="Activate">
                            <i className="fas fa-check-circle" />
                        </Btn>
                        <Btn title="Deactivate">
                            <i className="fas fa-ban" />
                        </Btn>
                    </ButtonToolbar> */}
                    <ButtonToolbar>
                        <Btn href={`/app/inventory/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/inventory/new" title="New Item">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Details</h4>
                <dl>
                    <dt>Unit</dt>
                    <dd>{details.unit}</dd>
                    <dt>Catetory</dt>
                    <dd>{details.category}</dd>
                    <dt>Opening Units</dt>
                    <dd>{details.opening_units}</dd>
                    <dt>Available Units</dt>
                    <dd>{details.available_units}</dd>
                    {/* <dt>No Jobs</dt>
                    <dd>{details.no_jobs}</dd> */}
                </dl>

                <ViewPurchases purchases={details.purchases} onEditClick={handleEditClick} onDelete={onPurchaseDelete} />
                <ViewAdjustments adjustments={details.adjustments} onEditClick={handleEditAClick} onDelete={onAdjustmentDelete} />

                <PurchaseForm
                    item_id={details.id}
                    onUpload={onPurchaseUpload}
                    purchaseDetails={{ item_details, setItemDetails }}
                    show={{ purcahse_show, setPurchaseShow }}
                    action={purchase_action}
                />
                <AdjustmentForm
                    purchaseDetails={{ aitem_details, setAItemDetails }}
                    show={{ adjustmentShow, setAdjustmentShow }}
                    action={a_action}
                    onUpload={onAdjustmentUpload}
                    item_id={details.id}
                />
            </div>

        </>
    )

}


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/inventory").PurchaseItem[]} props.purchases 
 * @returns 
 */
const ViewPurchases = ({ purchases, onEditClick, onDelete }) => {

    if (purchases.length === 0) return <></>

    return (
        <>
            <h4 className="mt-3 mt-md-5">Purchases</h4>
            <Table responsive hover size="sm" style={{ maxWidth: "700px", minWidth: "350px" }}>
                <thead>
                    <tr>
                        <th>Delivery Date</th>
                        <th className="text-center">Quantity</th>
                        <th>Expense</th>
                        <th>...</th>
                    </tr>
                </thead>
                <tbody>
                    {purchases.map(e => (
                        <tr key={e.id}>
                            <td>{moment(e.delivery_date).format("DD MMM YYYY")}</td>
                            <td className="text-center">{e.quantity}</td>
                            <td><Link to={`/app/expenses/${e.expense_id}`} >{e.expense}</Link></td>
                            <td>
                                <a href="#." className="mx-1 text-secondary" onClick={() => onEditClick(e.id)}><i className="fas fa-pencil-alt" /></a>
                                <a href="#." className="mx-1 text-danger" onClick={() => onDelete(e.id)}><i className="fas fa-times-circle" /></a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/inventory").AdjustmentObject[]} props.adjustments 
 * @returns 
 */
const ViewAdjustments = ({ adjustments, onEditClick, onDelete }) => {

    if (adjustments.length === 0) return <></>

    return (
        <>
            <h4 className="mt-3 mt-md-5">Adjustments</h4>
            <Table responsive hover size="sm" style={{ maxWidth: "700px", minWidth: "350px" }}>
                <thead>
                    <tr>
                        <th>Adjustment Date</th>
                        <th className="text-center">Quantity</th>
                        <th>Reason</th>
                        <th>Job</th>
                        <th>...</th>
                    </tr>
                </thead>
                <tbody>
                    {adjustments.map(e => (
                        <tr key={e.id}>
                            <td>{moment(e.adjustment_date).format("DD MMM YYYY")}</td>
                            <td className="text-center">{e.quantity}</td>
                            <td>{UCWords(e.adjustment_type)}</td>
                            <td>{e.job_id && <Link to={`/app/jobs/${e.job_id}`}>{e.job}</Link>}</td>
                            <td>
                                <a href="#." className="mx-1 text-secondary" onClick={() => onEditClick(e.id)}><i className="fas fa-pencil-alt" /></a>
                                <a href="#." className="mx-1 text-danger" onClick={() => onDelete(e.id)}><i className="fas fa-times-circle" /></a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}



const InventoryItemDetails = () => {

    const { itemid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);

        const sortFn1 = (a, b) => sortFunction(a, b, 'delivery_date', "asc");
        const sortFn2 = (a, b) => sortFunction(a, b, 'adjustment_date', "asc");

        getInventoryItem(itemid, ['purchases', 'adjustments'])
            .then(({ item }) => {

                const { purchases, adjustments } = item;
                purchases.sort(sortFn1);
                adjustments.sort(sortFn2);

                setDetails({ ...item, purchases, adjustments });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [itemid])

    const handlePurchaseUpload = (purchase, action = "edit") => {

        if (action === "create") {
            setDetails(d => ({ ...d, purchases: d.purchases.concat(purchase) }))
        } else {
            let purchases = [...details.purchases];

            purchases.splice(
                purchases.findIndex(p => p.id === purchase.id),
                1,
                purchase
            );
            setDetails(d => ({ ...d, purchases }))
        }
    }

    const handlePurchaseDelete = id => {
        infoAlert("Deleting purchase...");

        deletePurchase(id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, purchases: d.purchases.filter(p => p.id !== id) }));
            })
            .catch(errorAlert)
    }

    const handleAdjustmentUpload = (adjustment, action = "edit") => {

        if (action === "create") {
            setDetails(d => ({ ...d, adjustments: d.adjustments.concat(adjustment) }))
        } else {
            let adjustments = [...details.adjustments];

            adjustments.splice(
                adjustments.findIndex(p => p.id === adjustment.id),
                1,
                adjustment
            );
            setDetails(d => ({ ...d, adjustments }))
        }
    }

    const handleAdjustmentDelete = id => {
        infoAlert("Deleting adjustment...");

        deleteAdjustment(id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, adjustments: d.adjustments.filter(p => p.id !== id) }));
            })
            .catch(errorAlert)
    }

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/edit" element={<EditInventory details={details} setDetails={setDetails} />} />
            <Route path="/" element={
                <ViewDetails details={details}
                    onPurchaseUpload={handlePurchaseUpload}
                    onPurchaseDelete={handlePurchaseDelete}
                    onAdjustmentUpload={handleAdjustmentUpload}
                    onAdjustmentDelete={handleAdjustmentDelete}
                />
            } />
        </Routes>
    )

}


export default InventoryItemDetails;