import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { error as errorAlert, successAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { PageLoading } from '../../components/loading';
import { Select } from '../../components/select';
import { useMemo } from 'react';
import cur from '../../components/currency';
import { getAllStaff } from '../../resources/api/payroll';
import { UCWords } from '../../components/resources';
import moment from 'moment';
import { createAllowance, updateAllowance } from '../../resources/api/allowances';
import { allowanceCategories } from '../../assets/constants';

const { Control: { Feedback }, Label } = Form;

const wokerObject = {
    staff_id: "",
    amount: 0
}



/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import("../../resources/api/allowances").AllowanceObject} props.details
 */
const PaymentForm = ({ details: labourObject, action, onSubmit }) => {


    const [details, setDetails] = useState(labourObject);
    const [workers, setWorkers] = useState(labourObject.items || []);
    const [staff, setStaff] = useState([]);

    const [deleted_workers, setDeletedWorkers] = useState([]);

    const [loaded, setLoaded] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const months = useMemo(() => {
        return Array(12).fill(0).map((e, i) => e + i).map(n => ({ value: n + 1, title: moment([2023, n]).format("MMM") }));
    }, [])

    const years = useMemo(() => {
        return Array(3).fill(parseInt(moment().format("YYYY")) - 1).map((e, i) => (e + i));
    }, [])


    const workers_list = useMemo(() => {
        const _workers = workers.map(i => i.staff_id);
        return staff.filter(c => _workers.indexOf(c.id) === -1);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workers.length, staff.length])

    const addWorkers = ids => {
        const _workers = staff
            .filter(w => ids.indexOf(w.id) !== -1)
            .map(w => ({
                ...wokerObject,
                rate: w.pay_rate,
                amount: 0,
                staff_id: w.id,
                isNew: 1,
                display_name: w.display_name
            })
            );


        setWorkers([...workers.concat(_workers)])
    }

    const updateWorker = (field, value, idx) => {

        const _workers = [...workers];
        let _w = _workers[idx];
        _w = { ..._w, [field]: value, hasChanged: 1 };

        _workers.splice(idx, 1, _w);
        setWorkers(_workers);
    }


    const deleteWorker = idx => {

        const _workers = [...workers],
            [_w] = _workers.splice(idx, 1);

        if ('id' in _w) {
            setDeletedWorkers(w => w.concat(_w.id));
        }
        setWorkers(_workers);
    }


    useEffect(() => {
        getAllStaff(true)
            .then(({ staff }) => {
                setStaff(
                    staff
                        .filter(s => s.isActive)
                        .map(c => ({
                            ...c,
                            title: c.display_name,
                            description: `${c.email || ""} ${c.telephone || ""} | ${UCWords(c.staff_type)} staff`
                        }))
                );

                setWorkers(workers => workers.map(w =>
                ({
                    ...w,
                    rate: staff.find(s => s.id === w.staff_id).pay_rate,
                    amount: 0
                })));
            })
            .catch(e => errorAlert(e))
            .finally(() => setLoaded(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }


        if (workers.length < 1) {
            errorAlert("You must select at a least staff member for this event.", "Form Errors");
            return;
        }


        setValidated(false);
        setSubmitting(true);

        let promise;

        const data = {
            ...details,
            period_date: moment([details.period_year, (details.period_month - 1), 1]).format("YYYY-MM-DD"),
        }

        if (action === "create") {
            promise = createAllowance({
                ...data,
                allowance_items: workers
            });
        }
        else {
            promise = updateAllowance({
                ...data,
                new_items: workers
                    .filter(p => !!p.isNew),
                updated_items: workers.filter(p => (!p.isNew && !!p.hasChanged)),
                deleted_items: deleted_workers
            }, details.id);
        }

        promise
            .then(({ allowance, message }) => {
                successAlert(message);
                onSubmit(allowance);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    if (!loaded) return <PageLoading>Loading staff...</PageLoading>;

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Period Year</Label>
                    <Form.Select
                        value={details.period_year}
                        onChange={e => setDetails({ ...details, period_year: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {years
                            .map(t => <option value={t} key={t}>{t}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Please choose a year
                    </Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Period Month</Label>
                    <Form.Select
                        value={details.period_month}
                        onChange={e => setDetails({ ...details, period_month: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {months
                            .map(t => <option value={t.value} key={t.value}>{t.title}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Please choose a month
                    </Feedback>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <Form.Select
                        value={details.category}
                        onChange={e => setDetails({ ...details, category: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {allowanceCategories
                            .map(t => <option value={t} key={t}>{UCWords(t.replace(/-/g, " "))}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Select a category for these allowances
                    </Feedback>
                </Col>
            </Row>


            <h4 className="form-section-label">Employees</h4>
            {workers.length === 0 &&
                <Form.Text as={"div"} className="my-2 lead">
                    No staff added yet. Add a staff to continue.
                </Form.Text>}

            <Row className="d-none d-sm-flex">
                <Col sm={6}>
                    <Label className="form-field-title">Name</Label>
                </Col>
                <Col sm={6}>
                    <Label className="form-field-title">Amount</Label>
                </Col>
            </Row>

            {
                workers.map((w, idx) => (
                    <div key={idx} className="my-4 my-sm-0 g-2 border-start border-primary ps-1" style={{ borderLeftWidth: '3px' }}>
                        <Row key={idx} className="g-2">
                            <Col sm={6}>
                                <Label className="form-field-title d-sm-none">Name</Label>
                                <Form.Control
                                    value={w.display_name}
                                    readOnly
                                    size="sm"
                                />
                            </Col>


                            <Col sm={6}>
                                <Label className="form-field-title d-sm-none">Amount</Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        size="sm"
                                        value={w.amount}
                                        onChange={e => updateWorker("amount", e.currentTarget.value, idx)}
                                        type="number"
                                        step={0.5}
                                        min={0}
                                        required
                                    />
                                    <Button
                                        variant="outline-danger"
                                        className="border-0"
                                        onClick={() => deleteWorker(idx)}
                                    >
                                        <i className="fas fa-times" />
                                    </Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>
                ))
            }

            <Row className="mt-4 flex-column-reverse flex-sm-row">

                <Col sm={6}>
                    <Select
                        className='rounded-pill'
                        size="sm"
                        maxItems={null}
                        onSelect={addWorkers}
                        items={workers_list}
                    >
                        <i className="fas fa-plus-circle me-2" />Add Staff
                    </Select>
                </Col>
                <Col sm={6} className="fw-normal text-end" style={{ fontSize: "2rem" }}>
                    {cur(workers.reduce((p, c) => (p + (parseFloat(c.amount) || 0)), 0)).format()}
                </Col>

            </Row>

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Payment" : "Submit Payment"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default PaymentForm;