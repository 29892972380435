import { APIURL, getData, postData } from "../fetch";

/**
 * 
 * @typedef {{
 * id: string
 * name: string 
 * description: string
 * unit: string
 * category: string
 * opening_units: number 
 * available_units: number
 * no_jobs: number
 * date_added: string
 * added_by: string
 * updated_by: string 
 * date_updated: string
 * adjustments: AdjustmentObject[]
 * purchases: PurchaseItem[]
 * }} InventoryItem 
 */

/**
 * 
 * @typedef {{
 * id: string
 * item_id: string 
 * quantity: number
 * expense_id: string
 * delivery_date: string
 * expense: string|import("./expenses").ExpenseObject
 * date_added: string
 * added_by: string
 * updated_by: string 
 * date_updated: string
 * }} PurchaseItem 
*/

/**
 * 
 * @typedef {{
 * id: string
 * item_id: string 
 * quantity: number
 * adjustment_type: "retired"|"loss"
 * adjustment_date: string
 * date_added: string
 * added_by: string
 * updated_by: string 
 * date_updated: string
 * }} AdjustmentObject 
 */



/**
 * 
 * @param {InventoryItem} data 
 * @returns {Promise<{code: number, message: string, item: InventoryItem}>}
 */
const createItem = (data) => {
    return postData({
        url: `${APIURL}/inventory`,
        data
    })
}

/**
 * @param {InventoryItem} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, item: InventoryItem}>}
 */
const updateItem = (data, id) => {
    return postData({
        url: `${APIURL}/inventory/${id}`,
        data
    })
}

/**
 * @returns {Promise<{code: number, message: string, inventory: InventoryItem[]}>}
 */
const getInventory = () => getData({ url: `${APIURL}/inventory` })

/**
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, item: InventoryItem}>}
 */
const getInventoryItem = (id, includes = null) => {

    const obj = { url: `${APIURL}/inventory/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


export {
    createItem,
    getInventoryItem,
    getInventory,
    updateItem
}