import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Routes, Route, useParams, Link, useNavigate } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { UGX } from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { ConfirmAction } from "../../components/prompt";
import { sortFunction, } from "../../components/resources";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { deleteVendorContact, deleteVendor, getVendor } from "../../resources/api/vendors";
import { EditVendor } from "./vendor-manipulation";
import ContactPersonForm from "./contact-person-form";
import ViewStatement from "./statement";
import { AppUserContext } from "../../App";


const ContactPersonObject = {
    other_names: "", last_name: "", email: "", telephone: "", position: ""
}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/vendors").VendorObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import("../../resources/api/vendors").VendorObject>>} props.setDetails
 */
const ViewDetails = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Vendors', href: "/app/vendors" },
        { title: details.title }
    ]

    const [formShow, setFormShow] = useState(false);
    const [form_details, setFormDetails] = useState(ContactPersonObject);
    const [formAction, setFormAction] = useState("create");

    const { profile } = useContext(AppUserContext);
    const navigate = useNavigate();


    const handleDelete = () => {

        infoAlert('Deleting vendor, please hold...');

        deleteVendor(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/vendors");
            })
            .catch(e => errorAlert(e));
    }

    /**
     * open form to create new contact person
     */
    const createNewContactPerson = () => {
        setFormDetails(ContactPersonObject);
        setFormAction("create");
        setFormShow(true);
    }

    /**
     * On Click of edit contct person, open the form
     * @param {string} id 
     * @param {React.MouseEvent} e 
     */
    const editContactPerson = (id, e, isPosition = false) => {
        e.preventDefault();

        const item = details.contact_persons.find(i => (i.id === id));

        setFormDetails(item);
        setFormAction(isPosition ? "position" : "edit");
        setFormShow(true);
    }

    /**
     * Add new contact person to the list
     * @param {import("../../resources/api/vendors").ContactPersonObject} details 
     */
    const handleContactCreate = contact_person => {
        setDetails(d => ({ ...d, contact_persons: d.contact_persons.concat(contact_person) }));
    }

    /**
     * Update list after API call
     * @param {import("../../resources/api/vendors").ContactPersonObject} details 
     */
    const handleContactUpdate = (contact_person) => {
        let contact_persons = [...details.contact_persons];
        contact_persons.splice(
            contact_persons.findIndex(c => (c.id === contact_person.id)),
            1,
            contact_person
        );

        setDetails(d => ({ ...d, contact_persons }));

    }

    /**
     * Delete a contact person
     * @param {string} id 
     */
    const deleteContactPerson = (id) => {
        if (details.contact_persons.length < 2)
            return errorAlert("Client must have at least one contact person. First create another one before deleting this one.");

        infoAlert("Deleting contact person...");

        deleteVendorContact(id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({
                    ...d,
                    contact_persons: d.contact_persons.filter(c => (c.id !== id))
                }));
            })
            .catch(errorAlert)
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title><span dangerouslySetInnerHTML={{ __html: details.title }} /></Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn href={`/app/vendors/${details.id}/statement`} title="View Statement">
                            <i className="fas fa-list" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/purchase-orders/new?vendor=${details.id}`} title="New Purchase Order">
                            <i className="fas fa-shop" />
                        </Btn>
                        <Btn href={`/app/bills/new?vendor=${details.id}`} title="New Bill">
                            <i className="fas fa-file-invoice" />
                        </Btn>
                        <Btn href={`/app/bill-payments/new?vendor=${details.id}`} title="New Payment">
                            <i className="fas fa-coins" />
                        </Btn>
                    </ButtonToolbar>
                    {(
                        (profile.permission_level === 1)
                    ) &&
                        <ButtonToolbar>
                            <ConfirmAction
                                as={Btn}
                                title="Delete Vendor"
                                confirmationText="Are you sure you want to delete this vendor? Please ensure first that vendor has no bills, purchase orders or payments."
                                handleAction={handleDelete}
                            >
                                <i className="fas fa-trash-alt" />
                            </ConfirmAction>
                        </ButtonToolbar>

                    }
                    <ButtonToolbar>
                        <Btn href={`/app/vendors/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/vendors/new" title="New Vendor">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader >

            <div className="max-1000">
                <h4>Vendor Details</h4>
                <dl>
                    <dt>Category</dt>
                    <dd>{details.category || "n/a"}</dd>
                    <dt>Address</dt>
                    <dd>{details.address}</dd>
                    <dt>Payables</dt>
                    <dd>{UGX(details.payables).format()}</dd>
                    <dt>
                        Contact Persons
                        <Button
                            variant="link"
                            size="sm"
                            className="ms-2 text-secondary"
                            onClick={createNewContactPerson}
                        >
                            <i className="fas fa-circle-plus" />
                        </Button>
                    </dt>
                    <dd>
                        {(details.contact_persons.length === 0) && <>No contact Persons</>}
                        {details.contact_persons.map(p => (
                            <span className="d-block" key={p.id}>
                                {p.display_name} | {p.position} | T:{p.telephone} {!!p.email && ` | E: ${p.email}`}
                                <a href="#." className="ms-1 ms-sm-2 px-1 small text-primary" onClick={(e) => editContactPerson(p.id, e)}>
                                    <i className="fas fa-pencil-alt" />
                                </a>
                                <a href="#." className="ms-1 ms-sm-2 px-1 small text-secondary" onClick={(e) => editContactPerson(p.id, e, true)}>
                                    <i className="fas fa-user-pen" />
                                </a>
                                <ConfirmAction
                                    as="a"
                                    href="#."
                                    className="ms-1 px-1 small text-danger"
                                    confirmationText="Are you sure you want to delete this contact person?"
                                    handleAction={() => deleteContactPerson(p.id)}
                                >
                                    <i className="fas fa-trash-alt" />
                                </ConfirmAction>
                            </span>
                        ))}
                    </dd>
                </dl>

                <div className="mt-3 mt-sm-4 lead fw-normal">
                    View:
                    <Link to={`/app/expenses?vendor=${details.id}`} className="mx-2 fw-bold">Expenses</Link>|
                    <Link to={`/app/purchase-orders?vendor=${details.id}`} className="mx-2 fw-bold">Purchase Orders</Link>|
                    <Link to={`/app/bills?vendor=${details.id}`} className="mx-2 fw-bold">Bills</Link>|
                    <Link to={`/app/bill-payments?vendor=${details.id}`} className="mx-2 fw-bold">Payments</Link>
                </div>

                <ContactPersonForm
                    vendorId={details.id}
                    action={formAction}
                    details={{ details: form_details, setDetails: setFormDetails }}
                    onCreate={(formAction !== "create") ? handleContactUpdate : handleContactCreate}
                    toShow={{ show: formShow, setShow: setFormShow }}

                />

            </div>

        </>
    )

}


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/bills").BillObject[]} props.bills 
 * @returns 
 */
// const ViewBills = ({ bills }) => {

//     if (bills.length === 0) return <></>

//     return (
//         <>
//             <h4 className="mt-3 mt-md-5">Bills</h4>
//             <Table responsive hover size="sm" style={{ maxWidth: "800px", minWidth: "500px" }}>
//                 <thead>
//                     <tr>
//                         <th>Reference</th>
//                         <th>Vendor Ref</th>
//                         <th>Date</th>
//                         <th>Due</th>
//                         <th className="text-end">Total Amount</th>
//                         <th className="text-end">Balance</th>
//                         <th>Status</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {bills.slice(-10).map(e => (
//                         <tr key={e.id}>
//                             <td>
//                                 <Link to={`/app/bills/${e.id}`} >{e.reference}</Link>
//                             </td>
//                             <td>{e.user_reference}</td>
//                             <td>{moment(e.bill_date).format("DD MMM YYYY")}</td>
//                             <td>{moment(e.due_date).format("DD MMM YYYY")}</td>
//                             <td className="text-end">{cur(e.total_amount, 0).format()}</td>
//                             <td className="text-end">{cur(e.balance, 0).format()}</td>
//                             <td>{UCWords(e.status?.replace(/-/g, " "))}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//                 <tfoot>
//                     <tr>
//                         <th colSpan={4}>Total</th>
//                         <th className="text-end">{cur(bills.reduce((p, c) => p + c.total_amount, 0), 0).format()}</th>
//                         <th className="text-end">{cur(bills.reduce((p, c) => p + c.balance, 0), 0).format()}</th>
//                         <th />
//                     </tr>
//                 </tfoot>
//             </Table>
//         </>
//     )
// }



const VendorDetails = () => {

    const { vendorid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);

        const sortFn = (a, b) => sortFunction(a, b, 'bill_date', "desc");

        getVendor(vendorid, ['contact_persons'])
            .then(({ vendor }) => {

                const bills = [...(vendor.bills || [])];
                bills?.sort(sortFn);

                setDetails({ ...vendor, bills });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [vendorid])

    //load events details

    if (!isLoaded) return <PageLoading />;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/statement" element={<ViewStatement details={details} />} />
            <Route path="/edit" element={<EditVendor details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default VendorDetails;