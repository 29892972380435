import { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';

/**
 * 
 * @param {{
 * setPage(page: number) => void,
 * no_pages: number,
 * page: number,
 * maxDisplay?: number 
 * }} props 
 */
const PagePagination = ({ setPage, no_pages, page, maxDisplay = 3, ...props }) => {

    const [start, setStart] = useState(1);

    /**
     * on change on page, update the first page before ellipsis.
     */
    useEffect(() => {
        const s = Math.round(page - ((maxDisplay - 1) / 2)),
            p = s < 1 ? 1 : (s > (no_pages - maxDisplay + 1) ? (no_pages - maxDisplay + 1) : s);

        setStart(p < 1 ? 1 : p);

    }, [page, no_pages, maxDisplay])

    return (
        <div {...props}>
            <Pagination className="justify-content-end">
                <Pagination.First
                    disabled={no_pages === 1 || page === 1}
                    onClick={() => setPage(1)}
                />
                <Pagination.Prev
                    disabled={no_pages === 1 || page === 1}
                    onClick={() => setPage(page - 1)}
                />
                {start > 1 &&
                    <Pagination.Ellipsis
                        onClick={() => setStart(s => (s - maxDisplay < 1 ? 1 : s - maxDisplay))}
                    />
                }
                {Array(maxDisplay > no_pages ? no_pages : maxDisplay).fill(start).map((e, i) => (
                    <Pagination.Item key={i} active={page === e + i} onClick={() => setPage(e + i)}>{e + i}</Pagination.Item>
                ))}
                {(start + maxDisplay) <= no_pages &&
                    <Pagination.Ellipsis
                        onClick={() => setStart(s => (s + 2 * maxDisplay > no_pages + 1 ? no_pages - maxDisplay + 1 : s + maxDisplay))}
                    />}
                <Pagination.Next
                    disabled={no_pages === 1 || page === no_pages}
                    onClick={() => setPage(page + 1)}
                />
                <Pagination.Last
                    disabled={no_pages === 1 || page === no_pages}
                    onClick={() => setPage(no_pages)}
                />
            </Pagination>
        </div>
    )

}

/**
 * Input number of items and display items per page. 
 * Returns the page, number of pages and setPage function to change the page in array in that order.
 * @param {number} no_items 
 * @param {number} no_display_items 
 */
const usePagination = (no_items, no_display_items) => {

    const [page, setPage] = useState(1);
    const [no_pages, setNoPages] = useState(1);

    useEffect(() => {

        if (no_items === 0) {
            setPage(1);
            setNoPages(1);
            return;
        }

        const page_count = Math.ceil(no_items / no_display_items);
        setPage(p => p > page_count ? page_count : p);
        setNoPages(page_count);

    }, [no_items, no_display_items])

    return [page, no_pages, setPage];
}

export {
    usePagination
}

export default PagePagination;