import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Form, Button, InputGroup } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../components/btns";
import { errorAlert, successAlert } from "../../components/toastr";
import { createVendorContact, updateContactPosition, updateVendorContact } from "../../resources/api/vendors";

/**
 * 
 * @param {Object} props
 * @param {"create"|"edit"|"position"} props.action 
 * @param {string} props.clientId 
 * @param {{
 *  details: import('../../resources/api/vendors').ContactPersonObject,
 *  setDetails: React.Dispatch<React.SetStateAction<import('../../resources/api/vendors').ContactPersonObject>>
 * }} props.details
 * @param {(details: import("../../resources/api/vendors").ContactPersonObject) => void} props.onCreate
 * @param {{
*  show: boolean
*  setShow: React.Dispatch<React.SetStateAction<boolean>>
* }} props.toShow
* @returns 
*/
const ContactPersonForm = ({
    vendorId,
    details: PersonDetails,
    onCreate,
    toShow,
    action = "create"
}) => {



    const { details, setDetails } = PersonDetails;
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const { show, setShow } = toShow;

    const formref = useRef();

    const handleClose = () => {
        setDetails({ other_names: "", last_name: "", email: "", telephone: "", position: "" });
        setSubmitting(false);
    }

    const handleSubmit = e => {

        e.preventDefault();

        if (!formref.current.checkValidity()) {
            setValidated(true);
            errorAlert("There are some errors with your inputs.");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "edit") {
            promise = updateVendorContact(details, details.id);
        } else if (action === "position") {
            promise = updateContactPosition(details, details.id);
        } else {
            promise = createVendorContact({ ...details, vendor_id: vendorId });
        }

        promise
            .then(({ message, contact_person }) => {
                setShow(false);
                onCreate(contact_person);
                successAlert(message);
            })
            .catch(errorAlert)
            .finally(() => setSubmitting(false))
    }

    useEffect(() => {
        if (!show) setValidated(false);
    }, [show])

    return (
        <Modal show={show} onExit={handleClose} centered size="sm" >
            <Modal.Header className="bg-secondary text-light">
                <h4 className="mb-0">Edit Details</h4>
            </Modal.Header>
            <Modal.Body className="pt-1">
                <Form ref={formref} noValidate validated={validated} >
                    {(action !== "position") &&
                        <Row className="g-2">
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Other Names</Form.Label>
                                <Form.Control
                                    value={details.other_names}
                                    onChange={e => setDetails({ ...details, other_names: e.currentTarget.value })}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Last Name</Form.Label>
                                <Form.Control
                                    value={details.last_name}
                                    onChange={e => setDetails({ ...details, last_name: e.currentTarget.value })}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Telephone</Form.Label>
                                <Form.Control
                                    type="tel"
                                    pattern="^\+?[1-9]{1}[0-9]{0,2}(-|\s)?([0-9]{2,4}(-|\s)?)+$"
                                    value={details.telephone}
                                    onChange={e => setDetails({ ...details, telephone: e.currentTarget.value })}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required. Ensure format is correct
                                </Form.Control.Feedback>
                                <Form.Text muted>
                                    Format of 256-7##-######
                                </Form.Text>
                            </Col>
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={details.email}
                                    onChange={e => setDetails({ ...details, email: e.currentTarget.value })}

                                />
                                <Form.Control.Feedback type="invalid">
                                    Fill in a valid email address
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    }

                    {(action !== "edit") &&
                        <Row>
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Position</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={details.position}
                                    onChange={e => setDetails({ ...details, position: e.currentTarget.value })}
                                    required

                                />
                                <Form.Control.Feedback type="invalid">
                                    Position is required
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    }


                </Form>
            </Modal.Body>
            <Modal.Footer className="py-1">
                <SubmitButton isSubmitting={submitting} onClick={handleSubmit}>
                    Submit
                </SubmitButton>
                <CancelButton onClick={() => setShow(false)} isSubmitting={submitting}>
                    Cancel
                </CancelButton>
            </Modal.Footer>
        </Modal>
    )
}

/**
 * 
 * @param {{
 * contactpersons: {
 *      items: import('../../resources/api/vendors').ContactPersonObject[], 
 *      setItems: React.Dispatch<React.SetStateAction<import('../../resources/api/vendors').ContactPersonObject[]>>}
 * children: React.ReactNode
 * }} param0 
 */
const ContactPersons = ({ contactpersons, children }) => {

    const { items, setItems } = contactpersons;

    /**
     * add a new all empty invoice item.
     */
    const addItem = useCallback(() => {
        setItems(items => items.concat({
            other_names: "",
            last_name: "",
            email: "",
            telephone: "",
            position: ""
        }));
    }, [setItems])

    /**
     * update an item in the list
     * @param {number} index 
     * @param {import('../../resources/api/vendors').ContactPersonObject} value 
     */
    const updateItem = (index, value) => {

        // console.log(value);
        let current_items = [...items];
        value.hasChanged = true;
        current_items.splice(index, 1, value);
        setItems(current_items);
    }

    /**
     * Deletes an item in position index from the list.
     * @param {number} index Index of item in the list
     */
    const deleteItem = index => {
        if (items.length < 2) return errorAlert("You must have at least one contact person for this client.");
        setItems(items.filter((e, i) => i !== index));
    }




    useEffect(() => {
        if (items.length === 0) addItem();
    }, [items.length, addItem])

    return (
        <>
            <Row className="d-none d-sm-flex g-2">
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Other Names</Form.Label>
                </Col>
                <Col sm={3} className="py-0">
                    <Form.Label className="form-field-title">Last Name</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Telephone</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Email</Form.Label>
                </Col>
                <Col sm={2} className="py-0">
                    <Form.Label className="form-field-title">Position</Form.Label>
                </Col>
            </Row>
            {items.map((e, i) => <ContactPerson key={i} details={e} onChange={value => updateItem(i, value)} onDelete={() => deleteItem(i)} />)}
            <Row>
                <Col sm={12} className="mt-3 mb-2 d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center">
                    <div>
                        <Button variant="secondary" size="sm" className="rounded-pill m-1" onClick={addItem}>
                            <i className="fas fa-plus-circle me-2" />Add Item
                        </Button>
                        {children}
                    </div>

                </Col>
            </Row>
        </>
    )
}

/**
 * 
 * @param {{
 * details: import('../../resources/api/vendors').ContactPersonObject,
 * onChange: (details: import('../../resources/api/vendors').ContactPersonObject) => void
 * onDelete: () => void
 * }} props 
 */
const ContactPerson = ({ details, onChange, onDelete }) => {

    return (
        <div className="my-3 py-2 py-sm-0 my-sm-0 border-start border-primary label-sm-hide" style={{ borderLeftWidth: '3px!important' }}>
            <Row className='g-2'>
                <Col sm={3} className="my-1">
                    <Form.Label className="form-field-title">Other Names</Form.Label>
                    <Form.Control
                        size="sm"
                        value={details.other_names}
                        onChange={e => onChange(({ ...details, other_names: e.currentTarget.value }))}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Required
                    </Form.Control.Feedback>
                </Col>
                <Col sm={3} className="my-1">
                    <Form.Label className="form-field-title">Last Name</Form.Label>
                    <Form.Control
                        size="sm"
                        value={details.last_name}
                        onChange={e => onChange({ ...details, last_name: e.currentTarget.value })}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Required
                    </Form.Control.Feedback>
                </Col>
                <Col sm={2} className="my-1">
                    <Form.Label className="form-field-title">Telephone</Form.Label>
                    <Form.Control
                        size="sm"
                        type="tel"
                        pattern="^\+?[1-9]{1}[0-9]{0,2}(-|\s)?([0-9]{2,4}(-|\s)?)+$"
                        value={details.telephone}
                        onChange={e => onChange({ ...details, telephone: e.currentTarget.value })}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Required. Ensure format is correct
                    </Form.Control.Feedback>
                    <Form.Text muted>
                        Format of 256-7##-######
                    </Form.Text>
                </Col>
                <Col sm={2} className="my-1">
                    <InputGroup>
                        <Form.Label className="form-field-title">Email</Form.Label>
                        <Form.Control
                            size="sm"
                            type="email"
                            value={details.email}
                            onChange={e => onChange({ ...details, email: e.currentTarget.value })}

                        />
                        <Form.Control.Feedback type="invalid">
                            Fill in a valid email address
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col sm={2} className="my-1">
                    <InputGroup>
                        <Form.Label className="form-field-title">Position</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            value={details.position}
                            onChange={e => onChange({ ...details, position: e.currentTarget.value })}
                            required

                        />
                        <Button onClick={onDelete} size="sm" variant="link" className="text-danger" >
                            <i className="fas fa-times" />
                        </Button>
                        <Form.Control.Feedback type="invalid">
                            Fill in a valid position
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}


export { ContactPersons };
export default ContactPersonForm;