import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn } from '../../components/pageheader';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
import { executePayments, getApprovedRequisitions } from '../../resources/api/requisitions';
import { Link, useNavigate } from 'react-router-dom';
import cur from '../../components/currency';
import { SubmitButton } from '../../components/btns';
import { errorAlert, successAlert } from '../../components/toastr';
import { AppUserContext } from '../../App';
import { getAccounts } from '../../resources/api/accounts';



const ProcessRequisitions = () => {

    const { profile } = useContext(AppUserContext);


    const [requisitions, setRequisitions] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [account, setAccount] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);

    const navigate = useNavigate();



    useEffect(() => {

        setError(null);
        setLoaded(false);

        Promise.all([getApprovedRequisitions(), getAccounts()])
            .then(([{ requisitions }, { accounts }]) => {
                setRequisitions(requisitions);
                setAccounts(accounts.filter(a => a.isActive));
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    // Submit the data backend
    const handleSubmit = () => {

        if (!account) {
            return errorAlert("Please set the account where this expense is coming from");
        }

        const data = {
            account_id: account
        }

        setSubmitting(true);

        executePayments(data)
            .then(({ message, reference }) => {
                successAlert(message);
                // infoAlert(`Transaction Reference: ${reference}`)
                navigate(`/app/requisitions/payments/${reference}`);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    const nav_items = [
        { title: 'Requisitions', href: '/app/requisitions' },
        { title: "Process" }
    ];

    //load events details

    if (profile.permission_level > 1) {
        return <ErrorLoading>You do not have the permission to process requisitions.</ErrorLoading>
    }

    if (!isLoaded) {
        return <PageLoading>Loading requisition details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>Process Requisitions</Title>
                <ActionBar>
                    <Btn href="/app/requisitions/new">
                        <i className="fas fa-plus-circle me-1" />New Requisition
                    </Btn>
                </ActionBar>
            </PageHeader>


            <div className="max-1000">


                <Table responsive hover style={{ maxWidth: '600px' }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ref</th>
                            <th>User</th>
                            <th>Date</th>
                            <th style={{ textAlign: "right" }}>Amount</th>
                        </tr>

                    </thead>
                    <tbody>
                        {(requisitions.length === 0) &&
                            <tr>
                                <td colSpan={5} className="text-center">No approved requisitions to display</td>
                            </tr>
                        }
                        {requisitions.map((e, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td><Link to={`/app/requisitions/${e.id}`}>{e.reference}</Link></td>
                                <td>{e.display_name}</td>
                                <td>{moment(e.requisition_date).format("DD MMM YYYY")}</td>
                                <td style={{ textAlign: 'right' }}>{cur(e.total_amount).format()}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4} />
                            <th style={{ textAlign: "right" }}>
                                {cur(requisitions.reduce((p, c) => p + c.total_amount, 0)).format()}
                            </th>
                        </tr>

                    </tfoot>
                </Table>

                {(requisitions.length > 0) &&
                    <>
                        <Row className='mt-4'>
                            <Col sm={6} className="my-2">
                                <Form.Label className="form-field-title">Payment Account</Form.Label>
                                <Form.Select
                                    as="select"
                                    value={account}
                                    onChange={e => setAccount(e.currentTarget.value)}
                                    required
                                >
                                    <option value=""></option>
                                    {accounts.map(c => <option key={c.id} value={c.id}>{c.title}</option>)}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="my-2">
                                <SubmitButton onClick={handleSubmit} isSubmitting={isSubmitting}>
                                    Submit
                                </SubmitButton>
                            </Col>

                        </Row>
                    </>
                }


            </div>
        </>
    )

}

export default ProcessRequisitions;