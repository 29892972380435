import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn } from '../../components/pageheader';
import { Form, Table, Card, Row, Col } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import { Link } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import { Text } from '../../components/utlities';
import currency, { UGX } from '../../components/currency';
import { getVendors } from '../../resources/api/vendors';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { sortFunction } from '../../components/resources';




/**
 * View table items on screens larger than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/vendors').VendorObject[]} props.items
 * @param {number} props.currentPage
 * @param {number} props.noPageItems
*/
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {

    const El = ({ field, children, ...props }) => <th {...props}>{children} <SortButton field={field} {...srt} handleSort={handleSort} /></th>;

    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "20%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "16%" }} />
                <col span="1" style={{ width: "9%" }} />
                <col span="1" style={{ width: "16%" }} />
                <col span="1" style={{ width: "16%" }} />
            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <El field="title">Title</El>
                    <El field="category">Category</El>
                    <El field="address">Address</El>
                    <El className="text-end" field="payables">Payables</El>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={`/app/vendors/${e.id}`}>
                                <Text maxLength={20} text={e.title} />
                            </Link>
                        </td>
                        <td>{e.category || 'n/a'}</td>
                        <td>{e.address || 'n/a'}</td>
                        <td className="text-end">{currency(e.payables, 0).format()}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {VendorObject[]} props.items
 */
const ViewMobile = ({ srt, handleSort, items }) => {

    const El = ({ field, children }) => (
        <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
            {children} <SortButton field={field} {...srt} handleSort={handleSort} />
        </span>
    )
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <El field="title">Title</El>
                <El field="category">Category</El>
                <El field="title">Address</El>
                <El field="payables">Payables</El>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span className="text-muted font-weight-normal">{UGX(e.payables).format()}</span>
                            <span className="text-muted font-weight-normal">{e.category}</span>
                        </div>

                        <div className="lead mb-2">
                            <Link to={`/app/vendors/${e.id}`} className="stretched-link">{e.title}</Link>
                        </div>

                        {!!e.address &&
                            <p>{e.address}</p>
                        }
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}

/**
 * module that handles view of vendors
 * match path /app/vendors
 */
const ViewVendors = () => {

    const navItems = [{ title: 'Vendors' }];

    const [srt, setSrt] = useState({
        currentField: 'title',
        currentOrder: 'asc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'title' });

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);

    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => (!!i[f]) && i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)]);
    }, [search.val, search.field, orginalItems]);


    /**
     * get the items
     */
    useEffect(() => {

        getVendors()
            .then(({ vendors }) => {
                setOriginalItems(vendors);
                setItems(vendors);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))


    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder);

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    if (!loaded) return <PageLoading>Loading all vendors ...</PageLoading>

    if (error) return <ErrorLoading>{error}</ErrorLoading>


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Vendors</Title>
                <ActionBar>
                    {/* <Btn href="/app/vendors/debtors">
                        <i className="fas fa-hand-holding-dollar me-1" />Debtors
                    </Btn> */}
                    <Btn href="/app/vendors/new">
                        <i className="fas fa-plus-circle me-1" />New Vendor
                    </Btn>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="Search For" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="title">Title</option>
                            <option value="category">Category</option>
                            <option value="address">Address</option>
                        </Form.Select>
                    </Col>


                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>
        </>
    )
}


export default ViewVendors;