import { useRef, useState } from "react";
import { Col, Row, Form, Modal, Button } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../../components/btns";
import { errorAlert, successAlert } from "../../../components/toastr";
import { jobDocumentCategories } from "../../../assets/constants";
import { UCWords } from "../../../components/resources";
import FileInput from "../../../components/files";
import { updateJob, uploadDocument } from "../../../resources/api/jobs";
import { DateSelector } from "../../../components/datetimepicker";
import { ActionButton } from "../../../components/pageheader";
import { useEffect } from "react";

const { Label, Control, Group, Select } = Form;

/**
 * 
 * @param {Object} props
 * @param {string} props.job_id ID of the job
 * @param {(document: import("../view-titles").TitleDocObject) => void} props.onUpload
 */
const UploadDocument = ({ onUpload, job_id }) => {

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [handlingSubmit, setHandlingSubmit] = useState(false);

    const [file, setFile] = useState("");
    const [category, setCategory] = useState('');

    const ref = useRef();

    /**
     * 
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = ref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have some errors in your form.");
            return;
        }

        setValidated(false);
        setHandlingSubmit(true);

        uploadDocument(job_id, { file_id: file, category })
            .then(({ message, document }) => {
                successAlert(message);
                onUpload(document);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => {
                form.reset();
                setHandlingSubmit(false);
            })

    }

    const handleExit = () => {
        setValidated(false);
        setFile("");
        setCategory("");
    }

    return (

        <>
            <Button
                variant="outline-secondary"
                size="sm"
                className="rounded-pill"
                onClick={() => setShow(true)}
            >
                <i className="fas fa-file-upload me-2" />
                Upload Document
            </Button>

            <Modal show={show} onExit={handleExit} centered>
                <Modal.Body>
                    <Form noValidate validated={validated} ref={ref}>

                        <Row>
                            <Col sm={12} className="my-1">
                                <Group controlId="category">
                                    <Label className="form-field-title">Category</Label>
                                    <Select
                                        value={category}
                                        onChange={e => setCategory(e.currentTarget.value)}
                                        required
                                    >
                                        <option value="">Select an option</option>
                                        {jobDocumentCategories.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                                    </Select>
                                    <Control.Feedback type="invalid">
                                        Category is required.
                                    </Control.Feedback>
                                </Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="my-1">
                                <Label className="form-field-title">File</Label>
                                <FileInput
                                    value={[file]}
                                    maxFiles={1}
                                    onChange={([id]) => setFile(id || "")}
                                    required
                                    errorText="You must upload a file"
                                    placeholder="Select a file you want to upload"
                                />
                            </Col>
                        </Row>



                        <Row className="mt-3 text-end">
                            <Col>
                                <SubmitButton
                                    onClick={handleSubmit}
                                    isSubmitting={handlingSubmit}
                                >
                                    Upload File
                                </SubmitButton>
                                <CancelButton
                                    isSubmitting={handlingSubmit}
                                    onClick={() => setShow(false)}
                                >
                                    Cancel
                                </CancelButton>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {string} props.job_id ID of the job
 * @param {import("../../../resources/api/jobs").LPOObject} props.lpo_details
 * @param {(details: import("../../../resources/api/jobs").JobObject) => void} props.onUpload
 */
const UploadLPODetails = ({ onUpload, lpo_details, job_id }) => {

    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [handlingSubmit, setHandlingSubmit] = useState(false);

    const [lpo_no, setLPONo] = useState(lpo_details.lpo_no || "");
    const [lpo_date, setLPODate] = useState(lpo_details.lpo_date || "");

    const ref = useRef();

    useEffect(() => {
        setLPONo(lpo_details.lpo_no || "");
        setLPODate(lpo_details.lpo_date || "");
    }, [lpo_details.lpo_date, lpo_details.lpo_no])

    /**
     * 
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = ref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have some errors in your form.");
            return;
        }

        setValidated(false);
        setHandlingSubmit(true);

        updateJob({ lpo_date, lpo_no }, job_id,)
            .then(({ message, job }) => {
                successAlert(message);
                onUpload(job);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => {
                form.reset();
                setHandlingSubmit(false);
            })

    }

    const handleExit = () => {
        setValidated(false);
        setLPONo("");
        setLPODate("");
    }

    return (

        <>
            <ActionButton
                variant="outline-secondary"
                size="sm"
                title="Upload/Update LPO Details"
                onClick={() => setShow(true)}
            >
                <i className="fas fa-file-invoice" />
            </ActionButton>

            <Modal show={show} onExit={handleExit} centered size="sm">
                <Modal.Body>
                    <Form noValidate validated={validated} ref={ref}>

                        <Row>
                            <Col sm={12} className="my-1">
                                <Group controlId="category">
                                    <Label className="form-field-title">Date</Label>
                                    <DateSelector
                                        value={lpo_date}
                                        onChange={d => setLPODate(d || "")}
                                        required={!!lpo_no}
                                    />
                                    <Control.Feedback type="invalid">
                                        LPO Date is required or leave both fields empty.
                                    </Control.Feedback>
                                </Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="my-1">
                                <Label className="form-field-title">Number</Label>
                                <Control
                                    required={!!lpo_date}
                                    value={lpo_no}
                                    onChange={e => setLPONo(e.currentTarget.value)}
                                />
                                <Control.Feedback type="invalid">
                                    LPO number is required or leave both fields empty.
                                </Control.Feedback>

                            </Col>
                        </Row>



                        <Row className="mt-3 text-end">
                            <Col>
                                <SubmitButton
                                    onClick={handleSubmit}
                                    isSubmitting={handlingSubmit}
                                >
                                    Update Job
                                </SubmitButton>
                                <CancelButton
                                    isSubmitting={handlingSubmit}
                                    onClick={() => setShow(false)}
                                >
                                    Cancel
                                </CancelButton>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
            </Modal>
        </>
    )
}


export { UploadLPODetails };
export default UploadDocument;