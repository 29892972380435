import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import AccountDetails from './account-details';
import ViewAccounts from './view-accounts';

const Accounts = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level !== 1) {
        return <ErrorLoading>You do not have the permission to view/add/edit financial accounts.</ErrorLoading>
    }


    return (
        <Routes>
            <Route path="/:accountid/*" element={<AccountDetails />} />
            <Route path="/" element={<ViewAccounts />} />
        </Routes>
    )
};

export default Accounts;