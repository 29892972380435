import { APIURL, postData, getData, deleteData } from "../fetch";

/**
 * 
 * @typedef {{
 *  id: string
 *  salary_id: string
 *  staff_id: string
 *  display_name: string 
 *  total_amount: number 
 *  payee_amount: number 
 *  nssf_amount: number 
 *  e_nssf_amount: number 
 *  wht_amount: number 
 *  advance_amount: number 
 *  other_amount: number 
 *  nett_amount: number
 * }} SalaryItemObject 
 */


/**
 * 
 * @typedef {{
 *  id: string,
 *  reference: string
 *  transaction_date: string
 *  expense_id: string 
 *  expense_date: string|null
 *  period_date: string
 *  period_year: number
 *  period_month: number
 *  total_amount: number 
 *  payee_amount: number 
 *  nssf_amount: number 
 *  e_nssf_amount: number 
 *  wht_amount: number 
 *  advance_amount: number 
 *  other_amount: number 
 *  nett_amount: number
 *  status: string
 *  items: SalaryItemObject[]
 * }} SalaryObject 
 */




/**
 * 
 * @param {SalaryObject} data 
 * @returns {Promise<{code: number, message: string, salary: SalaryObject}>}
 */
const createSalary = (data) => {
    return postData({
        url: `${APIURL}/salaries`,
        data
    })
}

/**
 * 
 * @param {SalaryObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, salary: SalaryObject}>}
 */
const updateSalary = (data, id) => {
    return postData({
        url: `${APIURL}/salaries/${id}`,
        data
    })
}

/**
 * 
 * @param {SalaryObject} data 
 * @returns {Promise<{code: number, message: string, salaries: SalaryObject[]}>}
 */
const getSalaries = () => getData({ url: `${APIURL}/salaries` })



/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, salary: SalaryObject}>}
 */
const getSalary = (id, includes = null) => {

    const obj = { url: `${APIURL}/salaries/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {"draft"|"awaiting-approval"|"approved"} status 
 * @returns  {Promise<{code: number, message: string, salary: SalaryObject}>}
 */
const changeSalaryStatus = (id, status) => postData({ url: `${APIURL}/salaries/${id}/status/${status}` });


/**
 * 
 * @param {{account_id: string, method: string}} data 
 * @param {string} id idof the salary
 * @returns {Promise<{code: number, message: string}>}
 */
const executePayments = (data, id) => postData({ url: `${APIURL}/salaries/${id}/pay`, data })


/**
 * @param {string} id ID 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteSalaries = id => deleteData({ url: `${APIURL}/salaries/${id}` });


export {
    createSalary,
    getSalary,
    getSalaries,
    updateSalary,
    changeSalaryStatus,
    executePayments,
    deleteSalaries
}