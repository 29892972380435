import { useState } from "react";
import { Tab, Table } from "react-bootstrap"
import { ErrorLoading, PageLoading } from "../../../components/loading";
import { useEffect } from "react";
import { getFutureWork } from "../../../resources/api/dashboard";
import cur from "../../../components/currency";
import moment from "moment";
// import WorkDoneGraph from "./WorkDoneGraph";

const FutureWork = () => {

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [payments, setPayments] = useState();


    useEffect(() => {

        getFutureWork()
            .then(({ data: { future_work } }) => {

                let cumsum = 0;
                future_work = future_work.map(f => {
                    cumsum = cumsum + parseFloat(f.amount);
                    return ({ ...f, cumsum });
                });
                setPayments(future_work);



            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])



    if (!loaded) return <PageLoading>Loading all data...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Tab.Pane eventKey="future_work">

            {/* <Button variant="secondary" onClick={onDownloadClick}><i className="fas fa-print me-1" />Print</Button> */}

            <Table responsive size="sm" hover className="mt-3" style={{ maxWidth: "500px" }}>
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Week</th>
                        <th>Week Starting</th>
                        <th className="text-end">Amount</th>
                        <th className="text-end">Cumulative</th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map(f => (
                        <tr key={`${f.job_year}${f.job_week}`}>
                            <td>{f.job_year}</td>
                            <td>{f.job_week}</td>
                            <td>{moment().year(f.job_year).isoWeek(f.job_week).day(1).format("DD MMMM YYYY")}</td>
                            <td className="text-end">{cur(f.amount, 0).format()}</td>
                            <td className="text-end">{cur(f.cumsum, 0).format()}</td>
                        </tr>
                    ))}
                </tbody>

            </Table>


        </Tab.Pane>
    )
}

export { FutureWork };