import { Routes, Route } from "react-router-dom";
import JobDetails from "./job-details";
import { NewJob } from "./job-manipulation";
import ViewJobs from "./view-jobs";

const Jobs = () => {

    return (
        <Routes>
            <Route path="/new" element={<NewJob />} />
            <Route path="/table" element={<ViewJobs view="table" />} />
            <Route path="/calendar" element={<ViewJobs view="calendar" />} />
            <Route path="/:jobid/*" element={<JobDetails />} />/
            <Route path="/" element={<ViewJobs />} />
        </Routes>
    )
}

export default Jobs;