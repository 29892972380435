import { faker } from "@faker-js/faker";
import moment from "moment";
import fetchData, { APIURL, getData, postData } from "../fetch";


/**
 * 
 * @typedef {{
* id: string,
* contact_id: string,
* display_name: string
* other_names: string
* last_name: string
* email: string
* telephone: string
* job_title: string
* roles: string[]
* rm_accounts: {id: string, title: string}[]
* last_logged_in: string
* last_changed_password: string
* status: string
* }} UserObject 
*/

export const createUserObject = () => {

    let names = [faker.name.firstName(), faker.name.lastName()];

    return {
        id: faker.datatype.uuid(),
        contact_id: faker.datatype.uuid(),
        display_name: `${names[0]} ${names[1]}`,
        other_names: names[0],
        last_name: names[1],
        telephone: faker.phone.number('256-7##-######'),
        email: faker.internet.email(),
        job_title: faker.word.noun(),
        last_logged_in: moment(faker.date.past()).format("YYYY-MM-DD HH:mm:ss"),
        permission_level: 1,
        status: 'active'
    }
}

/**
 * 
 * @returns {Promise<{code: number, message: string, users: import("../../app/users/view-users").UserObject}>}
 */
const getUsers = () => getData({
    url: `${APIURL}/users`
})



/**
 * 
 * @param {string} id 
 * @returns {Promise<{code: number, message: string, user: import("../../app/users/view-users").UserObject}>}
 */
const getUser = id => getData({
    url: `${APIURL}/users/${id}`
})

/**
 * 
 * @param {{other_names: string, last_name: string, email: string, telephone: string, job_title: string, roles: string[]}} data 
 * @returns {Promise<{code: number, message: string}>}
 */
const createUser = (data) => postData({
    url: `${APIURL}/users`,
    data
})

/**
 * 
 * @param {{other_names: string, last_name: string, email: string, telephone: string, job_title: string, roles: string[]}} data 
 * @returns {Promise<{code: number, message: string}>}
 */
const updateUser = (data, id) => postData({
    url: `${APIURL}/users/${id}`,
    data
});
/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteUser = id => fetchData({ method: "DELETE", url: `${APIURL}/users/${id}` });

/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const resetUserPassword = id => postData({ url: `${APIURL}/users/${id}/reset-password` });


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const suspendUser = id => postData({ url: `${APIURL}/users/${id}/suspend` });


const updateRoles = (roles, id) => postData({ url: `${APIURL}/users/${id}/roles`, data: { roles } })


const updatePermissionLevel = (permission_level, id) => postData({ url: `${APIURL}/users/${id}/roles`, data: { permission_level } })

/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string, dashboard: {feenotes: import("./fee-notes").NoteObject[], titles: import("../../app/titles/view-titles").TitleObject[], accounts: import("./accounts").AccountObject[]}}>}
 */
const getDashboardDetails = () => getData({ url: `${APIURL}/dashboard` });


export {
    createUser,
    deleteUser,
    getUsers,
    getUser,
    resetUserPassword,
    suspendUser,
    updateUser,
    updateRoles,
    getDashboardDetails,
    updatePermissionLevel
}