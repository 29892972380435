import { Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { errorAlert, successAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { useNavigate } from 'react-router-dom';
import { Select } from '../../components/select';
import { getInventory } from '../../resources/api/inventory';
import { updateItem, createItem } from '../../resources/api/pricelist';
import { PricelistCategories } from '../../assets/constants';
import { UCWords } from '../../components/resources';
// import { UCWords } from '../../components/resources';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/pricelist').PricelistObject} props.details
 * @param {(details: import('../../resources/api/pricelist').PricelistObject) => void} props.onSubmit
 */
const PriceListForm = ({ details: pricelistItem, action, onSubmit }) => {


    const [details, setDetails] = useState(pricelistItem);
    const [components, setComponents] = useState(pricelistItem.inventory);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);


    const [deleted_components, setDeletedComponents] = useState([]);

    const [items, setItems] = useState([]);
    const [selected_items, setSelectedItems] = useState([]);
    const [display_items, setDisplayItems] = useState([]);

    const navigate = useNavigate();


    const changeComponentQuantity = (quantity, idx) => {
        const _components = [...components];

        let _comp = _components[idx];
        _comp = { ..._comp, quantity, hasChanged: 1 };
        _components.splice(idx, 1, _comp);
        setComponents(_components);
    }

    const deleteComponent = idx => {
        const _comp = [...components][idx];
        setComponents(comps => comps.filter((e, i) => i !== idx));
        setSelectedItems(ids => ids.filter(i => (i !== _comp.item_id)));
        if (!!_comp.id) setDeletedComponents(p => p.concat(_comp.id));

    }

    const handeItemSelect = ids => {
        let _items = items
            .filter(i => ids.indexOf(i.id) !== -1)
            .map(i => ({
                item_id: i.id,
                item: i.name,
                quantity: 1,
                isNew: 1
            }))

        setSelectedItems(ids => [...ids, ..._items.map(i => i.item_id)]);
        setComponents(prev => ([...prev, ..._items]));
    }

    useEffect(() => {

        setDisplayItems(
            items.filter(i => (selected_items.indexOf(i.id) === -1))
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selected_items), JSON.stringify(items)])

    useEffect(() => {
        getInventory()
            .then(({ inventory }) => {
                setItems(
                    inventory.map(e => ({
                        ...e,
                        title: e.name
                    }))
                )
            })
            .catch(e => errorAlert(`Could not load inventory. Error ${e}`))
    }, [])


    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        // if (components.length < 1) {
        //     errorAlert("Select at least one component", "Form Errors");
        //     return;
        // }

        setValidated(false);
        setSubmitting(true);

        const promise = (action === "edit") ?
            updateItem({
                ...details,
                new_inventory: components.filter(i => i.isNew),
                updated_inventory: components.filter(i => (!!i.id && i.hasChanged)),
                deleted_inventory: deleted_components
            }, details.id) :
            createItem({ ...details, inventory: components });

        promise
            .then(({ item, message }) => {
                successAlert(message);
                onSubmit(item);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Name</Label>
                    <Control
                        value={details.name}
                        minLength={8}
                        maxLength={60}
                        onChange={e => setDetails({ ...details, name: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        The title should have a minimum of 8 and maximum of 60 characters.
                    </Feedback>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Price</Label>
                    <Control
                        value={details.price}
                        step={1}
                        onChange={e => setDetails({ ...details, price: e.currentTarget.value })}
                        required={true}
                    />
                    <Feedback type="invalid">
                        The title should have a minimum of 8 and maximum of 60 characters.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <Form.Select
                        value={details.category}
                        onChange={e => setDetails({ ...details, category: e.currentTarget.value })}
                        required={true}
                    >
                        <option value=""></option>
                        {PricelistCategories.map(c => <option key={c} value={c}>{(UCWords(c.replace(/-/g, " ")))}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Categorise this item.
                    </Feedback>
                </Col>
            </Row>
            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Description</Label>
                    <Control
                        as="textarea"
                        value={details.description}
                        onChange={e => setDetails(d => ({ ...d, description: e.target.value }))}
                        rows={1}
                        onFocus={e => (e.target.rows = 4)}
                        onBlur={e => (e.target.rows = 2)}
                    />
                </Col>
            </Row>


            <h4 className="form-section-label">Components</h4>

            <Row className="d-none d-sm-flex">
                <Col sm={5}><Label className="form-field-title">Inventory Item</Label></Col>
                <Col sm={3}><Label className="form-field-title">Quantity</Label></Col>
            </Row>

            {components.map((p, idx) => (
                <Row key={idx} className="g-2 my-3 my-sm-0 pb-3 pb-sm-0">
                    <Col sm={5}>
                        <Label className="form-field-title d-sm-none">Inventory Item</Label>
                        <Control
                            readOnly
                            value={p.item}
                            size="sm"
                        />

                    </Col>
                    <Col sm={3} >
                        <Label className="form-field-title d-sm-none">Quantity</Label>
                        <InputGroup>
                            <Control
                                type="number"
                                value={p.quantity}
                                onChange={e => changeComponentQuantity(e.currentTarget.value, idx)}
                                step={1}
                                size="sm"
                            />
                            <Button variant="link" size="sm" className='text-danger' onClick={() => deleteComponent(idx)}>
                                <i className="fas fa-times" />
                            </Button>
                        </InputGroup>
                        <Control.Feedback type="invalid">
                            Required
                        </Control.Feedback>
                    </Col>
                </Row>

            ))}

            {(display_items.length > 0) &&
                <Row>
                    <Col className='my-2'>
                        <Select
                            items={display_items}
                            size="sm"
                            className='rounded-pill'
                            maxItems={10}
                            onSelect={handeItemSelect}
                        >
                            <i className='fas fa-circle-plus me-1' />Add Items(s)
                        </Select>
                    </Col>
                    {/* <Col className='my-2 text-end h4'>
                        {UGX(prods.reduce((p, c) => p + parseFloat(c.amount), 0)).format()}
                    </Col> */}
                </Row>
            }

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Item" : "Create Item"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default PriceListForm;