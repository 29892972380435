import { Form, Row, Col, InputGroup, FormSelect } from 'react-bootstrap';
import { useContext, useEffect, useMemo, useState } from 'react';
import { error as errorAlert, infoAlert, successAlert, warningAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { DateSelector, TimeSelector } from '../../components/datetimepicker';
import { UCWords } from '../../components/resources';
import { EventCategories, EventColours } from '../../assets/constants';
import { PageLoading } from '../../components/loading';
import { getClientContacts, getClients } from '../../resources/api/clients';
import SelectInput from '../../components/select';
import { Link, useNavigate } from 'react-router-dom';
import { createJob, updateJob } from '../../resources/api/jobs';
import { getUsers } from '../../resources/api/users';
import { AppUserContext } from "../../App";

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating trip
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/jobs').JobObject} props.details
 * @param {(details: import('../../resources/api/jobs').JobObject) => void} props.onSubmit
 */
const JobForm = ({ details: JobObject, action, onSubmit }) => {

    const { profile } = useContext(AppUserContext);

    const [details, setDetails] = useState(JobObject);
    const [clients, setClients] = useState([]);
    const [client_contacts, setClientContacts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const [users, setUsers] = useState([]);

    const bools = useMemo(() => {

        let isCorporate = (details.type === "corporate");

        return {
            depositRequired: isCorporate ? false : details.depositRequired,
            fullPaymentBeforeDelivery: isCorporate ? false : details.fullPaymentBeforeDelivery,
        }

    }, [details.type, details.depositRequired, details.fullPaymentBeforeDelivery])

    const navigate = useNavigate();



    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }


        setValidated(false);
        setSubmitting(true);

        let promise;

        const isCorporate = (details.type === "corporate"),
            data = {
                ...details,
                depositRequired: (!isCorporate && details.depositRequired) ? 1 : 0,
                fullPaymentBeforeDelivery: (!isCorporate && details.fullPaymentBeforeDelivery) ? 1 : 0,
                min_deposit: isCorporate ? 0 : (details.depositRequired ? (details.min_deposit || 0) : 0)
            };

        // console.log(data);
        // setSubmitting(false);
        // return;

        if (action === "create") {
            promise = createJob(data);
        } else {
            promise = updateJob(data, details.id);
        }

        promise
            .then(({ job, message }) => {
                successAlert(message);
                onSubmit(job);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    useEffect(() => {
        Promise.all([
            getClients(),
            getUsers()
        ])

            .then(([{ clients }, { users }]) => {
                setClients(
                    clients.map(c => ({
                        ...c,
                        description: c.address
                    }))
                );
                setUsers(
                    users.map(c => ({
                        ...c,
                        id: c.contact_id,
                        title: c.display_name,
                        description: `E: ${c.email} | T: ${c.telephone} | ${c.job_title}`
                    }))
                );
            })
            .catch(e => errorAlert(e))
            .finally(() => setLoaded(true));
    }, []);

    useEffect(() => {

        if (!details.client_id) return;

        infoAlert("Getting client's contact persons...");

        getClientContacts(details.client_id)
            .then(({ contact_persons }) => {
                setClientContacts(
                    contact_persons
                        .map(c => ({
                            ...c,
                            title: c.display_name,
                            id: c.contact_id,
                            description: `${c.telephone} | ${c.email}`
                        })));

                if (contact_persons.length === 0) {
                    warningAlert(
                        "This client has no contact persons. Please click to add contact person...",
                        "",
                        {
                            onclick: () => navigate(`/app/clients/${details.client_id}`)
                        }
                    )
                } else {
                    successAlert("Loaded client's contact persons");
                }
            })
            .catch(errorAlert)

    }, [details.client_id, navigate])


    if (!loaded) return <PageLoading>Loading clients...</PageLoading>;

    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Job Type</Label>
                    <div className='mt-1'>
                        <Form.Check
                            type="radio"
                            inline
                            id={`corporate`}
                            name="job_type"
                            label={`Corporate`}
                            checked={details.type === "corporate"}
                            onChange={() => setDetails(d => ({ ...d, type: "corporate" }))}
                            required
                        />
                        <Form.Check
                            type="radio"
                            inline
                            id={`individual`}
                            name="job_type"
                            label={`Individual`}
                            checked={details.type === "individual"}
                            onChange={() => setDetails(d => ({ ...d, type: "individual" }))}
                            required
                        />
                    </div>
                </Col>
            </Row>
            <Row className='g-2'>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client</Label>
                    <SelectInput
                        value={[details.client_id]}
                        onChange={([id]) => setDetails(d => ({ ...d, client_id: id || "", client_contact_id: "" }))}
                        options={clients}
                        required
                        placeholder='-'
                        maxItems={1}
                        errorText="This field is required"
                    />
                    <Form.Text muted style={{ lineHeight: 1, display: "block" }}>
                        The client must exist in the existing lists of clients.
                        <Link to="/app/clients/new?action=create_job">Click here</Link> to add a new client.
                    </Form.Text>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client Contact</Label>
                    <SelectInput
                        value={[details.client_contact_id]}
                        onChange={([id]) => setDetails(d => ({ ...d, client_contact_id: id || "" }))}
                        options={client_contacts}
                        required
                        placeholder="-"
                        maxItems={1}
                        errorText="This field is required."
                    />
                    {!details.client_id &&
                        <Form.Text muted style={{ lineHeight: 1, display: "block" }}>
                            Select a client first.
                        </Form.Text>
                    }

                </Col>
            </Row>
            <Row className='g-2'>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Title</Label>
                    <Control
                        value={details.title}
                        onChange={e => setDetails({ ...details, title: e.currentTarget.value })}
                        placeholder=""
                        required={true}
                    />
                    <Feedback type="invalid">
                        A title must be provided for this job
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Category</Label>
                    <Form.Select
                        value={details.category}
                        onChange={e => setDetails({ ...details, category: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {EventCategories.map(t => <option value={t} key={t}>{UCWords(t.replace(/-/g, " "))}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        Please choose a category for this event.
                    </Feedback>
                </Col>
            </Row>
            <Row className="g-2">
                <Col xs={6} sm={4} className="my-1">
                    <Label className="form-field-title">Start Date</Label>
                    <DateSelector
                        value={details.job_date}
                        onChange={date =>
                            setDetails(d => ({
                                ...d,
                                job_date: date,
                                end_date: (!d.end_date || (d.end_date < date)) ? date : d.end_date
                            }))}
                        // placeholder="when is the event taking place?"
                        required={true}
                        minDate={(profile.permission_level === 1) ? false : "2021-01-01"}

                    />
                    <Feedback type="invalid">
                        A valid date must be provided.
                    </Feedback>
                </Col>
                <Col xs={6} sm={4} className="my-1">
                    <Label className="form-field-title">End Date</Label>
                    <DateSelector
                        value={details.end_date}
                        onChange={date =>
                            setDetails(d => ({
                                ...d,
                                end_date: date
                            }))}
                        // placeholder="when is the event taking place?"
                        required={true}
                        minDate={details.job_date}

                    />
                    <Feedback type="invalid">
                        A valid date must be provided.
                    </Feedback>
                </Col>
                <Col xs={6} sm={2} className="my-1">
                    <Label className="form-field-title">Start Time</Label>
                    <TimeSelector
                        value={details.start_time}
                        onChange={date =>
                            setDetails(d => ({
                                ...d,
                                start_time: date,
                                end_time: (!d.end_time || (d.end_time < date)) ? date : d.end_time
                            }))}
                        // placeholder="when is the event taking place?"
                        required={true}
                        min="2021-01-01"

                    />
                    <Feedback type="invalid">
                        A valid date must be provided.
                    </Feedback>
                </Col>
                <Col xs={6} sm={2} className="my-1">
                    <Label className="form-field-title">End Time</Label>
                    <TimeSelector
                        value={details.end_time}
                        onChange={date =>
                            setDetails(d => ({
                                ...d,
                                end_time: date
                            }))}
                        // placeholder="when is the event taking place?"
                        required={true}
                        minDate={details.job_date}

                    />
                    <Feedback type="invalid">
                        A valid date must be provided.
                    </Feedback>
                </Col>
            </Row>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Location</Label>
                    <Control
                        value={details.location}
                        onChange={e => setDetails({ ...details, location: e.currentTarget.value })}
                        placeholder=""
                        required={true}
                    />
                    <Feedback type="invalid">
                        A location must be provided
                    </Feedback>
                    <Form.Text muted>
                        Format of [building name], [streetname], [city]
                    </Form.Text>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Lead Contact</Label>
                    <SelectInput
                        value={[details.lead_contact_id]}
                        onChange={([id]) => setDetails(d => ({ ...d, lead_contact_id: id || "" }))}
                        placeholder="-"
                        options={users}
                        maxItems={1}
                        required={true}
                    />
                </Col>
            </Row>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Deliverables</Label>
                    <Control
                        value={details.deliverables}
                        onChange={e => setDetails({ ...details, deliverables: e.currentTarget.value })}
                        placeholder=""
                        required={true}
                    />
                    <Feedback type="invalid">
                        Deliverables must be stated
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Calendar Colour</Label>
                    <FormSelect
                        value={details.colour}
                        onChange={e => setDetails({ ...details, colour: e.currentTarget.value })}
                        required={true}
                    >
                        {EventColours.map(v => <option id={v} value={v}>{UCWords(v.replace(/_/g, " "))}</option>)}
                    </FormSelect>
                    <Feedback type="invalid">
                        Deliverables must be stated
                    </Feedback>
                </Col>
            </Row>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className='form-field-title'>Minimum Deposit</Label>
                    {bools.depositRequired &&
                        <InputGroup>
                            <Control
                                value={details.min_deposit}
                                type="number"
                                onChange={e => setDetails({ ...details, min_deposit: e.currentTarget.value })}
                                placeholder=""
                                max={100}
                                min={10}
                                required={details.depositRequired}
                                disabled={details.type === 'corporate'}
                            />
                            <InputGroup.Text className='border-0'>%</InputGroup.Text>
                        </InputGroup>
                    }
                    <Feedback type="invalid">
                        A minimum deposit must be between 10 and 100%
                    </Feedback>
                    <Form.Check
                        className=""
                        type="switch"
                        id="isBillSwitch"
                        disabled={details.type === 'corporate'}
                        checked={bools.depositRequired}
                        onChange={() => setDetails(d => ({ ...d, depositRequired: !d.depositRequired }))}
                        label={bools.depositRequired ? "Deposit is required" : "No deposit is required"}
                    />
                </Col>
                <Col sm={6} className="my-1">
                    <Label className='form-field-title'>Full payment required before delivery?</Label>
                    <Form.Check
                        className=""
                        type="switch"
                        id="isFullPayment"
                        disabled={details.type === 'corporate'}
                        checked={bools.fullPaymentBeforeDelivery}
                        onChange={() => setDetails(d => ({ ...d, fullPaymentBeforeDelivery: !d.fullPaymentBeforeDelivery }))}
                        label={bools.fullPaymentBeforeDelivery ? "Full payment is required" : "Full payment is not required"}
                    />
                </Col>
            </Row>


            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Job" : "Create Job"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default JobForm;