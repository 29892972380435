
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
import PaymentForm from './allowanceform';

const payment = {
    items: []
}

const NewPayment = () => {

    const nav_items = [
        { title: "Payroll", href: "/app/payroll" },
        { title: 'Allowances', href: '/app/payroll/allowances' },
        { title: 'Initiate Payment' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        navigate(`/app/payroll/allowances/${details.id}`);
    }

    // const { profile } = useContext(AppUserContext);

    // if (profile.roles.indexOf('finance') === -1) {
    //     return <ErrorLoading>You do not have permission to create an expense.</ErrorLoading>;
    // }



    return (
        <>
            <Nav items={nav_items} />

            <PaymentForm
                details={payment}
                action="create"
                onSubmit={handleSubmit}
            />
        </>
    )
}

/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/allowances").AllowanceObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import("../../resources/api/allowances").AllowanceObject>>} props.setDetails 
 */
const EditPayment = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Payroll', href: '/app/payroll' },
        { title: 'Allowances', href: '/app/payroll/allowances' },
        { title: details.reference, href: `/app/payroll/allowances/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/payroll/allowances/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <PaymentForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { EditPayment }

export default NewPayment;

