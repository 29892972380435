import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ListGroup, Table, Modal, Form, Row, Col } from "react-bootstrap";
import { Routes, Route, useParams, Link, useNavigate } from "react-router-dom";
import { AppUserContext } from "../../App";
import Nav from '../../components/breadcrumb';
import { CancelButton, SubmitButton } from "../../components/btns";
import cur from "../../components/currency";
import { DateSelector } from "../../components/datetimepicker";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { ConfirmAction } from "../../components/prompt";
import { sortFunction, UCWords } from "../../components/resources";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import { createNote, deleteNote } from "../../resources/api/events";
import { attachInvoice, changeJobStatus, createDates, deleteDate, deleteDocument, deleteJob, getJob } from "../../resources/api/jobs";
import { EditJob } from "./job-manipulation";
import { APIURL } from "../../resources/fetch";
import { Select } from "../../components/select";
import { getJoblessInvoices } from "../../resources/api/invoices";
import { confirmedJobStatuses, fileIcons, unconfirmedJobStatuses } from "../../assets/constants";
import UploadDocument, { UploadLPODetails } from "./details/upload-document";
import PackingList from "./packing-list";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/jobs").JobObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import("../../resources/api/jobs").JobObject>>} props.setDetails
 */
const ViewDetails = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Jobs', href: "/app/jobs" },
        { title: details.job_no }
    ]

    const [invoices, setInvoices] = useState([]);
    const { profile } = useContext(AppUserContext);

    useEffect(() => {

        if (details.hasInvoice) return;

        getJoblessInvoices(details.client_id)
            .then(({ invoices }) => {
                setInvoices(invoices.map(i => ({ ...i, title: i.invoice_no })))
            })
            .catch(errorAlert)
    }, [details.hasInvoice, details.client_id])

    const handleInvoiceSelect = ([invoice_id]) => {
        if (!invoice_id) return;

        infoAlert("Attaching invoice. Please wait...");
        attachInvoice(invoice_id, details.id)
            .then(({ message }) => {
                successAlert(message);
                let _invoice = invoices.find(i => i.id === invoice_id);

                setDetails(d => ({
                    ...d,
                    hasInvoice: true,
                    total_amount: _invoice.due_amount,
                    balance: _invoice.due_amount,
                    invoice: _invoice,
                    status: 'processing'
                }))
            })
            .catch(e => errorAlert(`Could not attach invoice selected. Error: ${e}`));

    }

    const navigate = useNavigate();

    const addNote = (details) => setDetails(d => ({ ...d, notes: d.notes.concat(details) }));

    const handleJobDelete = () => {

        infoAlert('Deleting job, please hold...');

        deleteJob(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/jobs");
            })
            .catch(e => errorAlert(e));
    }

    const handleDocUpload = (document) => setDetails(d => ({ ...d, documents: d.documents.concat(document) }))

    const handleDocDelete = id => setDetails(d => ({ ...d, documents: d.documents.filter(dd => dd.id !== id) }));

    const addDates = (details) => setDetails(d => ({ ...d, job_dates: d.job_dates.concat(details) }));

    const handleDateDelete = (e, id) => {
        e.preventDefault();

        infoAlert('Deleting dates, please hold...');

        deleteDate(details.id, id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, job_dates: d.job_dates.filter(ed => ed.id !== id) }));
            })
            .catch(e => errorAlert(e));
    }

    const handleDelete = id => {

        infoAlert('Deleting note, please hold...');

        deleteNote(id)
            .then(({ message }) => {
                setDetails(d => ({ ...d, notes: d.notes.filter(n => n.id !== id) }));
                successAlert(message);
            })
            .catch(e => errorAlert(e));

    }

    const handleJobCancel = () => {

        infoAlert("Cancelling job...");

        changeJobStatus(details.id, "cancelled")
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: "cancelled" }))
            })
            .catch(e => errorAlert(`Could not cancel the job. Error: ${e}`))

    }

    const handleConfirmJob = () => {
        infoAlert("Confirming job...");

        changeJobStatus(details.id, "confirmed")
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: "confirmed" }))
            })
            .catch(e => errorAlert(`Could not confirm the job. Error: ${e}`))
    }

    const handleJobHold = () => {
        infoAlert("Putting job on hold...");

        changeJobStatus(details.id, "awaiting-confirmation")
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: "awaiting-confirmation" }))
            })
            .catch(e => errorAlert(`Could not put the job on hold. Error: ${e}`))
    }

    const handleJobOpen = () => {
        infoAlert("Re-opening job...");

        changeJobStatus(details.id, "open")
            .then(({ message, job }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: job.status || "open" }))
            })
            .catch(e => errorAlert(`Could not put the job on hold. Error: ${e}`))
    }



    // const handleAdvance = () => {

    //     errorAlert("Please add an LPO or Confirmation Document to advance...");
    //     errorAlert("Customer has not yet reached required deposit to confirm the job.");
    //     successAlert("Job status successfully changed.");
    // }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title}</Title>
                <ActionBar>

                    <ButtonToolbar>
                        <UploadLPODetails
                            job_id={details.id}
                            lpo_details={{
                                lpo_no: details.lpo_no || "",
                                lpo_date: details.lpo_date || ""
                            }}
                            onUpload={job => setDetails(d => ({ ...d, ...job }))}
                        />
                    </ButtonToolbar>

                    <ButtonToolbar>
                        <Btn title="Add Expense" href={`/app/expenses/new?job=${details.id}&client=${details.client_id}`}>
                            <i className="fas fa-coins" />
                        </Btn>
                        <Btn title="Add Requisition" href={`/app/requisitions/new?job=${details.id}&client=${details.client_id}`}>
                            <i className="fas fa-circle-dollar-to-slot" />
                        </Btn>
                    </ButtonToolbar>

                    <ButtonToolbar>
                        {!details.hasInvoice && <>
                            <Btn title="Create Invoice" href={`/app/invoices/new?client=${details.client_id}&job=${details.id}`} >
                                <i className="fas fa-file-invoice" />
                            </Btn>
                            <Select
                                as={Btn}
                                title="Attach Invoice"
                                items={invoices}
                                onSelect={handleInvoiceSelect}
                                maxItems={1}
                            >
                                <i className="fas fa-paperclip" />
                            </Select>
                        </>
                        }
                        {(details.hasInvoice && (['unpaid', 'partially-paid', 'overdue'].indexOf(details.invoice.status) !== -1)) &&
                            <Btn href={`/app/payments/new?client=${details.client_id}&invoice=${details.invoice.id}&return=jobs|${details.id}`} title="Add Payment">
                                <i className="fas fa-coins" />
                            </Btn>
                        }

                    </ButtonToolbar>
                    <ButtonToolbar>
                        {/* <Btn title="Download Invoice" href={`${APIURL}/jobs/${details.id}/pdf`} isExternalLink>
                            <i className="fas fa-file-invoice" />
                        </Btn> */}
                        {/* <ConfirmAction
                            as={Btn}
                            title="Advance Status"
                            confirmationText="Are you sure you want to advance this job to confirmed?"
                            handleAction={handleAdvance}
                        >
                            <i className="fas fa-arrow-right" />
                        </ConfirmAction> */}

                        {(
                            (profile.permission_level === 1)
                            &&
                            (confirmedJobStatuses.indexOf(details.status) !== -1)
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Put Job on Hold"
                                confirmationText="Are you sure you want to put this job on hold?"
                                handleAction={handleJobHold}
                            >
                                <i className="far fa-hand" />
                            </ConfirmAction>
                        }

                        {(
                            (profile.permission_level === 1)
                            &&
                            (details.status === "cancelled")
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Reopen Job"
                                confirmationText="Are you sure you want to advance this job to confirmed?"
                                handleAction={handleJobOpen}
                            >
                                <i className="fas fa-lock-open" />
                            </ConfirmAction>
                        }


                        {(
                            (profile.permission_level === 1)
                            &&
                            (["open", "awaiting-deposit", "awaiting-confirmation"].indexOf(details.status) !== -1)
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Confirm Job"
                                confirmationText="Are you sure you want to advance this job to confirmed?"
                                handleAction={handleConfirmJob}
                            >
                                <i className="far fa-thumbs-up" />
                            </ConfirmAction>
                        }

                        {(
                            ((unconfirmedJobStatuses.indexOf(details.status) !== -1) && (details.status !== "cancelled")) ||
                            ((profile.permission_level === 1) && (details.payment_amount === 0))
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Cancel Job"
                                confirmationText="Are you sure you want to cancel this job?"
                                handleAction={handleJobCancel}
                            >
                                <i className="fas fa-times-circle" />
                            </ConfirmAction>
                        }
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <AddDates jobid={details.id} onCreate={addDates} />

                        {!!details.invoice &&
                            <Btn title="Packing List" href={`/app/jobs/${details.id}/packing-list`} >
                                <i className="fas fa-store" />
                            </Btn>
                        }

                    </ButtonToolbar>

                    <ButtonToolbar>
                        {/* <Btn onClick={handleEventDelete} title="Delete Job">
                            <i className="fas fa-trash-alt" />
                        </Btn> */}
                        {(
                            (profile.permission_level === 1)
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Delete Job"
                                confirmationText="Are you sure you want to delete this job?"
                                handleAction={handleJobDelete}
                            >
                                <i className="fas fa-trash-alt" />
                            </ConfirmAction>
                        }
                        <Btn href={`/app/jobs/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/jobs/new" title="New Job">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Job Details</h4>

                <Row>
                    <Col sm={6}>
                        <dl>
                            <dt>Job Type</dt>
                            <dd>{UCWords(details.type)}</dd>
                            <dt>Lead Contact</dt>
                            <dd>
                                {details.lead_contact.display_name} | T:{details.lead_contact.telephone} {!!details.lead_contact.email && ` | E: ${details.lead_contact.email}`}
                            </dd>
                            <dt>Client</dt>
                            <dd>
                                <Link to={`/app/clients/${details.client_id}`}>
                                    {details.client.title}
                                </Link> <br />
                                {details.client_contact.display_name} | T:{details.client_contact.telephone} {!!details.client_contact.email && ` | E: ${details.client_contact.email}`}</dd>
                            <dt>Dates</dt>
                            <dd>
                                {moment(details.job_date).format("ddd DD MMMM YYYY")}
                                {(details.end_date !== details.job_date) &&
                                    <span> to {moment(details.end_date).format("ddd DD MMMM YYYY")}</span>}

                                {details.job_dates.map(d => (
                                    <div key={d.id}>
                                        {moment(d.start).format("ddd DD MMM YYYY")}
                                        {(d.end !== d.start) &&
                                            <span> - {moment(d.end).format("ddd DD MMM YYYY")}</span>}
                                        <a className="ms-2 ms-sm-4 text-danger small" href="#." onClick={e => handleDateDelete(e, d.id)}>
                                            <i className="fas fa-times me-1" />Delete
                                        </a>
                                    </div>
                                ))}
                                <div>
                                    From {moment(details.start_time, "HH:mm:ss").format("HH:mm")} to {moment(details.end_time, "HH:mm:ss").format("HH:mm")}
                                </div>
                            </dd>
                            <dt>Location</dt>
                            <dd>{details.location}</dd>
                            <dt>Deliverables</dt>
                            <dd>{UCWords(details.deliverables) || "n/a"}</dd>
                            <dt>LPO Details</dt>
                            <dd>
                                {!details.lpo_date ? "n/a" : <span>No: {details.lpo_no} sent on {moment(details.lpo_date).format("DD MMM YYYY")}</span>}
                            </dd>
                        </dl>
                    </Col>
                    <Col sm={6}>
                        <dl>
                            <dt>Status</dt>
                            <dd>{UCWords(details.status.replace(/-/g, " "))}</dd>
                            <dt>Deposit</dt>
                            <dd>{details.depositRequired ? `${details.min_deposit}%` : "Not Required"}</dd>
                            <dt>Payment Before Delivery?</dt>
                            <dd>{details.fullPaymentBeforeDelivery ? `Required` : "Not Required"}</dd>
                            {(details.hasInvoice && (profile.permission_level < 4)) && <>
                                <dt>Total Amount</dt>
                                <dd>
                                    UGX {cur(details.total_amount, 0).format()}
                                    <Link to={`/app/invoices/${details.invoice.id}`} className="small">
                                        <i className="fas fa-file-invoice ms-2 me-1" />{details.invoice.invoice_no}
                                    </Link>
                                </dd>
                                <dt>Total Paid</dt>
                                <dd>UGX {cur(details.payment_amount, 0).format()}</dd>
                                <dt>Balance</dt>
                                <dd>UGX {cur(details.balance, 0).format()}</dd>
                            </>}
                        </dl>
                    </Col>
                </Row>

                <ViewDocuments documents={details.documents} onDocUpload={handleDocUpload} onDocDelete={handleDocDelete} job_id={details.id} />
                <ViewRequisitions requisitions={details.requisitions || []} />
                <ViewExpenses expenses={details.expenses} />
                <Notes
                    jobid={details.id}
                    notes={details.notes || []}
                    addNote={addNote}
                    deleteNote={handleDelete}
                />

            </div>

        </>
    )

}


const ViewDocuments = ({ documents, onDocUpload, onDocDelete, job_id }) => {

    const deleteDoc = (id) => {
        infoAlert("Deleting Document...");

        deleteDocument(job_id, id)
            .then(({ message }) => {
                successAlert(message);
                onDocDelete(id);
            })
            .catch(errorAlert)
    }


    return (
        <>
            <h4 className="mt-3 mt-md-5">Documents</h4>

            {(documents.length === 0) &&
                <>
                    <p className="lead">
                        No documents have been uploaded for this job.
                    </p>
                </>
            }
            <ListGroup variant="flush">
                {documents.map(d => (
                    <ListGroup.Item key={d.id} className="d-flex align-items-center">

                        <i className={`${fileIcons[d.file_group]} fa-3x me-3 me-sm-4`} />
                        <div>
                            <h6 className="mb-1">{d.file_title}</h6>
                            <p className="mb-0 text-muted">
                                {UCWords(d.category.replace(/-/g, " "))}
                            </p>
                            <p className="mb-1 text-muted">
                                {d.display_name} | {moment(d.date_added).format("DD MMM YYYY, HH:mm")}
                            </p>
                            <div className="hstack gap-3 small">
                                <a href={`${APIURL}/files/${d.file_slug}/view`} className="text-secondary" target={`_blank`}>
                                    <i className="fas fa-eye me-1" />View
                                </a>
                                <a href={`${APIURL}/files/${d.file_id}/download`} className="text-primary" target={`_blank`}>
                                    <i className="fas fa-download me-1" />Download
                                </a>
                                <ConfirmAction
                                    as={"a"}
                                    handleAction={() => deleteDoc(d.id)}
                                    href="#."
                                    className="text-danger"
                                    target="_blank"
                                    confirmationText="Are you sure you want to delete this document?"
                                >
                                    <i className="fas fa-trash-alt me-1" />Delete
                                </ConfirmAction>
                            </div>
                        </div>

                    </ListGroup.Item>
                ))}
            </ListGroup>

            <div className="my-2">
                <UploadDocument
                    onUpload={onDocUpload}
                    job_id={job_id}
                />
            </div>
        </>
    )
}



/**
 * @param {Object} props
 * @param {import("../../resources/api/requisitions").RequisitionObject[]}  props.requisitions
 */
const ViewRequisitions = ({ requisitions }) => {

    return (
        <>
            <h4 className="mt-3 mt-md-5">Requisitions</h4>
            <Table responsive hover striped size="sm" style={{ minWidth: "500px", maxWidth: "600px" }}>
                <thead>
                    <tr>
                        <th>Requisition</th>
                        <th>Date</th>
                        <th>Contact</th>
                        <th className="text-end">Amount</th>
                        <th>Contact</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        requisitions.map(i => (
                            <tr key={i.id}>
                                <td><Link to={`/app/requisitions/${i.id}`}>{i.reference}</Link></td>
                                <td>{moment(i.requisition_date).format("DD MMM YYYY")}</td>
                                <td>{i.display_name}</td>
                                <td className="text-end">{cur(i.total_amount, 0).format()}</td>
                                <td>{UCWords(i.status.replace(/-/g, " "))}</td>
                            </tr>
                        ))
                    }
                    {requisitions.length === 0 &&
                        <tr>
                            <td colSpan={5} className="text-center">
                                No requisitions
                            </td>
                        </tr>

                    }
                </tbody>
                {(requisitions.length > 0) &&
                    <tfoot>
                        <tr>
                            <th colSpan={3} />
                            <th className="text-end">
                                {cur(
                                    requisitions
                                        .reduce(
                                            (p, c) => p + parseFloat(c.total_amount), 0),
                                    0)
                                    .format()}
                            </th>
                            <th />
                        </tr>
                    </tfoot>
                }
            </Table>
        </>
    )
}


/**
 * @param {Object} props
 * @param {import("../../resources/api/expenses").ExpenseObject[]} props.expenses
 */
const ViewExpenses = ({ expenses }) => {

    return (
        <>
            <h4 className="mt-3 mt-md-5">Expenses</h4>
            <Table responsive hover striped size="sm" style={{ minWidth: "500px", maxWidth: "600px" }}>
                <thead>
                    <tr>
                        <th>Expense</th>
                        <th>Date</th>
                        <th>Vendor</th>
                        <th>Account</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        expenses.map(i => (
                            <tr key={i.id}>
                                <td><Link to={`/app/expenses/${i.id}`}>{i.reference}</Link></td>
                                <td>{moment(i.expense_date).format("DD MMM YYYY")}</td>
                                <td>{i.vendor || "-"}</td>
                                <td>{UCWords(i.account.replace(/-/g, " "))}</td>
                                <td className="text-end">{cur(i.total_amount, 0).format()}</td>
                            </tr>
                        ))
                    }
                    {expenses.length === 0 &&
                        <tr>
                            <td colSpan={5} className="text-center">
                                No expenses
                            </td>
                        </tr>

                    }
                </tbody>
                {(expenses.length > 0) &&
                    <tfoot>
                        <tr>
                            <th colSpan={4} />
                            <th className="text-end">
                                {cur(
                                    expenses
                                        .reduce(
                                            (p, c) => p + parseFloat(c.total_amount), 0),
                                    0)
                                    .format()}
                            </th>
                        </tr>
                    </tfoot>
                }
            </Table></>
    )
}


/**
 * @param {Object} props
 * @param {string} props.jobid 
 * @param {import("../../resources/api/events").NoteObject[]} props.notes 
 * @param {(details: import("../../resources/api/events").NoteObject) => void} props.addNote 
 * @param {(id: string) => void} props.deleteNote 
 * @returns 
 */
const Notes = ({ jobid, notes, addNote, deleteNote }) => {

    const { profile } = useContext(AppUserContext);

    const handleDelete = (e, id) => {
        e.preventDefault();
        infoAlert("Just a minute...");
        deleteNote(id);
    }

    if (notes.length === 0) {
        return (
            <>
                <h4 className="mt-3 mt-md-5">
                    Notes
                    <NewNote jobid={jobid} onCreate={addNote} />
                </h4>
                <p className="lead">
                    No notes have been added to this event.
                </p>
            </>
        )
    }

    return (
        <>
            <h4 className="mt-3 mt-md-5">
                Notes
                <NewNote jobid={jobid} onCreate={addNote} />
            </h4>

            <ListGroup variant="flush">

                {notes.map(n => (
                    <ListGroup.Item className="px-1" key={n.id}>
                        <div className="d-flex justify-content-between align-items-center">
                            <small className="text-muted text-uppercase">
                                {n.display_name}, {moment(n.date_added).format('DD MMMM YYYY, HH:mm')}
                            </small>
                            {(profile.id === n.added_by) &&
                                <a href="#." className="text-danger small" onClick={e => handleDelete(e, n.id)}>
                                    <i className="fas fa-times me-1" />Delete
                                </a>}

                        </div>

                        <p className="mb-1" dangerouslySetInnerHTML={{ __html: n.content.replace(/\n/g, "<br />") }} />
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </>
    )
}


/**
 * 
 * @param {Object} props 
 * @param {string} props.jobid 
 * @param {(details: import("../../resources/api/events").NoteObject) => void} props.onCreate 
 * @returns 
 */
const NewNote = ({ jobid, onCreate }) => {

    const [text, setText] = useState("");
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleClick = e => {
        e.preventDefault();
        setShow(true);
    }

    const handleClose = () => {
        setText("");
        setSubmitting(false);
    }

    const handleSubmit = e => {

        e.preventDefault();

        setSubmitting(true);

        createNote(jobid, text)
            .then(({ note, message }) => {
                setShow(false);
                successAlert(message);
                onCreate(note);
            })
            .catch(e => errorAlert(e))
            .finally(() => setSubmitting(false))
    }

    return (
        <>
            <a className="text-secondary ms-3 small" href="#." onClick={handleClick}>
                <i className="fas fa-plus-circle" />
            </a>

            <Modal show={show} onExit={handleClose} centered>
                <Modal.Body>
                    <Form.Control as="textarea"
                        size="sm"
                        value={text}
                        placeholder="What would you like to say?"
                        onChange={e => setText(e.currentTarget.value)}
                        style={{ height: "200px" }}
                    />

                </Modal.Body>
                <Modal.Footer className="py-1">
                    {(text.length > 10) &&
                        <SubmitButton isSubmitting={submitting} onClick={handleSubmit}>
                            Add Note
                        </SubmitButton>
                    }

                    <CancelButton onClick={() => setShow(false)} isSubmitting={submitting}>
                        Cancel
                    </CancelButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}/**
 * 
 * @param {Object} props 
 * @param {string} props.string 
 * @param {(details: import("../../resources/api/jo").DateObject) => void} props.onCreate 
 * @returns 
 */


const AddDates = ({ jobid, onCreate }) => {

    const [details, setDetails] = useState({ start: "", end: "" });
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const formref = useRef();

    const handleClose = () => {
        setDetails({ start: "", end: "" });
        setSubmitting(false);
    }

    const handleSubmit = e => {

        e.preventDefault();

        if (!formref.current.checkValidity()) {
            setValidated(true);
            errorAlert("There are some errors with your inputs.");
            return;
        }

        setValidated(false);

        setSubmitting(true);

        createDates(jobid, details)
            .then(({ job_date, message }) => {
                setShow(false);
                successAlert(message);
                onCreate(job_date);
            })
            .catch(e => errorAlert(e))
            .finally(() => setSubmitting(false))
    }

    return (
        <>
            <Btn title="Add Dates" onClick={() => setShow(true)}>
                <i className="far fa-calendar-plus" />
            </Btn>

            <Modal show={show} onExit={handleClose} centered size="sm">
                <Modal.Body className="pt-1">
                    <Form ref={formref} noValidate validated={validated} >
                        <Row className="g-2">
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">Start Date</Form.Label>
                                <DateSelector
                                    value={details.start}
                                    onChange={date =>
                                        setDetails(d => ({
                                            ...d,
                                            start: date,
                                            end: (!d.end || (d.end < date)) ? date : d.end
                                        }))}
                                    required={true}

                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid date must be provided.
                                </Form.Control.Feedback>
                            </Col>
                            <Col sm={12} className="my-1">
                                <Form.Label className="form-field-title">End Date</Form.Label>
                                <DateSelector
                                    value={details.end}
                                    onChange={date =>
                                        setDetails(d => ({
                                            ...d,
                                            end: date
                                        }))}
                                    required={true}
                                    minDate={details.start}

                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid date must be provided.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>


                </Modal.Body>
                <Modal.Footer className="py-1">
                    <SubmitButton isSubmitting={submitting} onClick={handleSubmit}>
                        Add Dates
                    </SubmitButton>
                    <CancelButton onClick={() => setShow(false)} isSubmitting={submitting}>
                        Cancel
                    </CancelButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}


const JobDetails = () => {

    const { jobid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError(null);

        getJob(jobid, ['client', 'invoice', 'notes', 'job_dates', 'expenses', 'lead_contact', 'client_contact', 'requisitions', 'documents'])
            .then(({ job }) => {
                job.notes.sort((a, b) => sortFunction(a, b, 'date_added'));
                setDetails(job);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [jobid])

    //load events details
    if (!isLoaded) {
        return <PageLoading>Loading job details...</PageLoading>
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/edit" element={<EditJob details={details} setDetails={setDetails} />} />
            <Route path="/packing-list" element={<PackingList details={details} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default JobDetails;