import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import New from './order-manipulation';
import View from './view-order';
import ViewAll from './view-orders';

const PurchaseOrders = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access purchase orders.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="new" element={<New />} />
            <Route path=":orderid/*" element={<View />} />
            <Route path="/" element={<ViewAll />} />
        </Routes>
    )
};

export default PurchaseOrders;