
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
// import { ErrorLoading } from '../../components/loading';
import RequisitionForm from './requisition-form';
import { useEffect } from 'react';
import { useState } from 'react';
import { PageLoading } from '../../components/loading';

const requisition = {
    category: '',
    requisition_date: '',
    description: '',
    client_id: '',
    job_id: '',
    receipt_file_id: '',
    markup: "",
    user_reference: "",
    vendor: "",
    items: [],
}

const NewRequisition = () => {

    const nav_items = [
        { title: 'Requisitions', href: '/app/requisitions' },
        { title: 'New Requisition' }
    ];

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(requisition);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        let client_id = searchParams.get('client') || "",
            job_id = searchParams.get('job') || "";
        const _details = { ...requisition, client_id, job_id };

        setDetails(_details);
        setLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (details) => {
        let job_id = searchParams.get('job') || "";

        if (job_id) {
            navigate(`/app/jobs/${job_id}`);
        } else {
            navigate(`/app/requisitions/${details.id}`);
        }

    }

    // const { profile } = useContext(AppUserContext);

    // if (profile.roles.indexOf('finance') === -1) {
    //     return <ErrorLoading>You do not have permission to create an expense.</ErrorLoading>;
    // }


    if (!loaded) return <PageLoading />;



    return (
        <>
            <Nav items={nav_items} />

            <RequisitionForm
                details={details}
                action="create"
                onSubmit={handleSubmit}
            />
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/requisitions').RequisitionObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/requisitions').RequisitionObject>>} props.setDetails 
 */
const EditRequisition = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Requisitions', href: '/app/requisitions' },
        { title: details.reference, href: `/app/requisitions/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/requisitions/${details.id}`);
    }

    // const { profile } = useContext(AppUserContext);

    // if (profile.roles.indexOf('finance') === -1) {
    //     return <ErrorLoading>You do not have permission to update an expense.</ErrorLoading>;
    // }


    return (

        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <RequisitionForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { EditRequisition };


export default NewRequisition;