import { APIURL, postData, getData, deleteData } from "../fetch";



/**
 * 
 * @typedef {{
 *  id: string,
 *  staff_id: string
 *  display_name: string
 *  payment_date: string
 *  total_amount: number 
 *  reason: string 
 *  salary_id: string 
 *  expense_date: string|null
 *  period_date: string
 *  period_year: number
 *  period_month: number
 *  status: "open"|"approved"|"paid"
 *  staff: import("./payroll").StaffObject
 * }} DeductionObject 
 */




/**
 * 
 * @param {DeductionObject} data 
 * @returns {Promise<{code: number, message: string, deduction: DeductionObject}>}
 */
const createDeduction = (data) => {
    return postData({
        url: `${APIURL}/deductions`,
        data
    })
}

/**
 * 
 * @param {DeductionObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, deduction: DeductionObject}>}
 */
const updateDeduction = (data, id) => {
    return postData({
        url: `${APIURL}/deductions/${id}`,
        data
    })
}

/**
 * 
 * @param {DeductionObject} data 
 * @returns {Promise<{code: number, message: string, deductions: DeductionObject[]}>}
 */
const getDeductions = () => getData({ url: `${APIURL}/deductions` })



/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, deduction: DeductionObject}>}
 */
const getDeduction = (id, includes = null) => {

    const obj = { url: `${APIURL}/deductions/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {"draft"|"awaiting-approval"|"approved"} status 
 * @returns  {Promise<{code: number, message: string, deduction: DeductionObject}>}
 */
const changeDeductionStatus = (id, status) => postData({ url: `${APIURL}/deductions/${id}/status/${status}` });




/**
 * @param {string} id ID 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteDeduction = id => deleteData({ url: `${APIURL}/deductions/${id}` });




export {
    createDeduction,
    getDeduction,
    getDeductions,
    updateDeduction,
    changeDeductionStatus,
    deleteDeduction
}