import VendorForm from './vendor-form';
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
import { useNavigate, useSearchParams } from 'react-router-dom';

const vendorObject = {
    title: "", contact_person: "", email: "", telephone: "", address: ""
}

const NewVendor = () => {

    const nav_items = [
        { title: 'Vendors', href: '/app/vendors' },
        { title: 'New Vendor' }
    ];

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleSubmit = (details) => {

        const action = searchParams.get('action');

        if (action === "create_bill") {
            navigate(`/app/jobs/new?vendor=${details.id}`);
        } else if (action === "create_invoice") {
            navigate(`/app/invoices/new?vendor=${details.id}`);
        } else {
            navigate(`/app/vendors/${details.id}`);
        }
    }


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <VendorForm
                    details={vendorObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/vendors').VendorObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/vendors').VendorObject>>} props.setDetails 
 */
const EditVendor = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Vendors', href: '/app/vendors' },
        { title: details.title, href: `/app/vendors/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/vendors/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.title}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <VendorForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewVendor, EditVendor };