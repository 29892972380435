import React, { useRef, useState } from "react";
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../components/btns";
import { errorAlert, successAlert } from "../../components/toastr";
import { createStaff, updateStaff, updateStaffContact } from "../../resources/api/payroll";
import { staffTypes } from "../../assets/constants";
import { UCWords } from "../../components/resources";
import { DateSelector } from "../../components/datetimepicker";


const { Control, Label } = Form;

/**
 * @param {Object} props
 * @param {"create"|"user"|"salary"} props.action
 * @param {(details: import("../../resources/api/payroll").StaffObject) => void} props.onUpload
 * @param {{
 *  show: boolean
 *  setShow: React.Dispatch<React.SetStateAction<boolean>>
 * }} props.show
 * @param {{
 *  details: import("../../resources/api/payroll").StaffObject
 *  setDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/payroll").StaffObject>>
 * }} props.staffDetails
 */
const StaffForm = ({ onUpload, action = "create", staffDetails, show: toShow }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { details, setDetails } = staffDetails;



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;
        const data = {
            ...details,
            isActive: details.isActive ? 1 : 0
        };

        if (action === "create") {
            promise = createStaff(data);
        } else {
            promise = (action === "salary") ? updateStaff(data, details.id) : updateStaffContact(data, details.id);
        }

        promise
            .then(({ staff, message }) => {
                form.reset();
                successAlert(message);
                onUpload(staff);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered animation={false} size="lg">
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>

                    {(action !== "salary") &&
                        <>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">First Name(s)</Label>
                                    <Control
                                        value={details.other_names}
                                        onChange={e => setDetails({ ...details, other_names: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Contact must have other names
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Last Name</Label>
                                    <Control
                                        value={details.last_name}
                                        pattern="[a-zA-Z0-9]+"
                                        onChange={e => setDetails({ ...details, last_name: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        A surname (last name) must be provided and cannot have spaces or special characters.
                                    </Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Email</Label>
                                    <Control
                                        type="email"
                                        value={details.email}
                                        onChange={e => setDetails({ ...details, email: e.currentTarget.value })}
                                        required={!details.telephone}
                                    />
                                    <Control.Feedback type="invalid">
                                        A valid email address must be provided.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Telephone</Label>
                                    <Control
                                        pattern="^\+?[1-9]{1}[0-9]{0,2}(-|\s)?([0-9]{2,4}(-|\s)?)+$"
                                        value={details.telephone}
                                        onChange={e => setDetails({ ...details, telephone: e.currentTarget.value })}
                                        required={!details.email}
                                    />
                                    <Control.Feedback type="invalid">
                                        The telephone number should be in the international format.
                                    </Control.Feedback>
                                </Col>
                            </Row>
                        </>
                    }

                    {(action !== "user") &&
                        <>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Staff Type</Label>
                                    <Form.Select
                                        value={details.staff_type}
                                        onChange={e => setDetails({ ...details, staff_type: e.currentTarget.value })}
                                        required
                                    >
                                        <option value="" />
                                        {staffTypes.map(t => <option key={t} value={t}>{UCWords(t.replace(/-/g, " "))}</option>)}
                                    </Form.Select>
                                    <Control.Feedback type="invalid">
                                        Must be provided.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Salary/Wage</Label>
                                    <InputGroup>
                                        <Control
                                            type="number"
                                            step={50}
                                            value={details.pay_rate}
                                            onChange={e => setDetails({ ...details, pay_rate: e.currentTarget.value })}
                                            required
                                        />
                                        <InputGroup.Text className="border-0">per</InputGroup.Text>
                                        <Form.Select
                                            value={details.pay_unit}
                                            onChange={e => setDetails({ ...details, pay_unit: e.currentTarget.value })}
                                            required
                                        >
                                            <option value="month">month</option>
                                            <option value="week">week</option>
                                            <option value="day">day</option>
                                            <option value="hour">hour</option>
                                        </Form.Select>
                                        <Control.Feedback type="invalid">
                                            THe unit must be provided
                                        </Control.Feedback>

                                        <Control.Feedback type="invalid">
                                            The salary/wage much be provided & should be a multiple of 50.
                                        </Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="g-1">
                                <Col sm={6} md={4} className="my-1">
                                    <Label className="form-field-title">Designation</Label>
                                    <Control
                                        value={details.designation}
                                        onChange={e => setDetails({ ...details, designation: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Member should have a desgination
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} md={4} className="my-1">
                                    <Label className="form-field-title">TIN</Label>
                                    <Control
                                        value={details.tin_no}
                                        pattern="[0-9]{5,}"
                                        onChange={e => setDetails({ ...details, tin_no: e.currentTarget.value })}
                                        required={details.staff_type !== "casual"}
                                    />
                                    <Control.Feedback type="invalid">
                                        TIN no should only be figures and required when staff is not casual.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} md={4} className="my-1">
                                    <Label className="form-field-title">NSSF No</Label>
                                    <Control
                                        value={details.nssf_no}
                                        pattern="[0-9]{5,}"
                                        onChange={e => setDetails({ ...details, nssf_no: e.currentTarget.value })}
                                        required={details.staff_type === "permanent"}
                                    />
                                    <Control.Feedback type="invalid">
                                        NSSF no should be only figures and required when staff is permanent
                                    </Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="g-1">
                                <Col sm={6} md={4} className="my-1">
                                    <Label className="form-field-title">Bank Name</Label>
                                    <Control
                                        value={details.bank_name}
                                        onChange={e => setDetails({ ...details, bank_name: e.currentTarget.value })}
                                        required={!!details.bank_account_no}
                                    />
                                    <Control.Feedback type="invalid">
                                        Name is required when bank account no is provided.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} md={4} className="my-1">
                                    <Label className="form-field-title">Bank Account No</Label>
                                    <Control
                                        value={details.bank_account_no}
                                        pattern="[0-9]{5,}"
                                        onChange={e => setDetails({ ...details, bank_account_no: e.currentTarget.value })}
                                        required={!!details.bank_name}
                                    />
                                    <Control.Feedback type="invalid">
                                        Account no should only be figures and required when bank name is provided.
                                    </Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Start Date</Label>
                                    <DateSelector
                                        value={details.start_date}
                                        onChange={date =>
                                            setDetails(d => ({
                                                ...d,
                                                start_date: date,
                                            }))}
                                        required={true}
                                        minDate={"2021-01-01"}

                                    />
                                    <Control.Feedback type="invalid">
                                        A valid date must be provided.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">End Date</Label>
                                    <DateSelector
                                        value={details.end_date}
                                        onChange={date =>
                                            setDetails(d => ({
                                                ...d,
                                                end_date: date,
                                            }))}
                                        required={!details.isActive}
                                        minDate={"2021-01-01"}

                                    />
                                    <Control.Feedback type="invalid">
                                        A valid date must be provided.
                                    </Control.Feedback>
                                    <Form.Check
                                        className=""
                                        id="isBillSwitch"
                                        checked={details.isActive}
                                        onChange={() => setDetails(d => ({ ...d, isActive: !d.isActive }))}
                                        label={details.isActive ? "Employee is active" : "Employee is inactive"}
                                    />
                                </Col>
                            </Row>
                        </>
                    }


                    <Row>
                        <Col className="mt-4 mb-3 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


export default StaffForm;