import { APIURL, postData, getData, deleteData } from "../fetch";



/**
 * 
 * @typedef {{
 * id: string,
 * title: string,
 * slug: string
 * description: string 
 * download_link: string 
 * size: number 
 * version: number 
 * content_type: string 
 * file_group: string 
 * date_added: string
 * added_by: string
 * jobs: import("./jobs").JobObject
 * invoices: import("./invoices").InvoiceObject
 * contact_persons: ContactPersonObject[]
 * }} FileObject 
 */



/**
 * 
 * @param {ClientObject} data 
 * @returns {Promise<{code: number, message: string, client: ClientObject}>}
 */
const createFile = (data) => {
    return postData({
        url: `${APIURL}/clients`,
        data
    })
}

/**
 * 
 * @param {ClientObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, client: ClientObject}>}
 */
const updateFile = (data, id) => {
    return postData({
        url: `${APIURL}/clients/${id}`,
        data
    })
}

/**
 * 
 * @param {ClientObject} data 
 * @returns {Promise<{code: number, message: string, files: FileObject[]}>}
 */
const getFiles = () => getData({ url: `${APIURL}/files` })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, file: FileObject}>}
 */
const getFile = (id, includes = null) => {

    const obj = { url: `${APIURL}/files/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteFile = id => deleteData({ url: `${APIURL}/files/${id}` });



/**
 * 
 * @param {string} id 
 * @param {JobDocObject} data
 * @returns  {Promise<{code: number, message: string, document: JobDocObject}>}
 */

const uploadDocument = (data) => postData({ url: `${APIURL}/resource-center`, data });


const getDocuments = () => getData({ url: `${APIURL}/resource-center` });

const deleteDocument = (id) => deleteData({ url: `${APIURL}/resource-center/${id}` });



export {
    createFile,
    updateFile,
    getFiles,
    getFile,
    deleteFile,
    uploadDocument,
    getDocuments,
    deleteDocument
}