import { Editor } from '@tinymce/tinymce-react';
import { fetchData, IMAGESURL } from './../resources/fetch';
import { success as successAlert, error as errorAlert } from './toastr';

const APILEY = 'njlckhxn9oc3dhym2iqllqwvgp45bp5vn4xy7ryfeynhl4we';


/**
 * base settings for tiny mce.
 */
const base_settings = {
    plugins: 'spellchecker autolink lists link paste',
    menubar: false,
    relative_urls: false,
    remove_script_host: false,
    toolbar: 'link | bold italic underline | align | bullist numlist | outdent indent | undo redo  | paste removeformat',
    toolbar_mode: 'floating',
    toolbar_location: 'bottom',
    inline: true,
    browser_spellcheck: true,
    paste_as_text: true
}

/**
 * Settings for tripped editor
 */
const stripped_settings = {
    plugins: 'spellchecker autolink lists link hr toc paste',
    menubar: false,
    relative_urls: false,
    remove_script_host: false,
    toolbar: 'toc | blockquote hr | media link | formatselect | bold italic underline | align | bullist numlist outdent indent | undo redo  | paste removeformat',
    toolbar_mode: 'floating',
    toolbar_location: 'bottom',
    inline: true,
    browser_spellcheck: true,
    paste_as_text: true,
    block_formats: 'Paragraph=p; Heading=h2; Section Heading=h4; Sub Section Heading=h5',
    formats: {
        h2: {
            block: 'h2',
            classes: 'heading'
        },
        h4: {
            block: 'h4',
            classes: 'sub-heading'
        },
        h5: {
            block: 'h5',
            classes: 'sub-sub-heading'
        },
        figcaption: {
            block: 'figcaption',
            classes: 'figcaption'
        },
        blockquote: {
            block: 'blockquote',
            classes: 'blockquote'
        }

    }
}


/**
 * Settings for tinycme for full blown HTML editor
 */
const tinymceSettings = {
    plugins: 'spellchecker code autolink lists media table image link hr toc paste',
    menubar: false,
    placeholder: 'Click in here to start typing',
    relative_urls: false,
    remove_script_host: false,
    toolbar: 'table | toc | blockquote hr | media image link | formatselect | styleselect | bold italic underline | align | bullist numlist outdent indent | undo redo  | paste removeformat | code',
    toolbar_mode: 'floating',
    toolbar_location: 'bottom',
    inline: true,
    browser_spellcheck: true,
    paste_as_text: true,
    images_upload_credentials: true,
    image_caption: true,
    image_dimensions: false,
    image_class_list: [{
        title: 'Fluid',
        value: 'img-fluid'
    }],
    table_default_attributes: {
        className: 'table table-hover table-striped'
    },
    table_class_list: [{
        title: 'Default',
        value: 'table table-hover table-striped'
    }],
    block_formats: 'Paragraph=p; Heading=h2; Section Heading=h4; Sub Section Heading=h5',
    formats: {
        h2: {
            block: 'h2',
            classes: 'heading'
        },
        h4: {
            block: 'h4',
            classes: 'sub-heading'
        },
        h5: {
            block: 'h5',
            classes: 'sub-sub-heading'
        },
        figcaption: {
            block: 'figcaption',
            classes: 'figcaption'
        },
        blockquote: {
            block: 'blockquote',
            classes: 'blockquote'
        }

    },
    style_formats: [
        {
            title: 'Blockquote',
            block: 'blockquote',
            selector: 'blockquote'
        },
        {
            title: 'Code',
            format: 'code'
        },
        {
            title: 'Caption',
            format: 'figcaption'
        },
        {
            title: 'Small Table',
            selector: 'table',
            classes: 'table-sm'
        }
    ],
    style_formats_autohide: true
}


/**
 * Handles upload 
 * @param {Blob} blobInfo 
 * @param {(location:string) => void} success 
 * @param {(error: string) => void} failure 
 */
const handleImageUpload = (title, description, blobInfo, success, failure) => {

    fetchData({ url: '/api/files/token' })
        .then(({ token }) => {
            const url = `${IMAGESURL}/upload/`,
                formdata = new FormData(),
                xhr = new XMLHttpRequest(),
                rand = Math.floor(Math.random() * Math.floor(100000));


            formdata.append('file', blobInfo.blob(), blobInfo.filename());
            formdata.append('title', `${title} ${rand}`);
            formdata.append('category', 'web-image');
            formdata.append('description', description);
            formdata.append('authorisation', 'public');

            xhr.onreadystatechange = () => {

                if (xhr.readyState !== 4) return;

                if (xhr.status === 200 || xhr.status === 201) {

                    try {
                        const r = JSON.parse(xhr.responseText);

                        if (r.code !== 0) return failure(r.message);
                        successAlert(`File has successfully been uploaded.`);
                        return success(`${IMAGESURL}/images/${r.file.slug}`);

                    } catch (e) {
                        return failure('Sorry, an unknown error occurred. Error: ' + e.message);
                    }

                }

                try {
                    const r = JSON.parse(xhr.responseText);
                    return failure(r.message);

                } catch (e) {
                    return failure(xhr.responseText);
                }

            }

            xhr.open('POST', url, true);
            if (token) xhr.setRequestHeader('Authorization', `Basic ${token}`);
            xhr.send(formdata);

        })
        .catch(err => {
            err = `Failed to access a token to upload the file. ${err}`;
            errorAlert(err);
            return failure(err);
        })


}

/**
 * 
 * @param {{
 * value: string
 * initialValue: string
 * onChange: (htmlText: string) => void
 * placeholder?: string 
 * }} props 
 */
const HTMLEditor = ({ value = "", onChange, placeholder = "Start typing" }) => {
    return (
        <Editor
            className="p-2"
            apiKey={APILEY}
            // initialValue={initialValue}
            value={value}
            init={{ ...base_settings, placeholder, body_class: "p-2 border" }}
            onEditorChange={v => onChange(v)}
        />
    )
}

/**
 * 
 * @param {{
 * value: string
 * onChange: (htmlText: string) => void
 * placeholder?: string
 * }} props 
 */
const StrippedEditor = ({ value = "", onChange, placeholder = "Start typing" }) => {

    return (
        <Editor
            apiKey={APILEY}
            initialValue={value}
            init={{ ...stripped_settings, placeholder, body_class: "p-2" }}
            onChange={e => onChange(e.target.getContent())}
        />
    )
}


/**
 * 
 * @param {{
 * value: string
 * onChange: (htmlText: string) => void
 * uploadDetails: {title: string, description: string}
 * }} props 
 * @param {string} props.value 
 */
const FullEditor = ({ value = "", onChange, placeholder, uploadDetails }) => {

    const image_upload_handler = (i, s, f) => handleImageUpload(uploadDetails.title, uploadDetails.description, i, s, f);

    return (
        <Editor
            apiKey={APILEY}
            initialValue={value}
            init={{
                ...tinymceSettings,
                placeholder,
                images_upload_handler: image_upload_handler
            }}
            onChange={e => onChange(e.target.getContent())}
        />
    )
}


export { StrippedEditor, HTMLEditor };

export default FullEditor;