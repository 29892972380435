import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
import { getPayment } from '../../resources/api/requisitions';
import { Link, useParams } from 'react-router-dom';
import cur from '../../components/currency';


const ViewRequisitionPayment = () => {

    const [details, setDetails] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const { paymentid } = useParams();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getPayment(paymentid)
            .then(({ payment }) => {
                setDetails(payment);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [paymentid])



    const nav_items = [
        { title: 'Requisitions', href: '/app/requisitions' },
        { title: 'Payments', href: '/app/requisitions/payments' },
        { title: details.reference }
    ];

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading requisition details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.reference}</Title>
                <ActionBar>
                    <Btn href="/app/requisitions/new">
                        <i className="fas fa-plus-circle me-1" />New Requisition
                    </Btn>
                </ActionBar>
            </PageHeader>


            <div className="max-1000">

                <dd>
                    <dt>Expense</dt>
                    <dd><Link to={`/app/expenses/${details.expense_id}`}>View Expense</Link></dd>
                </dd>

                <h4 className='mt-4'>Requisitions</h4>
                <Table responsive hover style={{ maxWidth: '600px' }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ref</th>
                            <th>User</th>
                            <th>Date</th>
                            <th style={{ textAlign: "right" }}>Amount</th>
                        </tr>

                    </thead>
                    <tbody>
                        {details.requisitions.map((e, i) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td><Link to={`/app/requisitions/${e.id}`}>{e.reference}</Link></td>
                                <td>{e.display_name}</td>
                                <td>{moment(e.requisition_date).format("DD MMM YYYY")}</td>
                                <td style={{ textAlign: 'right' }}>{cur(e.total_amount).format()}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4} />
                            <th style={{ textAlign: "right" }}>
                                {cur(details.requisitions.reduce((p, c) => p + c.total_amount, 0)).format()}
                            </th>
                        </tr>

                    </tfoot>
                </Table>

            </div>
        </>
    )

}

export default ViewRequisitionPayment;