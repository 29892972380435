import { useEffect, useRef, useState } from "react";
// import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap";
import { Chart, registerables } from 'chart.js';
import moment from "moment";
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap";
import { hexToRGB } from "../../components/resources";
// import autocolors from 'chartjs-plugin-autocolors';
// import moment from "moment";
// import { arrayAverage } from "../../../components/resources";

Chart.register(...registerables);


/**
 * 
 * @param {Object} props
 * @param {{product: string, payment_month: number, payment_year: number, amount: number}[]}  props.incomes
 * @param {{category: string, payment_month: number, payment_year: number, amount: number}[]}  props.expenses
 * @param {{job_month: number, job_year: number, no_jobs: number}[]}  props.job_count
 * @returns 
 */
const InvoiceExpenditure = ({ incomes = [], expenses = [], job_count = [] }) => {


    const [years, setYears] = useState([]);
    const [data, setData] = useState({ incomes: {}, expenses: {} });

    const [labels, setLabels] = useState([]);
    const [chart_data, setChartData] = useState([]);

    const [selected, setSelected] = useState();



    useEffect(() => {

        let _years = [], _data = {}, _edata = {}, _jdata = {};

        incomes.forEach(inc => {
            let y = parseInt(inc.payment_year),
                m = parseInt(inc.payment_month),
                a = parseFloat(inc.amount);

            if (_years.indexOf(y) === -1) _years.push(y);

            if (!(y in _data)) _data[y] = {};

            if (!(m in _data[y])) _data[y][m] = [];

            _data[y][m].push(a);

        })

        expenses.forEach(inc => {
            let y = parseInt(inc.payment_year),
                m = parseInt(inc.payment_month),
                a = parseFloat(inc.amount);

            if (_years.indexOf(y) === -1) _years.push(y);

            if (!(y in _edata)) _edata[y] = {};

            if (!(m in _edata[y])) _edata[y][m] = [];

            _edata[y][m].push(a);

        })

        job_count.forEach(inc => {
            let y = parseInt(inc.job_year),
                m = parseInt(inc.job_month),
                a = parseFloat(inc.no_jobs);

            if (_years.indexOf(y) === -1) _years.push(y);

            if (!(y in _jdata)) _jdata[y] = {};

            if (!(m in _jdata[y])) _jdata[y][m] = [];

            _jdata[y][m].push(a);

        })

        setYears(_years.sort((a, b) => (b - a)));
        setData({
            incomes: _data,
            expenses: _edata,
            jobs: _jdata
        });
        setSelected(Math.max(..._years));


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(incomes), JSON.stringify(expenses)])

    useEffect(() => {

        if (!selected) return;

        const incs = data.incomes[selected] || {},
            exps = data.expenses[selected] || {},
            jbs = data.jobs[selected] || {},
            _incomes = [],
            _expenses = [],
            _jobs = [],
            max_month = parseInt(moment().format("YYYY")) === selected ? parseInt(moment().format("M")) : 12,
            dataset = [];


        for (let m = 1; m < (max_month + 1); m++) {
            let ti = 0, tj = 0, te = 0;

            if (m in incs) {
                ti = incs[m].reduce((p, c) => p + c, 0);
            } else {
                ti = 0;
            }

            if (m in exps) {
                te = exps[m].reduce((p, c) => p + c, 0);
            } else {
                te = 0;
            }


            if (m in jbs) {
                tj = jbs[m].reduce((p, c) => p + c, 0);
            } else {
                tj = 0;
            }

            _incomes.push(ti);
            _expenses.push(te);
            _jobs.push(tj);

        }

        dataset.push({
            label: 'Work Done',
            data: _incomes,
            fill: false,
            borderColor: "#076514",
            backgroundColor: hexToRGB("#076514", 0.4),
            yAxisID: 'y'
        })
        dataset.push({
            label: 'Expenses',
            data: _expenses,
            fill: false,
            borderColor: "#932C11",
            backgroundColor: hexToRGB("#932C11", 0.4),
            yAxisID: 'y'
        })
        dataset.push({
            label: 'No Jobs',
            data: _jobs,
            fill: false,
            borderColor: "#07347F",
            backgroundColor: hexToRGB("#07347F", 0.4),
            yAxisID: 'y1'
        })

        setChartData(dataset);
        setLabels(Array(max_month).fill(1).map((k, i) => moment(`${i + 1}`, "M").format("MMM")))



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, JSON.stringify(data)])

    const onDPChange = key => setSelected(key);


    return (
        <Card className="my-2 my-sm-5">
            <Card.Header className="d-flex align-items-center justify-content-between py-3">
                <h4 className='my-0'>Work Done Vs No Jobs</h4>
                <span>
                    <Dropdown as={ButtonGroup} className="me-3" align="end">
                        <Button variant="outline-secondary">
                            {selected}
                        </Button>
                        <Dropdown.Toggle split variant="secondary" />
                        <Dropdown.Menu>
                            {years.map((b) =>
                                <Dropdown.Item
                                    onClick={() => onDPChange(b)}
                                    eventKey={b} key={b}
                                >
                                    {b}
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <span className="font-weight-normal">25</span>
                    <span className="text-success ms-3">
                        <i className="fas fa-arrow-up small" /> 5.00%
                    </span> */}
                </span>
            </Card.Header>
            <Card.Body>
                <div style={{ maxHeight: '600px' }}>
                    <IncomeExpenditureChart data={chart_data} labels={labels} />
                </div>
            </Card.Body>
        </Card>
    )
}


const IncomeExpenditureChart = ({ data, labels }) => {

    const canvasElement = useRef();

    useEffect(() => {
        const chart = new Chart(canvasElement.current, {
            type: 'line',
            data: {
                labels,
                datasets: data

                // [{
                //     label: 'Fee Notes',
                //     data: data.feenotes,
                //     backgroundColor: 'rgba(255, 99, 132, 0.2)',
                //     borderColor: 'rgba(255, 99, 132, 1)',
                //     borderWidth: 1,
                //     yAxisID: 'yAxis1',
                //     categoryPercentage: 0.95
                // },
                // {
                //     label: 'No Titles',
                //     type: "line",
                //     data: data.no_titles,
                //     backgroundColor: 'rgba(54, 162, 235, 0.2)',
                //     borderColor: 'rgba(54, 162, 235, 1)',
                //     borderWidth: 1,
                //     yAxisID: 'yAxis2',
                //     categoryPercentage: 0.95
                // },
                // {
                //     label: 'Turn Around Time',
                //     type: "line",
                //     data: data.turnaround_times,
                //     backgroundColor: 'rgba(255, 206, 86, 0.2)',
                //     borderColor: 'rgba(255, 206, 86, 1)',
                //     borderWidth: 1,
                //     yAxisID: 'yAxis3',
                //     categoryPercentage: 0.95
                // }]
            },
            options: {
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: "Amount (UGX)"
                        }
                    },

                    y1: {
                        title: {
                            display: true,
                            text: "Job Count"
                        },
                        position: 'right'
                    }
                },
                elements: {
                    point: {
                        radius: 1
                    },
                    line: {
                        tension: 0.3,
                        borderWidth: 1.5
                    }
                }
            }
        });

        return () => chart.destroy();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(data)])

    return <canvas ref={canvasElement} width="100%" />
}


export default InvoiceExpenditure;