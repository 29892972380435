import { APIURL, postData, getData, deleteData } from "../fetch";

/**
 * 
 * @typedef {{
 *  id: string
 *  advance_id: string
 *  salary_id: string
 *  period_date: string 
 *  amount: number 
 *  payment_date: string
 * }} AdvancePaymentObject 
 */


/**
 * 
 * @typedef {{
 *  id: string,
 *  staff_id: string
 *  display_name: string
 *  payment_date: string
 *  total_amount: number 
 *  duration: number
 *  reason: string 
 *  installment: number 
 *  paid_amount: number
 *  expense_id: string 
 *  expense_date: string|null
 *  period_date: string
 *  period_year: number
 *  period_month: number
 *  status: "open"|"approved"|"paid"
 *  payments: AdvancePaymentObject[]
 *  staff: import("./payroll").StaffObject
 * }} AdvanceObject 
 */




/**
 * 
 * @param {AdvanceObject} data 
 * @returns {Promise<{code: number, message: string, advance: AdvanceObject}>}
 */
const createAdvance = (data) => {
    return postData({
        url: `${APIURL}/advances`,
        data
    })
}

/**
 * 
 * @param {AdvanceObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, advance: AdvanceObject}>}
 */
const updateAdvance = (data, id) => {
    return postData({
        url: `${APIURL}/advances/${id}`,
        data
    })
}

/**
 * 
 * @param {AdvanceObject} data 
 * @returns {Promise<{code: number, message: string, advances: AdvanceObject[]}>}
 */
const getAdvances = () => getData({ url: `${APIURL}/advances` })



/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, advance: AdvanceObject}>}
 */
const getAdvance = (id, includes = null) => {

    const obj = { url: `${APIURL}/advances/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {"draft"|"awaiting-approval"|"approved"} status 
 * @returns  {Promise<{code: number, message: string, advance: AdvanceObject}>}
 */
const changeAdvanceStatus = (id, status) => postData({ url: `${APIURL}/advances/${id}/status/${status}` });


/**
 * 
 * @param {{account_id: string, method: string}} data 
 * @param {string} id idof the advance
 * @returns {Promise<{code: number, message: string, advance: AdvanceObject}>}
 */
const executePayments = (data, id) => postData({ url: `${APIURL}/advances/${id}/pay`, data })


/**
 * @param {string} id ID 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteAdvance = id => deleteData({ url: `${APIURL}/advances/${id}` });




export {
    createAdvance,
    getAdvance,
    getAdvances,
    updateAdvance,
    changeAdvanceStatus,
    executePayments,
    deleteAdvance
}