import XLSX from 'xlsx';
import { error as errorAlert } from './toastr';

/**
 * Read an excel file into js object
 * @param {File} file A file from an input field or downloaded from a link.
 * @returns Object with sheetnames and data
 */
const readFile = file => new Promise(resolve => {

    const reader = new FileReader(),
        result = {};

    reader.onload = e => {
        const data = reader.result,
            workbook = XLSX.read(data, { type: 'array' });

        workbook.SheetNames.forEach(sheetName => {
            const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
            if (roa.length) {
                result[sheetName] = roa;
            }
        })
        resolve(result);
    }
    reader.readAsArrayBuffer(file);

})


// var file = document.getElementById('myExcelFile').files[0];


/**
 * 
 * @param {any[][]| {[sheetname: string]: any[][]}} data 
 * @param {string?} fileName 
 * @param {string} sheetName 
 */
const createFile = (data, fileName = null, sheetName = null) => {

    if (typeof (data) !== 'object') {
        errorAlert('Invalid data format. Unable to create spreadsheet.');
        return;
    }

    let filename = fileName || "Events UG Download.xlsx",
        ws_name = sheetName || "Sheet 1",
        wb, ws,
        isSheet = false;

    wb = XLSX.utils.book_new();


    if (data.constructor === Object) {

        for (let sheetname in data) {
            var item = data[sheetname];

            if (typeof (item) !== 'object' || item.constructor !== Array || item === '') {
                console.warn(sheetname + ' is not an object.');
                continue;
            }

            if (typeof (item[0]) !== 'object' || item[0].constructor !== Array) {
                console.warn('Items in ' + sheetname + ' is not an array.');
                continue;
            }

            if (typeof (item[0][0]) === 'object') {
                console.warn('You cannot pass an object for a value in a cell.');
                continue;
            }

            isSheet = true;

            ws = XLSX.utils.aoa_to_sheet(item);
            XLSX.utils.book_append_sheet(wb, ws, sheetname);
        }

    } else if (data.constructor === Array) {

        if (data === '') {
            console.warn('You passed an empty array.');
            return;
        }

        if (typeof (data[0]) !== 'object' || data[0].constructor !== Array) {
            console.warn('Items in the sheet is not an array.');
        }

        if (typeof (data[0][0]) == 'object') {
            console.warn('You cannot pass an object for a value in a cell.');
        }

        isSheet = true;

        ws = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

    }

    if (!isSheet) {
        console.error('No / invalid data to write to the sheet.');
        return;
    }

    XLSX.writeFile(wb, filename);

}

export { readFile, createFile };




