
import { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
// import SelectInput from "../../components/select";
import { sortFunction } from "../../components/resources";
import cur from "../../components/currency";
import moment from "moment";
import { APIURL } from "../../resources/fetch";



/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/salaries").SalaryObject} props.details
 */
const PaymentLetter = ({ details }) => {

    const nav_items = [
        { title: "Payroll", href: "/app/payroll" },
        { title: 'Salaries', href: '/app/payroll/salaries' },
        { title: moment(details.period_date).format("MMMM YYYY"), href: `/app/payroll/salaries/${details.id}` },
        { title: 'Payment Letter' }
    ];



    const [items, setItems] = useState(details.items.sort((a, b) => sortFunction(a, b, "display_name")));

    const [type, setType] = useState("bank");


    const onReset = () => {
        let _items = [...details.items];

        if (type === "bank")
            _items = _items.filter(i => !!i.bank_name);
        else
            _items = _items.filter(i => !i.bank_name);

        setItems(_items.sort((a, b) => sortFunction(a, b, "display_name")))
    }


    const onDeleteClick = id => {
        setItems(_items => _items.filter(i => i.id !== id));
    }

    const item_string = useMemo(() => {
        return items.map(i => i.id).join(",");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items.length])

    useEffect(() => {
        let _items = [...details.items];

        if (type === "bank")
            _items = _items.filter(i => !!i.bank_name);
        else
            _items = _items.filter(i => !i.bank_name);

        setItems(_items.sort((a, b) => sortFunction(a, b, "display_name")))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])



    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>Generate Payment Statement</Title>
            </PageHeader>

            <div className="max-1000">

                <Row xs={{ cols: "auto" }} className="align-items-center flex-column flex-sm-row g-2">
                    <Col>
                        <div className="mb-3">
                            <Form.Check
                                inline
                                type={"radio"}
                                id={`bank_type`}
                                label={`Bank Letter`}
                                checked={type === "bank"}
                                onClick={() => setType("bank")}
                            />

                            <Form.Check
                                inline
                                type={"radio"}
                                id={`cash_type`}
                                label={`Cash Letter`}
                                checked={type === "cash"}
                                onClick={() => setType("cash")}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="g-1">
                    <Col>
                        <Button className="rounded-pill mx-1" variant="danger" size="sm" onClick={onReset}>
                            <i className="fas fa-undo me-2" />
                            Reset
                        </Button>
                    </Col>
                </Row>

                <Table className="mt-3 mt-sm-5" responsive>
                    <colgroup>
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "5%" }} />
                        <col style={{ width: "30%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Bank</th>
                            <th>Acc No</th>
                            <th>Due</th>
                            <th>...</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.id}>
                                <td>{item.display_name}</td>
                                <td>{item.bank_name}</td>
                                <td>{item.bank_account_no}</td>
                                <td className="text-end">{cur(item.nett_amount, 0).format()}</td>
                                <td>
                                    <Button variant="link" className="rounded-circle text-danger px-0" size="sm" onClick={() => onDeleteClick(item.id)}>
                                        <i className="fas fa-trash-alt me-1" />Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className="text-end">
                                {
                                    cur(
                                        items.reduce((p, c) => (p + (c.nett_amount)), 0),
                                        0
                                    ).format()
                                }
                            </th>
                            <th></th>

                        </tr>
                    </tfoot>

                </Table>



                <Row className="g-1">
                    <Col>
                        <a
                            href={`${APIURL}/salaries/${details.id}/payment-statement/pdf?ids=${item_string}&type=${type}`}
                            className="btn btn-success rounded-pill"
                        >
                            <i className="fas fa-file-pdf me-2" />
                            Generate Letter
                        </a>
                    </Col>
                </Row>
            </div>

        </>
    )

}




export default PaymentLetter;