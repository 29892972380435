import fetchData, { APIURL } from "../fetch";



const getFinances = () => fetchData({
    url: `${APIURL}/dashboard/finances`
});



const getJobCount = () => fetchData({
    url: `${APIURL}/dashboard/analytics/job-count`
});

const getFutureWork = () => fetchData({
    url: `${APIURL}/dashboard/analytics/future-work`
});


const getPayments = () => fetchData({
    url: `${APIURL}/dashboard/analytics/payments`
});

const getWorkDone = () => fetchData({
    url: `${APIURL}/dashboard/analytics/work-done`
});

const getExpenses = () => fetchData({
    url: `${APIURL}/dashboard/analytics/expenses`
});





export {
    getFinances,
    getJobCount,
    getFutureWork,
    getPayments,
    getWorkDone,
    getExpenses
}