import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../components/btns";
import { errorAlert, successAlert } from "../../components/toastr";
import moment from "moment";
import { getAllStaff } from "../../resources/api/payroll";
import { UCWords, sortFunction } from "../../components/resources";
import { createDeduction, updateDeduction } from "../../resources/api/deductions";


const { Control, Label, Control: { Feedback } } = Form;

/**
 * @param {Object} props
 * @param {"create"|"user"|"salary"} props.action
 * @param {(details: import("../../resources/api/deductions").DeductionObject) => void} props.onUpload
 * @param {{
 *  show: boolean
 *  setShow: React.Dispatch<React.SetStateAction<boolean>>
 * }} props.show
 * @param {{
 *  details: import("../../resources/api/deductions").DeductionObject
 *  setDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/deductions").DeductionObject>>
 * }} props.deductionDetails
 */
const DeductionForm = ({ onUpload, action = "create", deductionDetails, show: toShow }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const [staff, setStaff] = useState([]);

    const { details, setDetails } = deductionDetails;

    const months = useMemo(() => {
        return Array(12).fill(0).map((e, i) => e + i).map(n => ({ value: n + 1, title: moment([2023, n]).format("MMM") }));
    }, [])

    const years = useMemo(() => {
        return Array(3).fill(parseInt(moment().format("YYYY")) - 1).map((e, i) => (e + i));
    }, [])

    useEffect(() => {
        getAllStaff(true)
            .then(({ staff }) => {
                setStaff(
                    staff
                        .filter(s => s.isActive)
                        .map(c => ({
                            ...c,
                            title: c.display_name,
                            description: `${c.email || ""} ${c.telephone || ""} | ${UCWords(c.staff_type)} staff`
                        }))
                        .sort((a, b) => sortFunction(a, b, "title"))
                );


            })
            .catch(e => errorAlert(e))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;
        const data = {
            ...details,
            period_date: moment([details.period_year, (details.period_month - 1), 1]).format("YYYY-MM-DD"),
        };

        if (action === "create") {
            promise = createDeduction(data);
        } else {
            promise = updateDeduction(data, details.id);
        }

        promise
            .then(({ deduction, message }) => {

                const period_date = moment(deduction.period_date);
                deduction.period_month = parseInt(period_date.format("MM"));
                deduction.period_year = period_date.format("YYYY");

                form.reset();
                successAlert(message);
                onUpload(deduction);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered animation={false} size="lg">
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>

                    <Row className="g-2">
                        <Col sm={3} xs={6} className="my-1">
                            <Label className="form-field-title">Period Year</Label>
                            <Form.Select
                                value={details.period_year}
                                onChange={e => setDetails({ ...details, period_year: e.currentTarget.value })}
                                required
                            >
                                <option value=""></option>
                                {years
                                    .map(t => <option value={t} key={t}>{t}</option>)}
                            </Form.Select>
                            <Feedback type="invalid">
                                Please choose a year
                            </Feedback>
                        </Col>
                        <Col sm={3} xs={6} className="my-1">
                            <Label className="form-field-title">Period Month</Label>
                            <Form.Select
                                value={details.period_month}
                                onChange={e => setDetails({ ...details, period_month: e.currentTarget.value })}
                                required
                            >
                                <option value=""></option>
                                {months
                                    .map(t => <option value={t.value} key={t.value}>{t.title}</option>)}
                            </Form.Select>
                            <Feedback type="invalid">
                                Please choose a month
                            </Feedback>
                        </Col>
                    </Row>
                    <Row className="g-2">
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Staff </Label>
                            <Form.Select
                                value={details.staff_id}
                                onChange={e => setDetails({ ...details, staff_id: e.currentTarget.value })}
                                required
                            >
                                <option value=""></option>
                                {staff
                                    .map(t => <option value={t.id} key={t.id}>{t.title}</option>)}
                            </Form.Select>
                            <Feedback type="invalid">
                                Select a staff member for this deduction
                            </Feedback>
                        </Col>
                        <Col sm={6} className="my-1">
                            <Label className="form-field-title">Amount</Label>
                            <Control
                                type="number"
                                step={50}
                                value={details.total_amount}
                                onChange={e => setDetails({ ...details, total_amount: e.currentTarget.value })}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Must be provided.
                            </Control.Feedback>
                        </Col>

                    </Row>

                    <Row className="g-1">
                        <Col sm={12} className="my-1">
                            <Label className="form-field-title">Reason</Label>
                            <Control
                                as="textarea"
                                value={details.reason}
                                onChange={e => setDetails({ ...details, reason: e.currentTarget.value })}
                                rows={3}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Must be provided.
                            </Control.Feedback>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="mt-4 mb-3 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


export default DeductionForm;