import moment from "moment";
import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
// import { UCWords } from "../../components/resources";
import { Select } from "../../components/select";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import {
    deleteUser,
    getUser,
    resetUserPassword,
    suspendUser,
    updatePermissionLevel
} from "../../resources/api/users";
import { EditUser } from "./user-manipulation";
import { ConfirmAction } from "../../components/prompt";


/**
 * 
 * @param {Object} props
 * @param {import("./view-users").UserObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import("./view-users").UserObject>} props.setDetails
 */
const ViewDetails = ({ details, setDetails }) => {

    const navigate = useNavigate();

    const nav_items = [
        { title: 'Users', href: "/app/users" },
        { title: details.display_name }
    ]

    const suspendAccount = () => {
        suspendUser(details.id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: "suspended" }));
            })
            .catch(e => errorAlert(e))
    }

    const deleteAccount = () => {
        deleteUser(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/");
            })
            .catch(e => errorAlert(e))
    }

    const resetAccountPassword = () => {
        resetUserPassword(details.id)
            .then(({ message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, status: "reset" }));
            })
            .catch(e => errorAlert(e))
    }

    const updateUserRoles = (ids) => {
        if (ids.length === 0) return errorAlert("Please select a permission level");

        infoAlert("Updating user details...");

        updatePermissionLevel(ids[0], details.id)
            .then(({ user: { permission_level }, message }) => {
                setDetails(d => ({ ...d, permission_level }))
                successAlert(message);
            })
            .catch(e => errorAlert(e))
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.display_name}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Select
                            as={Btn}
                            title="Re-assign Permission Level"
                            items={[1, 2, 3, 4, 5].map(i => ({ title: `Level ${i}`, id: i }))}
                            maxItems={1}
                            onSelect={updateUserRoles}
                        >
                            <i className="fas fa-user-cog" />
                        </Select>
                        <ConfirmAction
                            as={Btn}
                            title="Reset Password"
                            confirmationText="Are you sure you want to reset the password for this account?"
                            handleAction={resetAccountPassword}
                        >
                            <i className="fas fa-user-lock" />
                        </ConfirmAction>
                    </ButtonToolbar>
                    <ButtonToolbar>

                        <ConfirmAction
                            as={Btn}
                            title="Suspend"
                            confirmationText="Are you sure you want to suspend this account?"
                            handleAction={suspendAccount}
                        >
                            <i className="fas fa-circle-pause" />
                        </ConfirmAction>
                        <ConfirmAction
                            as={Btn}
                            title="Delete"
                            confirmationText="Are you sure you want delete this account?"
                            handleAction={deleteAccount}
                        >
                            <i className="far fa-trash-alt" />
                        </ConfirmAction>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/users/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/users/new" title="New User">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">

                <Row className="g-2">
                    <Col md={6}>
                        <Card className="my-2">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h4 className="my-2">Details</h4>
                                <span className="text-uppercase fw-bold">{details.account_status}</span>
                            </Card.Header>
                            <Card.Body>
                                <dl>
                                    <dt>Email</dt>
                                    <dd>
                                        {details.email}
                                    </dd>
                                    <dt>Telephone</dt>
                                    <dd>
                                        {details.telephone}
                                    </dd>
                                    <dt>Job Title</dt>
                                    <dd>
                                        {details.job_title}
                                    </dd>
                                    {/* <dt>Roles</dt>
                                    <dd>
                                        {details.roles.length > 0 ?
                                            details.roles.map(e => UCWords(e.replace(/-/g, " "))).join(", ") :
                                            <span className="text-muted fs-italic">none assigned</span>
                                        }
                                    </dd> */}
                                    <dt>Permission Level</dt>
                                    <dd>
                                        {details.permission_level}
                                    </dd>
                                    <dt>Last Logged In</dt>
                                    <dd>
                                        {details.last_logged_in ?
                                            moment(details.last_logged_in).format("HH:mm, DD MMMM YYYY")
                                            :
                                            'n/a'}
                                    </dd>
                                    <dt>Last Changed Password</dt>
                                    <dd>
                                        {moment(details.last_changed_password).format("HH:mm, DD MMMM YYYY")}
                                    </dd>
                                    <dt>Created</dt>
                                    <dd>
                                        {moment(details.date_added).format("HH:mm, DD MMMM YYYY")}
                                    </dd>
                                </dl>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )

}


const UserDetails = () => {

    const { userid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError(null);

        getUser(userid)
            .then(({ user }) => {

                setDetails(user);
            })
            .catch(err => setError(err))
            .finally(() => setLoaded(true));

    }, [userid])

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/edit" element={<EditUser details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewDetails details={details} setDetails={setDetails} />} />
        </Routes>
    )

}


export default UserDetails;