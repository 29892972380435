import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import currency from '../../components/currency';
import style from './expenses.scss';
import { useEffect, useState } from 'react';
import { deleteBill, getBill } from '../../resources/api/bills';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { EditBill } from './bill-manipulation';
import moment from "moment";
import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { ConfirmAction } from '../../components/prompt';
// import { fileIcons } from '../../assets/constants';


/**
 * handle for /app/bills/:billid
 * @param {Object} props
 * @param {import('../../resources/api/bills').BillObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/bills').BillObject>>} props.setDetails
 * 
 */
const ViewBill = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Bills', href: '/app/bills' },
        { title: details.vendor_reference }
    ];

    const navigate = useNavigate();

    const handleDelete = () => {
        infoAlert("Deleting bill, please wait...");

        deleteBill(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/bills");
            })
            .catch(e => errorAlert(e));
    }


    // const handleDocUpload = (document) => setDetails(d => ({ ...d, documents: d.documents.concat(document) }))

    // const handleDocDelete = id => setDetails(d => ({ ...d, documents: d.documents.filter(dd => dd.id !== id) }));

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.vendor_reference} ({details.reference})
                </Title>
                <ActionBar>
                    {(["unpaid", "partially-paid"].indexOf(details.status) !== -1) &&
                        <ButtonToolbar>
                            <Btn href={`/app/bill-payments/new?vendor=${details.vendor_id}&bill${details.id}`} title="Add Payment">
                                <i className="fas fa-coins" />
                            </Btn>

                            <ConfirmAction
                                as={Btn}
                                title="Delete Bill"
                                confirmationText="Are you sure you want to delete this bill?"
                                handleAction={handleDelete}
                            >
                                <i className="fas fa-trash-alt" />
                            </ConfirmAction>
                        </ButtonToolbar>
                    }
                    <ButtonToolbar>

                        {(details.status !== "cancelled" && details.status !== "paid") &&
                            <Btn href={`/app/bills/${details.id}/edit`} title="Edit Bill">
                                <i className="fas fa-pencil-alt" />
                            </Btn>
                        }

                        <Btn href="/app/bills/new" title="New Bill">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-800 p-1 bills">

                <div className="text-end">
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    VENDOR REF: <span style={style1}>{details.vendor_reference}</span> <br />
                    BILL DATE: <span style={style1}>{moment(details.bill_date).format("DD MMMM YYYY")}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span> <br />
                </div>

                {/* <div className="mt-5 mb-2">
                    <h5>Paid By</h5>
                    <span className="lead">Maurice Ntege</span> <br />
                    morsnegs@hotmail.com
                </div> */}

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <Table size="sm" borderless style={{ maxWidth: '500px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "50%" }} />
                        <col span="1" style={{ width: "50%" }} />
                    </colgroup>

                    <tbody>
                        {details.tax_amount > 0 &&
                            <>
                                <tr>
                                    <td>Pretax Amount</td>
                                    <td>UGX {currency(details.pretax_amount).format()}</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td>UGX {currency(details.tax_amount).format()}</td>
                                </tr>
                            </>

                        }
                        <tr>
                            <td>Balance</td>
                            <td>UGX {currency(details.balance).format()}</td>
                        </tr>
                        <tr>
                            <td>Prices Tax Inclusive?</td>
                            <td>{details.isTaxInclusive ? "Yes" : "No"}</td>
                        </tr>
                        {!!details.vendor &&
                            <tr>
                                <td>Vendor</td>
                                <td><Link to={`/app/vendors/${details.vendor_id}`}>{details.vendor.title}</Link></td>
                            </tr>
                        }
                        {!!details.order &&
                            <tr>
                                <td>Purchase Order</td>
                                <td><Link to={`/app/purchase-orders/${details.purchase_order_id}`}>{details.order.order_no}</Link></td>
                            </tr>
                        }
                        {!!details.client_id &&
                            <tr>
                                <td>Client</td>
                                <td><Link to={`/app/clients/${details.client_id}`} > {details.client.title}</Link> </td>
                            </tr>
                        }
                        {!!details.job_id &&
                            <tr>
                                <td>Job</td>
                                <td><Link to={`/app/jobs/${details.job_id}`} > {details.job.title} - {details.job.job_no}</Link> </td>
                            </tr>
                        }

                    </tbody>
                </Table>

                {(details.items.length > 0) && <BillItems items={details.items} />}
                {(details.payments.length > 0) && <BillPayments items={details.payments} />}


                {/* <ViewDocuments documents={details.documents || []} onDocUpload={handleDocUpload} onDocDelete={handleDocDelete} bill_id={details.id} /> */}


            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/bills').BilltItemObject[]} props.items 
 * @returns 
 */
const BillItems = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Items</h5>

        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "35%", textAlign: 'center' }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "22%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Qty</th>
                    <th className='text-end'>Rate</th>
                    <th>Tax</th>
                    <th className='text-end'>Amount</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td>
                            {UCWords(i.category.replace(/-/g, " "))}
                            <p className='text-muted small my-0' style={{ lineHeight: 1 }} >{i.notes}</p>
                        </td>
                        <td>{i.quantity}</td>
                        <td className='text-end'>{cur(i.unit_price, 0).format()}</td>
                        <td>{i.tax_percent}%</td>
                        <td className='text-end'>{cur(i.quantity * i.unit_price, 0).format()}</td>
                    </tr>
                ))}
            </tbody>

        </Table>

    </div>
)

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/bill-payments').PaymentItemObject[]} props.items 
 * @returns 
 */
const BillPayments = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Payments</h5>

        <Table responsive size="sm" style={{ maxWidth: '450px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "35%", textAlign: 'center' }} />
                <col span="1" style={{ width: "20%" }} />
                <col span="1" style={{ width: "20%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Receipt No</th>
                    <th>Date</th>
                    <th className='text-end'>Amount</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td>
                            <Link>{i.receipt_no}</Link>
                        </td>
                        <td>{moment(i.payment_date).format("DD MMM YYYY")}</td>
                        <td className='text-end'>{cur(i.amount, 0).format()}</td>
                    </tr>
                ))}
            </tbody>

        </Table>

    </div>
)



const BillDetails = () => {

    const { billid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getBill(billid, ['vendor', 'items', 'client', 'job', 'order', "payments"])
            .then(({ bill }) => {
                setDetails(bill);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [billid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading bill details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditBill details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewBill details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default BillDetails;