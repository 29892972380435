import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Alert, Button, Table } from 'react-bootstrap';
import { Link, Route, Routes, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// import style from './expenses.scss';
import { useContext, useEffect, useState } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
import { UCWords, sortFunction } from '../../components/resources';
import cur from '../../components/currency';
import { advanceInvoice, deleteInvoice, getInvoice, makeInvoiceFinal, restoreInvoiceVersion, reverseInvoice, reviseInvoice } from '../../resources/api/invoices';
import { DisplayInvoiceItems, DisplayInvoiceNotes, DisplayTotals } from '../../components/finances';
import { EditInvoice } from './invoice-manipulation';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { APIURL } from '../../resources/fetch';
import { ConfirmAction } from '../../components/prompt';
import { AppUserContext } from '../../App';
import { UploadEffris } from './details/uploadeffris';
import SendInvoice from './send-email';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/invoices').InvoiceObject} props.details
 */
const ViewInvoice = ({ details, changeStatus, onVersionRestore }) => {

    const nav_items = [
        { title: 'Invoices', href: '/app/invoices' },
        { title: details.reference }
    ];

    const [viewingOld, setViewingOld] = useState(false);
    const [version_details, setVersionDetails] = useState({});

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    const { profile } = useContext(AppUserContext);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        let version = searchParams.get('v') || null;

        if (!version) {
            setVersionDetails({});
            setViewingOld(false);
            return;
        }
        setViewingOld(true);
        setVersionDetails(details.versions.find(v => (v.version === parseInt(version))));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, JSON.stringify(details.versions)])

    const handleStatusChange = status => {
        infoAlert("Changing Invoice Status...");
        advanceInvoice(details.id, status)
            .then(({ invoice, message }) => {
                changeStatus(invoice.status);
                successAlert(message);

                if (invoice.version !== details.version) {
                    infoAlert("Loading updated invoice details");
                    getInvoice(details.id, ['versions'])
                        .then(({ invoice }) => {
                            onVersionRestore(invoice);
                            successAlert("Invoice details successfully updated.");
                        })
                        .catch(e => "Could not update invoice details. Please refresh page. " + e)
                }
            })
            .catch(errorAlert)
    }

    const handleInvoiceRevise = () => {
        infoAlert("Creating a new revision...");

        reviseInvoice(details.id)
            .then(({ message }) => {
                successAlert(message);
                getInvoice(details.id, ['versions'])
                    .then(({ invoice }) => {
                        onVersionRestore(invoice);
                        successAlert("Invoice details successfully updated.");
                        navigate(`/app/invoices/${details.id}/edit`);
                    })
                    .catch(e => "Could not update invoice details. Please refresh page. " + e)
            })
            .catch(errorAlert)

    }

    const handleRestore = version => {
        infoAlert("Restoring the version. Please wait...");
        restoreInvoiceVersion(details.id, version)
            .then(({ message }) => {
                successAlert(message + " Refresh the page to view invoice");
                navigate(`/app/invoices/${details.id}`);
                return getInvoice(details.id, ['items', 'versions'])
            })
            .then(({ invoice }) => {
                onVersionRestore(invoice);
            })
            .catch(errorAlert)
    }

    const changeInvoiceType = () => {
        infoAlert("Making the document an invoice...");

        makeInvoiceFinal(details.id)
            .then(({ invoice }) => {
                onVersionRestore(invoice);
            })
            .catch(errorAlert)
    }


    const reverseInvoiceType = () => {
        infoAlert("Making the document a proforma...");

        reverseInvoice(details.id)
            .then(({ invoice }) => {
                onVersionRestore(invoice);
            })
            .catch(errorAlert)
    }

    const handleDelete = () => {
        infoAlert("Deleting Invoice, please wait...");

        deleteInvoice(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate("/app/invoices");
            })
            .catch(e => errorAlert(e));
    }

    const cloneid = viewingOld ? `${details.id}.${version_details.version}` : details.id;

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.invoice_no}
                </Title>
                <ActionBar>

                    <ButtonToolbar>
                        <UploadEffris
                            invoice_id={details.id}
                            effris_details={{
                                effris_date: details.effris_date || "",
                                effris_vc: details.effris_vc || "",
                                effris_fdn: details.effris_fdn || ""
                            }}
                            onUpload={invoice => onVersionRestore(invoice)}
                        />
                    </ButtonToolbar>

                    <ButtonToolbar>

                        <Btn href={`${APIURL}/invoices/${details.id}/pdf`} title="Print Invoice" isExternalLink>
                            <i className="far fa-file-pdf" />
                        </Btn>

                    </ButtonToolbar>
                    <ButtonToolbar>

                        <Btn href={`/app/invoices/new?clone=${cloneid}`} title="Clone Invoice">
                            <i className="far fa-clone" />
                        </Btn>
                        {(details.invoice_type !== "final") &&
                            <ConfirmAction
                                as={Btn}
                                title="Change Invoice Type"
                                confirmationText="Are you sure you change invoice type from proforma to invoice?"
                                handleAction={changeInvoiceType}
                            >
                                <i className="fas fa-hourglass-end" />
                            </ConfirmAction>
                        }

                        {(details.invoice_type !== "proforma") &&
                            <ConfirmAction
                                as={Btn}
                                title="Change Invoice Type"
                                confirmationText="Are you sure you change invoice type to proforma?"
                                handleAction={reverseInvoiceType}
                            >
                                <i className="fas fa-hourglass-end" />
                            </ConfirmAction>
                        }

                        {(['unpaid', 'partially-paid', 'overdue'].indexOf(details.status) !== -1) &&
                            <Btn
                                title="Add Payment"
                                href={`/app/payments/new?client=${details.client_id}&invoice=${details.id}&return=invoices|${details.id}`}
                            >
                                <i className="fas fa-coins" />
                            </Btn>
                        }

                    </ButtonToolbar>

                    <ButtonToolbar>
                        {(
                            (profile.permission_level === 1)
                            &&
                            (['unpaid', 'partially-paid', 'paid', 'overdue'].indexOf(details.status) !== -1)
                        ) &&
                            <>
                                <ConfirmAction
                                    as={Btn}
                                    title="Mark as Draft"
                                    confirmationText="Are you sure you want to mark this invoice as draft? This will create a new revision for this invoice."
                                    handleAction={() => handleStatusChange('draft')}
                                >
                                    <i className="fas fa-pen-ruler" />
                                </ConfirmAction>
                                <ConfirmAction
                                    as={Btn}
                                    title="Create New Revision"
                                    confirmationText="Are you sure you want to create a new revision of this invoice?"
                                    handleAction={(handleInvoiceRevise)}
                                >
                                    <i className="fas fa-clock-rotate-left" />
                                </ConfirmAction>
                            </>
                        }
                        {(
                            (profile.permission_level === 1)
                            &&
                            ((details.status === "cancelled"))
                        ) &&
                            <>
                                <ConfirmAction
                                    as={Btn}
                                    title="Mark as Draft"
                                    confirmationText="Are you sure you want to mark this invoice as draft? This will create a new revision for this invoice."
                                    handleAction={() => handleStatusChange('draft')}
                                >
                                    <i className="fas fa-pen-ruler" />
                                </ConfirmAction>
                            </>
                        }

                        {(details.status === "sent") &&
                            <>
                                <ConfirmAction
                                    as={Btn}
                                    title="Mark as Draft"
                                    confirmationText="Are you sure you want to mark this invoice as draft? This will create a new revision for this invoice."
                                    handleAction={() => handleStatusChange('draft')}
                                >
                                    <i className="fas fa-pen-ruler" />
                                </ConfirmAction>
                                <ConfirmAction
                                    as={Btn}
                                    title="Mark as Accepted"
                                    confirmationText="Are you sure you want to mark this as accepted?"
                                    handleAction={() => handleStatusChange('unpaid')}
                                >
                                    <i className="fas fa-check-circle" />
                                </ConfirmAction>
                                <ConfirmAction
                                    as={Btn}
                                    title="Create New Revision"
                                    confirmationText="Are you sure you want to create a new revision of this invoice?"
                                    handleAction={handleInvoiceRevise}
                                >
                                    <i className="fas fa-clock-rotate-left" />
                                </ConfirmAction>
                            </>
                        }


                        <Btn href={`/app/invoices/${details.id}/send`} title="Send Invoice">
                            <i className="fas fa-envelope" />
                        </Btn>

                        {(details.status === "draft") &&
                            <>
                                <ConfirmAction
                                    as={Btn}
                                    title="Mark as Sent"
                                    confirmationText="Are you sure you want to mark this invoice as sent? Ensure you have sent it to the client."
                                    handleAction={() => handleStatusChange('sent')}
                                >
                                    <i className="far fa-calendar-check" />
                                </ConfirmAction>
                                <ConfirmAction
                                    as={Btn}
                                    title="Create New Revision"
                                    confirmationText="Are you sure you want to create a new revision of this invoice?"
                                    handleAction={handleInvoiceRevise}
                                >
                                    <i className="fas fa-clock-rotate-left" />
                                </ConfirmAction>
                            </>
                        }
                        {/* <Btn title="Send Invoice">
                            <i className="far fa-paper-plane" />
                        </Btn> */}
                        {/* {(['draft', 'sent', 'cancelled', 'written-off'].indexOf(details.status) === -1) &&
                            <Btn title="Write Off Invoice" onClick={() => handleStatusChange('written-off')}>
                                <i className="fas fa-ban" />
                            </Btn>
                        } */}
                        {(['draft', 'sent'].indexOf(details.status) !== -1) &&

                            <ConfirmAction
                                as={Btn}
                                title="Cancel Invoice"
                                confirmationText="Are you sure you want to cancel this invoice?"
                                handleAction={() => handleStatusChange('cancelled')}
                            >
                                <i className="fas fa-times-circle" />
                            </ConfirmAction>
                        }

                    </ButtonToolbar>

                    <ButtonToolbar>
                        {(
                            (profile.permission_level === 1)
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Delete Invoice"
                                confirmationText="Are you sure you want to delete this invoice?"
                                handleAction={handleDelete}
                            >
                                <i className="fas fa-trash-alt" />
                            </ConfirmAction>
                        }
                        {(details.status === "draft") &&
                            <Btn title="Edit Invoice" href={`/app/invoices/${details.id}/edit`}>
                                <i className="fas fa-pencil-alt" />
                            </Btn>
                        }
                        <Btn href="/app/invoices/new" title="New Invoice">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-800 p-1 expenses">


                {viewingOld &&
                    <Alert variant='warning'>
                        You are currently viewing version {version_details.version} of this invoice. <Link to={`/app/invoices/${details.id}`}>View latest</Link> or <a href='#.' onClick={() => handleRestore(version_details.version)}>restore to this version</a>.
                    </Alert>
                }


                <div className="text-end">
                    TYPE: <span style={style1}>{UCWords(details.invoice_type)}</span> <br />
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    INVOICE DATE: <span style={style1}>{moment(details.invoice_date).format("DD MMMM YYYY")}</span> <br />
                    PAYMENT DUE DATE: <span style={style1}>{moment(details.due_date).format("DD MMMM YYYY")}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span><br />
                    VERSION: <span style={style1}>{details.version}</span><br />
                    CURRENCY: <span style={style1}>{details.currency}</span><br />
                    {details.isClone && <>
                        CLONED FROM:<span style={style1}><Link to={`/app/invoices/${details.clone_id}`}>View Invoice</Link></span><br />
                    </>}
                    {(details.currency !== "UGX") && <>
                        EXCHANGE RATE: <span style={style1}>{cur(details.exchange_rate, 2).format()}</span><br />
                    </>}
                    {!!details.job_id && <>
                        JOB: <Link to={`/app/jobs/${details.job_id}`} style={style1}>{details.job.title}</Link><br />
                    </>}
                    EFFRIS DATE: <span style={style1}>{!!details.effris_date ? moment(details.effris_date).format("DD MMMM YYYY") : "n/a"}</span> <br />
                    {!!details.effris_fdn && <>
                        EFFRIS FDN: <span style={style1}>{details.effris_fdn}</span><br />
                    </>}
                    {!!details.effris_vc && <>
                        EFFRIS VC:<span style={style1}>{details.effris_vc}</span><br />
                    </>}
                </div>

                <div className="mt-5 mb-2">
                    <h5>Client Details</h5>
                    <Link className="lead" to={`/app/clients/${details.client_id}`}>{details.client.title}</Link> <br />
                    {!!details.client.email && <>{details.client.email}<br /></>}
                    {!!details.client.telephone && <>{details.client.telephone}<br /></>}
                </div>

                <div className="mt-5 mb-2">
                    <h5>Deliverables</h5>
                    <span className="lead">{details.subject}</span>
                </div>

                {viewingOld &&
                    <div className="my-3">
                        <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                            {details.currency} <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(version_details.total_amount).format()}</span>
                        </span>
                    </div>
                }



                {/* <Table size="sm" borderless style={{ maxWidth: '500px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "50%" }} />
                        <col span="1" style={{ width: "50%" }} />
                    </colgroup>

                    <tbody>

                        <tr>
                            <td>Prices Tax Inclusive?</td>
                            <td>{details.isTaxInclusive ? "Yes" : "No"}</td>
                        </tr>
                    </tbody>
                </Table> */}


                <h5 className='mt-3 mt-sm-5'>Items</h5>
                <DisplayInvoiceItems items={viewingOld ? version_details.items : details.items} />

                {!viewingOld &&
                    <DisplayTotals
                        management_fee={details.management_fee_amount}
                        tax={details.tax_amount}
                        total={details.total_amount || 0}
                        discount={details.discount_amount || 0}
                        due={details.due_amount}
                        balance={details.balance}
                        currency={details.currency}
                    />
                }


                <h4 className='mt-4'>Service Terms</h4>
                <DisplayInvoiceNotes notes={details.tcs} />

                <h4 className='mt-4'>Notes</h4>
                <DisplayInvoiceNotes notes={details.notes} />





                <h4 className='mt-4'>Payments</h4>
                <Table responsive hover size="sm" style={{ maxWidth: "450px" }}>
                    <thead>
                        <tr>
                            <th>Receipt No</th>
                            <th>Date</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.payments.map(v => (
                            <tr key={v.id}>
                                <td><Link to={`/app/payments/${v.payment_id}`}>{v.receipt_no}</Link></td>
                                <td>{moment(v.date_added).format("DD MMM YYYY")}</td>
                                <td>{cur(v.amount, 0).format()}</td>
                            </tr>
                        ))}

                        {(details.payments.length === 0) && <tr>
                            <td colSpan={4} className='text-center'>
                                No Payments towards Invoice
                            </td>
                        </tr>
                        }
                    </tbody>
                </Table>


                <h4 className='mt-4'>Versions</h4>
                <Table responsive hover size="sm" style={{ maxWidth: "450px" }}>
                    <thead>
                        <tr>
                            <th>Version</th>
                            <th>Date Created</th>
                            <th>Amount</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {details.versions.map(v => (
                            <tr key={v.id}>
                                <td>{v.version}</td>
                                <td>{moment(v.version_date).format("DD MMM YYYY")}</td>
                                <td>{cur(v.total_amount, 0).format()}</td>
                                <td>
                                    {/* <Button variant='link' size="sm" className='text-danger py-0'>
                                        <i className='fas fa-file-pdf' />
                                    </Button> */}
                                    <Link
                                        className="btn btn-link py-0 btn-sm mx-1"
                                        variant='link'
                                        size="sm"
                                        to={`/app/invoices/${details.id}?v=${v.version}`}
                                    >
                                        <i className='fas fa-eye me-1' />View
                                    </Link>
                                    <Button
                                        variant='link'
                                        size="sm"
                                        className='text-secondary py-0 mx-1'
                                        onClick={() => handleRestore(v.version)}
                                    >
                                        <i className='fas fa-undo me-1' />Restore
                                    </Button>
                                </td>
                            </tr>
                        ))}

                        {(details.versions.length === 0) && <tr>
                            <td colSpan={4} className='text-center'>
                                No Previous Versions
                            </td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}


const InvoiceDetails = () => {

    const { invoiceid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        // setTimeout(() => {
        getInvoice(invoiceid, ['versions', 'items', 'payments', 'client', 'payments', 'job'])
            .then(({ invoice }) => {
                let hasIndex = true;

                for (let index = 0; index < invoice.items.length; index++) {

                    if (!invoice.items[index].position) {
                        hasIndex = false;
                        break;
                    }

                }

                if (!hasIndex) {
                    let start = 1;
                    invoice.items = invoice.items.map(i => ({ ...i, position: start++, hasChanged: true }));
                }

                invoice.items.sort((a, b) => sortFunction(a, b, "position", "asc"));

                setDetails(invoice);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))
        // }, 1000);

    }, [invoiceid])

    const changeStatus = status => setDetails(d => ({ ...d, status }));

    const handleVersionRestore = invoice => setDetails(d => ({ ...d, ...invoice }));


    if (!isLoaded) {
        return <PageLoading>Loading invoice details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/send" element={<SendInvoice details={details} />} />
            <Route path="/edit" element={<EditInvoice details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewInvoice onVersionRestore={handleVersionRestore} changeStatus={changeStatus} details={details} />} />
        </Routes>
    )

}

export default InvoiceDetails;