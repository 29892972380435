
import React, { useEffect, useState } from "react";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { deletePackingListItem, getPackingList } from "../../resources/api/jobs";
import { sortFunction } from "../../components/resources";
import { Button, Table } from "react-bootstrap";
import { getItems } from "../../resources/api/pricelist";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";
import ItemForm from "./packing-list/item-form";
import { ConfirmAction, PromptAction } from "../../components/prompt";
import { updateInvoiceItem } from "../../resources/api/invoices";
import { APIURL } from "../../resources/fetch";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/jobs").JobObject} props.details
 */
const PackingList = ({ details }) => {

    const nav_items = [
        { title: 'Jobs', href: "/app/jobs" },
        { title: details.job_no, href: `/app/jobs/${details.id}` },
        { title: "Packing List" }
    ]

    const form_defaults = {
        pricelist_item_id: "",
        days: 1,
        quantity: 1,
        comments: "",
        title: "",
        job_id: ""
    }

    const [items, setItems] = useState([]);
    const [extra_items, setExtraItems] = useState([]);

    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);


    const [item_details, setItemDetails] = useState({});
    const [edit_action, setEditAction] = useState("create");
    const [isItemDialogueOpen, setItemDialogueOpen] = useState(false);
    const [pricelistitems, setPricelistItems] = useState([]);
    const [isPricelistLoaded, setPricelistLoaded] = useState(false);


    useEffect(() => {

        setError(null);
        setLoaded(false);

        getPackingList(details.id)
            .then(({ items, extra_items }) => {
                items.sort((a, b) => sortFunction(a, b, "position", "asc"));

                setItems(items);
                setExtraItems(extra_items);
            })
            .catch(setError)
            .finally(() => setLoaded(true))

    }, [details.id])


    useEffect(() => {

        if (isPricelistLoaded) return;

        if (!isItemDialogueOpen) return;


        infoAlert("Fetching all pricelist items. Please hold...");

        getItems()
            .then(({ pricelist }) => {
                setPricelistItems(
                    pricelist
                        .map(e => ({
                            ...e,
                            title: `${e.name}`
                        })));

                setPricelistLoaded(true);
                successAlert("Loaded all price list items.");
            })
            .catch(e => errorAlert(e));
    }, [isItemDialogueOpen, isPricelistLoaded])

    const onClickEdit = id => {
        let details = extra_items.find(i => i.id === id);
        setItemDetails(details);
        setEditAction("edit");
        setItemDialogueOpen(true);
    }

    const onClickNew = () => {
        setItemDetails(form_defaults);
        setEditAction("create");
        setItemDialogueOpen(true);
    }

    const onUploadItem = details => {
        if (edit_action === "create") {
            setExtraItems(_items => _items.concat(details));
        } else {
            let _items = [...extra_items];

            _items.splice(
                _items.findIndex(i => i.id === item_details.id),
                1,
                details
            );
            setExtraItems(_items);
        }
    }

    const handleDelete = (id) => {
        infoAlert("Please hold as we delete this item...");

        deletePackingListItem(id)
            .then(({ message }) => {
                successAlert(message);

                let _items = [...extra_items];

                _items.splice(
                    _items.findIndex(i => i.id === id),
                    1
                );
                setExtraItems(_items);
            })
            .catch(errorAlert)
    }

    const updateComments = (id, text) => {
        infoAlert("Updating the item...");

        updateInvoiceItem({ comments: text }, id)
            .then(({ item, message }) => {
                let _items = [...items];

                _items.splice(
                    _items.findIndex(i => i.id === item.id),
                    1,
                    item
                );

                setItems(_items);
                successAlert(message);

            })
            .catch(errorAlert)
    }


    let count = 1;

    if (!isLoaded) {
        return <PageLoading>Loading packing details...</PageLoading>
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.title} - Packing List</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn href={`${APIURL}/jobs/${details.id}/delivery-note/pdf`} title="Print Delivery Note" isExternalLink>
                            <i className='fas fa-truck' />
                        </Btn>
                        <Btn href={`${APIURL}/jobs/${details.id}/packing-list/pdf`} title="Print Packing List" isExternalLink>
                            <i className='fas fa-file-pdf' />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn onClick={onClickNew} title="Add PackingList Items">
                            <i className='fas fa-cart-plus' />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href="/app/jobs/new" title="New Job">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
                    {/* <colgroup>
                        <col span="1" style={{ width: "3%" }} />
                        <col span="1" style={{ width: "30%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "18%" }} />
                        <col span="1" style={{ width: "18%" }} />
                    </colgroup> */}
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Days</th>
                            <th>Qty</th>
                            <th colSpan={2}>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.map((e, i) => {
                                if (e.isHeading) {
                                    return (

                                        <tr key={i}>
                                            <td colSpan={6} className='text-start'>
                                                <h6 className='mb-0 mt-1'>
                                                    {e.title}
                                                </h6>
                                            </td>
                                        </tr>
                                    )
                                } else {
                                    return (
                                        <tr key={i}>
                                            <td>{count++}</td>
                                            <td>
                                                <span className="d-block font-weight-normal">
                                                    {e.title}
                                                </span>
                                                {e.description && <div className="description">{e.description}</div>}
                                            </td>
                                            <td>{e.days}</td>
                                            <td>{e.quantity}</td>
                                            <td className="small">{e.comments}</td>
                                            <td>
                                                <PromptAction
                                                    as={Button}
                                                    variant="link"
                                                    size="sm"
                                                    className='text-danger p-0'
                                                    promptMessage="Type your comments here below"
                                                    onAccept={text => updateComments(e.id, text)}
                                                >
                                                    <i className="fas fa-pencil-alt" />
                                                </PromptAction>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </Table>

                <h4 className="mt-3 mt-sm-5">Extra Items</h4>

                <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
                    {/* <colgroup>
                        <col span="1" style={{ width: "3%" }} />
                        <col span="1" style={{ width: "45%", textAlign: 'center' }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "8%" }} />
                        <col span="1" style={{ width: "18%" }} />
                        <col span="1" style={{ width: "18%" }} />
                    </colgroup> */}
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Days</th>
                            <th>Qty</th>
                            <th>Comments</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            extra_items.map((e, i) => (
                                <tr key={i}>
                                    <td>{count++}</td>
                                    <td>
                                        <span className="d-block font-weight-normal">
                                            {e.title}
                                        </span>
                                        {e.description && <div className="description">{e.description}</div>}
                                    </td>
                                    <td>{e.days}</td>
                                    <td>{e.quantity}</td>
                                    <td className="small">{e.comments}</td>
                                    <td>

                                        <Button variant="link" size="sm" className='text-danger p-0 me-2' onClick={() => onClickEdit(e.id)}>
                                            <i className="fas fa-pencil-alt" />
                                        </Button>
                                        <ConfirmAction
                                            as={Button}
                                            variant="link"
                                            size="sm"
                                            className='text-danger p-0'
                                            confirmationText="Are you sure you want to delete this item?"
                                            handleAction={() => handleDelete(e.id)}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </ConfirmAction>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>


                <ItemForm
                    itemDetails={{ details: item_details, setDetails: setItemDetails }}
                    action={edit_action}
                    show={{ show: isItemDialogueOpen, setShow: setItemDialogueOpen }}
                    onUpload={onUploadItem}
                    priceListItems={pricelistitems}
                    jobId={details.id}
                />


            </div>



        </>
    )

}





export default PackingList;