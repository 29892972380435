
import React from 'react';
import { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppUserContext } from '../../App';
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from '../../components/loading';
import ExpenseForm from './expense-form';
import { useEffect } from 'react';
import { useState } from 'react';

const expense = {
    category: '',
    expense_date: '',
    title: '',
    isItemized: false,
    description: '',
    account_id: '',
    feenote_id: '',
    isBillable: false,
    isTaxInclusive: true,
    amount: '',
    receipt_file_id: '',
    tax_percent: 0,
    payment_method: "",
    client_id: "",
    markup: "",
    user_reference: "",
    vendor: "",
    items: [],
    isBill: false,
    due_date: "",
    method: "",
    account: ""
}

const NewExpense = () => {

    const nav_items = [
        { title: 'Expenses', href: '/app/expenses' },
        { title: 'New Expense' }
    ];

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [details, setDetails] = useState(expense);
    const [loaded, setLoaded] = useState(false);

    const { profile } = useContext(AppUserContext);

    useEffect(() => {
        let client_id = searchParams.get('client') || "",
            job_id = searchParams.get('job') || "",
            expense_date = searchParams.get('date') || "";
        const _details = { ...expense, expense_date, client_id, job_id };

        setDetails(_details);
        setLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (details) => {
        let job_id = searchParams.get('job') || "";

        if (job_id) {
            navigate(`/app/jobs/${job_id}`);
        } else {
            navigate(`/app/expenses/${details.id}`);
        }
    }

    if (!loaded) return <PageLoading />;

    if (profile.permission_level !== 1) {
        return <ErrorLoading>You do not have the permission to create expenses. You might have permission to requisitions.</ErrorLoading>
    }

    return (
        <>
            <Nav items={nav_items} />

            <ExpenseForm
                details={details}
                action="create"
                onSubmit={handleSubmit}
            />
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/expenses').ExpenseObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/expenses').ExpenseObject>>} props.setDetails 
 */
const EditExpense = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Expenses', href: '/app/expenses' },
        { title: details.reference, href: `/app/expenses/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/expenses/${details.id}`);
    }

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level !== 1) {
        return <ErrorLoading>You do not have the permission to edit expenses.</ErrorLoading>
    }


    return (

        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <ExpenseForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { EditExpense };


export default NewExpense;