import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from '../../components/breadcrumb';
// import PageHeader, { Title } from '../../components/pageheader';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * 
 */
const ViewStats = () => {

    const nav_items = [
        { title: 'Payroll', href: '/app/payroll' },
        { title: "Action" }
    ];


    return (
        <>
            <Nav items={nav_items} />

            {/* <PageHeader maxWidth="900">
                <Title>
                    Payroll
                </Title>

            </PageHeader> */}

            <div className='max-900'>
                {/* <h5>Choose an action below to continue</h5> */}

                <Row cols={"auto"}>
                    <Col className='py-5 text-center'>
                        <Link className='btn btn-lg btn-outline-secondary py-4' style={{ minWidth: "200px" }} to="/app/payroll/staff">
                            <i className='fas fa-users' /><br />
                            Staff
                        </Link>
                    </Col>

                    <Col className='py-5 text-center'>
                        <Link className='btn btn-lg btn-outline-secondary py-4' style={{ minWidth: "200px" }} to="/app/payroll/salaries">
                            <i className='fas fa-vault' /><br />
                            Salaries
                        </Link>
                    </Col>
                    <Col className='py-5 text-center'>
                        <Link className='btn btn-lg btn-outline-secondary py-4' style={{ minWidth: "200px" }} to="/app/payroll/allowances">
                            <i className='fas fa-coins' /><br />
                            Allowances
                        </Link>
                    </Col>
                </Row>

                <Row cols={"auto"}>


                    <Col className='py-5 text-center'>
                        <Link className='btn btn-lg btn-outline-secondary py-4' style={{ minWidth: "200px" }} to="/app/payroll/advances">
                            <i className='fas fa-hand-holding-medical' /><br />
                            Advances
                        </Link>
                    </Col>

                    <Col className='py-5 text-center'>
                        <Link className='btn btn-lg btn-outline-secondary py-4' style={{ minWidth: "200px" }} to="/app/payroll/deductions">
                            <i className='fas fa-filter-circle-dollar' /><br />
                            Deductions
                        </Link>
                    </Col>
                </Row>
            </div>
        </>
    )
}




export default ViewStats;