import { APIURL, getData, postData, deleteData } from "../fetch";



/**
 * 
 * @param {import("./inventory").AdjustmentObject} data 
 * @returns {Promise<{code: number, message: string, adjustment: import("./inventory").AdjustmentObject}>}
 */
const createAdjustment = (data) => {
    return postData({
        url: `${APIURL}/inventory-adjustments`,
        data
    })
}

/**
 * @param {import("./inventory").AdjustmentObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, adjustment: import("./inventory").AdjustmentObject}>}
 */
const updateAdjustment = (data, id) => {
    return postData({
        url: `${APIURL}/inventory-adjustments/${id}`,
        data
    })
}

/**
 * @returns {Promise<{code: number, message: string, adjustments: import("./inventory").AdjustmentObject[]}>}
 */
const getAdjustments = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/inventory-adjustments`, data: {} }

    if (getAll) obj.data = { expanded: 1 };
    if (conditions) obj.data = { ...obj.data, ...conditions }

    return getData(obj)
}

/**
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, adjustment: import("./inventory").AdjustmentObject}>}
 */
const getAdjustment = (id, includes = null) => {

    const obj = { url: `${APIURL}/inventory-adjustments/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}



const deleteAdjustment = id => deleteData({ url: `${APIURL}/inventory-adjustments/${id}` });


export {
    createAdjustment,
    getAdjustment,
    getAdjustments,
    updateAdjustment,
    deleteAdjustment
}