import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { error as errorAlert, infoAlert, successAlert, warningAlert } from '../../components/toastr';
import { SubmitButton, CancelButton } from '../../components/btns';
import { DateSelector } from '../../components/datetimepicker';
import moment from 'moment';
import SelectInput, { Select } from '../../components/select';
import cur, { UGX } from '../../components/currency';
import { createPayment, updatePayment } from '../../resources/api/payments';
import { useNavigate } from 'react-router-dom';
import { paymentMethods } from '../../assets/constants';
import { UCWords } from '../../components/resources';
import { getClients } from '../../resources/api/clients';
import { getInvoices } from '../../resources/api/invoices';
import { getAccounts } from '../../resources/api/accounts';
import { AppUserContext } from '../../App';

const { Control, Control: { Feedback }, Label } = Form;

/**
 * handle page for creating payment
 * @param {Object} props
 * @param {"edit"|"create"} props.action
 * @param {import('../../resources/api/payments').PaymentObject} props.details
 * @param {(details: import('../../resources/api/payments').PaymentObject) => void} props.onSubmit
 */
const PaymentForm = ({ details: paymentObject, action, onSubmit }) => {

    const { profile } = useContext(AppUserContext);
    const [details, setDetails] = useState(paymentObject);
    const [prods, setProds] = useState(paymentObject.items);
    const [clients, setClients] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [deleted_prods, setDeletedProds] = useState([]);
    const [selected_invoices, setSelectedInvoices] = useState([]);
    const [display_invoices, setDisplayInvoices] = useState([]);
    const [accounts, setAccounts] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);


    useEffect(() => {

        setDisplayInvoices(
            invoices.filter(i => (selected_invoices.indexOf(i.id) === -1))
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selected_invoices), JSON.stringify(invoices)])

    const navigate = useNavigate();

    const changeProdAmount = (amount, idx) => {
        const _prods = [...prods];

        let _prod = _prods[idx];
        _prod = { ..._prod, amount, hasChanged: 1 };
        _prods.splice(idx, 1, _prod);
        setProds(_prods);
    }

    const deleteProd = idx => {
        const _prod = [...prods][idx];
        setProds(prods => prods.filter((e, i) => i !== idx));
        setSelectedInvoices(ids => ids.filter(i => (i !== _prod.invoice_id)));
        if (!!_prod.id) setDeletedProds(p => p.concat(_prod.id));

    }

    const handleInvoiceSelect = ids => {
        let items = invoices
            .filter(i => ids.indexOf(i.id) !== -1)
            .map(i => ({
                invoice_id: i.id,
                invoice_no: i.invoice_no,
                balance: i.balance,
                amount: 0,
                isNew: true
            }))

        setSelectedInvoices(ids => [...ids, ...items.map(i => i.invoice_id)]);
        setProds(prev => ([...prev, ...items]));
    }

    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        let promise;

        if (action === "create") {
            promise = createPayment({
                ...details,
                items: prods.filter(p => !!parseInt(p.amount))
            });
        } else {
            promise = updatePayment({
                ...details,
                new_items: prods.filter(p => (p.isNew && !!parseInt(p.amount))),
                updated_items: prods.filter(p => (!!p.id && p.hasChanged)),
                deleted_items: deleted_prods
            }, details.id);
        }

        promise
            .then(({ payment, message }) => {
                successAlert(message);
                onSubmit(payment);
            })
            .catch(e => {
                errorAlert(e);
                setSubmitting(false);
            })
    }

    // get clients and accounts on load of the form
    useEffect(() => {

        Promise.all([getClients(1), getAccounts()])
            .then(([{ clients }, { accounts }]) => {
                setClients(
                    clients
                        // .filter(e => (e.balance > 0))
                        .map(e => ({
                            ...e,
                            description: `${e.email} - ${e.telephone}`
                        })));

                setAccounts(accounts.filter(a => a.isActive));
            })

            .catch(e => errorAlert(e));
    }, [])

    //get unpaid/partially-paid invoices for  a selected client
    useEffect(() => {

        setInvoices([]);
        setProds([]);


        if (!details.client_id) return;

        infoAlert("Loading invoices for this client...")

        getInvoices(false, { client_id: details.client_id, status: ['unpaid', 'partially-paid', 'overdue'] })
            .then(({ invoices }) => {
                setInvoices(invoices.map(i => ({
                    ...i,
                    title: i.invoice_no,
                    description: `${moment(i.invoice_date).format("DD MMM YYYY")} | ${UGX(i.due_amount).format()}`
                })));

                if (invoices.length > 0)
                    successAlert("Successfully loaded all client's invoices")
                else
                    warningAlert("Client has no unpaid invoices");
            })
            .catch(e => errorAlert(`Could not get unpaid invoices for the selected client. ${e}`));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.client_id])

    useEffect(() => {

        if (action === "edit") {
            setProds(paymentObject.items.map(p => ({ ...p, balance: p.amount + p.balance })))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])


    return (
        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label">Details</h4>
            <Row className="g-2">
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client</Label>

                    {(action === "create") &&
                        <SelectInput
                            value={[details.client_id]}
                            onChange={([id]) => setDetails(d => ({
                                ...d,
                                client_id: id || ""
                            }))}
                            required
                            maxItems={1}
                            options={clients}
                            placeholder="-"
                            errorText="A client must be chosen inorder to get the list of unpaid invoices."
                        />
                    }

                    {(action === "edit") &&
                        <Control value={details.client.title} readOnly />
                    }

                </Col>
                <Col sm={6} className='my-1'>
                    <Label className="form-field-title">Paid By</Label>
                    <Control
                        value={details.payee}
                        onChange={e => setDetails({ ...details, payee: e.currentTarget.value })}
                        required
                    />
                    <Feedback type="invalid">
                        Required field
                    </Feedback>
                </Col>
            </Row>
            <Row className="g-2">
                <Col sm={5} className="my-1">
                    <Label className="form-field-title">Payment Date</Label>
                    <DateSelector
                        value={details.payment_date}
                        onChange={val => setDetails(d => ({ ...d, payment_date: val }))}
                        required={true}
                        minDate={(profile.permission_level === 1) ? false : moment().subtract(1, 'M').format("YYYY-MM-DD")}
                        maxDate={0}
                    />
                    <Feedback type="invalid">
                        The payment date should be defined.
                    </Feedback>
                </Col>
                <Col sm={3} xs={6} className="my-1">
                    <Label className="form-field-title">Payment Method</Label>
                    <Form.Select
                        as="select"
                        value={details.method}
                        onChange={e => setDetails({ ...details, method: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {paymentMethods.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select a method for payment used for this transaction.
                    </Feedback>
                </Col>
                <Col sm={4} xs={6} className="my-1">
                    <Label className="form-field-title">Account</Label>
                    <Form.Select
                        as="select"
                        value={details.account_id}
                        onChange={e => setDetails({ ...details, account_id: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {accounts.map(c => <option key={c.id} value={c.id}>{c.title}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select the account this payment went into.
                    </Feedback>
                </Col>
            </Row>
            <Row>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Currency</Label>
                    <Form.Select
                        value={details.currency}
                        onChange={e => setDetails(d => ({ ...d, currency: e.target.value }))}
                    >
                        <option value="UGX">UGX</option>
                        <option value="USD">USD</option>
                    </Form.Select>
                </Col>
                <Col sm={4} className="my-1">
                    <Label className="form-field-title">Exchange Rate</Label>
                    <Control
                        type="number"
                        value={details.exchange_rate}
                        onChange={e => setDetails(d => ({ ...d, exchange_rate: e.target.value }))}
                        required={details.currency !== "UGX"}
                    />
                </Col>
            </Row>

            {(details.method === "cash") &&
                <Row>
                    <Col sm={6} className='my-1'>
                        <Label className="form-field-title">Paid To</Label>
                        <Control
                            value={details.paid_to}
                            onChange={e => setDetails({ ...details, paid_to: e.currentTarget.value })}
                            required
                        />
                        <Feedback type="invalid">
                            Required field
                        </Feedback>
                    </Col>
                </Row>
            }


            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Remarks</Label>

                    <Control
                        as="textarea"
                        value={details.remarks}
                        onChange={e => setDetails({ ...details, remarks: e.currentTarget.value })}
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        required
                    />
                    <Feedback type="invalid">
                        Required field
                    </Feedback>
                    <Form.Text muted>
                        What is this payment for?
                    </Form.Text>
                </Col>
            </Row>

            <h4 className="form-section-label">Invoices</h4>

            <Row className="d-none d-sm-flex">
                <Col sm={5}><Label className="form-field-title">Invoice</Label></Col>
                <Col sm={3}><Label className="form-field-title">Balance</Label></Col>
                <Col sm={4}><Label className="form-field-title">Amount Paid</Label></Col>
            </Row>

            {prods.map((p, idx) => (
                <Row key={idx} className="g-2 my-3 my-sm-0 pb-3 pb-sm-0">
                    <Col sm={5} xs={12}>
                        <Label className="form-field-title d-sm-none">Invoice</Label>
                        <Control
                            readOnly
                            value={p.invoice_no}
                            size="sm"
                        />

                    </Col>
                    <Col sm={3} xs={5}>
                        <Label className="form-field-title d-sm-none">Balance</Label>
                        <Control
                            readOnly
                            value={cur(p.balance, 2).format()}
                            size="sm"
                        />

                    </Col>
                    <Col sm={4} xs={7}>
                        <Label className="form-field-title d-sm-none">Amount Paid</Label>
                        <InputGroup>
                            <Control
                                type="number"
                                value={p.amount}
                                min={500}
                                size="sm"
                                max={Math.ceil(p.balance)}
                                onChange={e => changeProdAmount(e.currentTarget.value, idx)}
                                step={0.01}
                            />
                            <Button variant="link" size="sm" className='text-danger' onClick={() => deleteProd(idx)}>
                                <i className="fas fa-times" />
                            </Button>
                        </InputGroup>
                        <Control.Feedback type="invalid">
                            Amount must be less than {cur(p.balance, 0).format()} but more than 500
                        </Control.Feedback>
                    </Col>
                </Row>

            ))}

            {(display_invoices.length > 0) &&
                <Row>
                    <Col className='my-2'>
                        <Select
                            items={display_invoices}
                            size="sm"
                            className='rounded-pill'
                            maxItems={10}
                            onSelect={handleInvoiceSelect}
                        >
                            <i className='fas fa-circle-plus me-1' />Add Invoice(s)
                        </Select>
                    </Col>
                    <Col className='my-2 text-end h4'>
                        {UGX(prods.reduce((p, c) => p + parseFloat(c.amount), 0)).format()}
                    </Col>
                </Row>
            }



            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        {action === "edit" ? "Update Payment" : "Create Payment"}
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting} onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>
        </Form>
    )
}


export default PaymentForm;