import fetchData, { APIURL, getData, postData } from "../fetch";
import faker from "faker";
import moment from "moment";
import { payrollPaymentStatus, payrollTypes } from "../../assets/constants";


/**
 * @typedef {{
 *  id: string
 *  staff_id: string
 *  display_name: string
 *  duration: number
 *  overtime_hrs: string
 *  isRisk: boolean
 *  amount: number
 *  isProcessed: boolean
 *  date_processed: string
 *  isExpensed: boolean
 *  date_expensed: string
 *  expense_id: string
 *  event_labour_id: string
 *  payroll_payment_id: string
 *  advance_id: string
 * }} LabourObject
 */


/**
 * @typedef {{
 *  id: string
 *  reference: string
 *  event_id: string
 *  activity: string
 *  activity_date: string
 *  payroll_type: string
 *  overtime_rate: number
 *  total_amount: number
 *  status: string
 *  workers: LabourObject[]
 *  date_added: string
 *  date_updated: string
 * }} StaffPaymentObject
 */

/**
 * @typedef {{
 *  id: string 
 *  contact_id: string 
 *  pay_rate: number
 *  pay_unit: "day"|"hour"|"week"|"month"
 *  designation: string
 *  tin_no: string
 *  nssf_no: string
 *  bank_name: string
 *  bank_account_no: string
 *  pay_mtd: number
 *  pay_ytd: number
 *  staff_type: "casual"|"permanent"|"consultant"
 *  total_pay: number
 *  display_name: string
 *  other_names: string
 *  last_name: string
 *  email: string
 *  telephone: string
 *  start_date: string
 *  end_date: string
 *  isActive: number
 *  allowances: import('./allowances').AllowanceItemObject[]
 *  salaries: import('./salaries').SalaryItemObject[]
 *  advances: []
 * }} StaffObject
 */

/**
 * @typedef {{
 *  id: string 
 *  reference: string 
 *  expense_type: number
 *  staff_id: "day"|"hour"|"week"|"month"
 *  cash_amount: number
 *  wht_amount: number
 *  nssf_amount: number
 *  payee_amount: number
 *  advance_amount: number
 *  total_amount: number
 *  expense_id: number
 * }} PayrollExpenseObject
 */

/**
 * 
 * @param {StaffObject} data 
 * @returns {Promise<{code: number, message: string, staff: StaffObject}>}
 */
const createStaff = data => {
    return postData({
        url: `${APIURL}/staff`,
        data
    })
}

/**
 * Update staff details
 * @param {String} id 
 * @param {string[]} include
 * @returns {Promise<{code: number, message: string, staff: StaffObject}>}
 */
const getStaff = (id, include = []) => fetchData({ url: `${APIURL}/staff/${id}`, data: { include: include.join(",") } })

/**
 * Update staff details
 * @param {StaffObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, staff: StaffObject}>}
 */
const updateStaff = (data, id) => {
    return postData({
        url: `${APIURL}/staff/${id}`,
        data
    })
}

/**
 * Update staff details
 * @param {StaffObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, staff: StaffObject}>}
 */
const updateStaffContact = (data, id) => {
    return postData({
        url: `${APIURL}/staff/${id}/contact`,
        data
    })
}


/**
 * get details of a payroll payment
 * @param {string} id ID of the payment
 * @param {?string[]} include 
 * @returns {Promise<{code: number, message: string, payment: StaffPaymentObject}>}
 */
const getPayrollPayment = (id, include = null) => {

    const obj = { url: `${APIURL}/payroll-payments/${id}` };

    if (include) obj.data = { include: include.join(",") };

    return fetchData(obj);
}


/**
 * create a payroll payment
 * @param {data} StaffPaymentObject
 * @returns {Promise<{code: number, message: string, payment: StaffPaymentObject}>}
 */
const createPayrollPayment = (data) => postData({ url: `${APIURL}/payroll-payments`, data });

/**
 * create a payroll payment
 * @param {data} StaffPaymentObject
 * @returns {Promise<{code: number, message: string, payment: StaffPaymentObject}>}
 */
const updatePayrollPayment = (data, id) => postData({ url: `${APIURL}/payroll-payments/${id}`, data });

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, payments: StaffPaymentObject[]}>}
 */
const getStaffPayments = (getAll = false) => {

    const obj = { url: `${APIURL}/payroll-payments` }

    if (getAll) obj.data = { expanded: 1 }

    return getData(obj);
}

/**
 * 
 * @param {id} id ID of the stuaff memeber
 * @returns {Promise<{code: number, message: string, payments: PayrollExpenseObject[]}>}
 */
const getIndStaffPayments = (id) => fetchData({ url: `${APIURL}/staff/${id}/payments` })

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, payments: LabourObject[]}>}
 */
const getInitiatedPayments = () => {

    const obj = { url: `${APIURL}/payroll-payments/initiated` }

    return getData(obj)

}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, payments: LabourObject[]}>}
 */
const getApprovedPayments = () => {

    const obj = { url: `${APIURL}/payroll-payments/approved` }

    return getData(obj)

}

/**
 * 
 * @param {string[]} ids If to get the expanded list  
 * @returns {Promise<{code: number, message: string}>}
 */
const processPayments = (ids) => postData({ url: `${APIURL}/payroll-payments/approve`, data: { payment_ids: ids } })


/**
 * 
 * @param {{account: string, "advance_amounts": {staff_id: string, advance_amount: number}[]}} data If to get the expanded list  
 * @returns {Promise<{code: number, message: string}>}
 */
const executePayments = (data) => postData({ url: `${APIURL}/payroll-payments/process`, data })

/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, payment: StaffPaymentObject}>}
 */
const getStaffPayment = (id, includes = null) => {

    const categories = Object.keys(payrollTypes);

    return Promise.resolve({
        payment: {
            id: faker.datatype.uuid(),
            reference: faker.datatype.string(10).replace(/[^A-Za-z]/g, "").toUpperCase(),
            vendor: faker.company.companyName(),
            payroll_type: categories[faker.datatype.number(categories.length - 1)],
            description: faker.lorem.sentences(5),
            total_amount: faker.finance.amount(10000, 1000000),
            status: payrollPaymentStatus[faker.datatype.number(payrollPaymentStatus.length - 1)],
            added_by: faker.datatype.uuid(),
            updated_by: faker.datatype.uuid(),
            date_added: moment(faker.date.past()).format("YYYY-MM-DD"),
            date_updated: moment(faker.date.past()).format("YYYY-MM-DD"),
            workers: Array(15).fill(0).map(() => ({
                id: faker.datatype.uuid(),
                staff_id: faker.datatype.uuid(),
                display_name: faker.name.findName(),
                duration: faker.datatype.number(5),
                overtime_hrs: faker.datatype.number(4),
                amount: faker.finance.amount(20000, 100000, 0),
                isProcessed: faker.datatype.boolean(),
                added_by: faker.datatype.uuid(),
                updated_by: faker.datatype.uuid(),
                date_added: moment(faker.date.past()).format("YYYY-MM-DD"),
                date_updated: moment(faker.date.past()).format("YYYY-MM-DD"),
            }))

        }
    })
}

/**
 * 
 * @returns  {Promise<{code: number, message: string, expenses: []}>}
 */
const getExpenses = () => fetchData({ url: `${APIURL}/payroll-payments/expenses` })


/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, payments: PayrollExpenseObject[]}>}
 */
const getPaidPayments = (reference) => {

    const obj = { url: `${APIURL}/payroll-payments/expenses/${reference}` }

    return getData(obj)

}



/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, staff: StaffObject[]}>}
 */
const getAllStaff = (includeAllFields = false) => {

    const obj = { url: `${APIURL}/staff` }

    if (includeAllFields) obj.data = { expanded: 1 }

    return getData(obj);

}

/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
// const deleteExpense = id => getData({ url: `${APIURL}/expenses/${id}`, method: "DELETE" })


export {
    getStaffPayments,
    getStaffPayment,
    getInitiatedPayments,
    getAllStaff,
    createStaff,
    updateStaff,
    updateStaffContact,
    getPayrollPayment,
    createPayrollPayment,
    processPayments,
    getApprovedPayments,
    executePayments,
    getExpenses,
    getPaidPayments,
    getIndStaffPayments,
    getStaff,
    updatePayrollPayment
}