import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Form, Table, Card, Row, Col, Dropdown } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import { Link, useSearchParams } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import currency from '../../components/currency';
import moment from 'moment';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { getInvoices } from '../../resources/api/invoices';
import { UCWords, sortFunction } from '../../components/resources';
import { APIURL } from '../../resources/fetch';
import cur from '../../components/currency';
import { infoAlert } from '../../components/toastr';
import { createFile } from '../../components/exceller';


/**
 * View table items on screens larger than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/invoices').InvoiceObject[]} props.items
 * @param {number} props.currentPage
 * @param {number} props.noPageItems
*/
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems, totals }) => {

    const El = ({ field, children, ...props }) => <th {...props} style={{ whiteSpace: "nowrap" }}>{children} <SortButton field={field} {...srt} handleSort={handleSort} /></th>;

    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "14%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "18%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "11%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "13%" }} />
            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <El field="invoice_no">Invoice No</El>
                    <El field="client">Client</El>
                    <El field="job">Job</El>
                    <El field="invoice_date">Date</El>
                    <El field="due_date">Due</El>
                    <El field="currency">Cur</El>
                    <El className="text-end" field="due_amount">Amount</El>
                    <El className="text-end" field="balance">Balance</El>
                    <El field="status">Status</El>
                    <El field="version">Version</El>
                    <th className='text-center'>Action</th>
                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i} style={{ color: e.status === "overdue" ? 'var(--bs-danger)' : "inherit" }}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={`/app/invoices/${e.id}`}>
                                {e.invoice_no}
                            </Link>
                        </td>
                        <td>
                            <Link to={`/app/clients/${e.client_id}`}>{e.client}</Link>
                        </td>
                        <td>
                            {!!e.job_id ?
                                <Link to={`/app/jobs/${e.job_id}`}>{e.job}</Link> :
                                'n/a'
                            }
                        </td>
                        <td>{moment(e.invoice_date).format("DD MMM YYYY")}</td>
                        <td>{moment(e.due_date).format("DD MMM YYYY")}</td>
                        <td>{e.currency}</td>
                        <td className="text-end">{currency(e.due_amount, 0).format()}</td>
                        <td className="text-end">{currency(e.balance, 0).format()}</td>
                        <td>{UCWords(e.status.replace(/-/g, " "))}</td>
                        <td>{e.version}</td>
                        <td className='text-center'>
                            <a href={`${APIURL}/invoices/${e.id}/pdf`} title="Print Invoice" className='text-secondary'>
                                <i className="far fa-file-pdf" />
                            </a>
                        </td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={7} />
                    <th className='text-end'>
                        {cur(totals.due, 0).format()}
                    </th>
                    <th className='text-end'>
                        {cur(totals.balance, 0).format()}
                    </th>
                    <th colSpan={3} />
                </tr>
            </tfoot>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/invoices').InvoiceObject[]} props.items
 */
const ViewMobile = ({ srt, handleSort, items }) => {

    const El = ({ field, children }) => (
        <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
            {children} <SortButton field={field} {...srt} handleSort={handleSort} />
        </span>
    )
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <El field="invoice_no">Invoice No</El>
                <El field="client">Client</El>
                <El field="job">Job</El>
                <El field="invoice_date">Date</El>
                <El field="due_date">Due Date</El>
                <El field="due_amount">Amount</El>
                <El field="balance">Balance</El>
                <El field="status">Status</El>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span className="text-muted font-weight-normal">{moment(e.invoice_date).format("DD MMM YYYY")} | V:{e.version}</span>
                            <span className="text-muted font-weight-normal">{e.invoice_type} | {e.status.replace(/-/g, " ")}</span>
                        </div>

                        <div className="lead mb-2">
                            <Link to={`/app/invoices/${e.id}`}>{e.invoice_no}</Link>
                        </div>
                        <Row>
                            <Col xs={4} className="small">
                                JOB REF
                            </Col>
                            <Col xs={8}>
                                {!!e.job_id ?
                                    <Link to={`/app/jobs/${e.job_id}`}>{e.job}</Link> :
                                    'n/a'
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                CLIENT
                            </Col>
                            <Col xs={8}>
                                <Link to={`/app/clients/${e.client_id}`}>{e.client}</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                AMOUNT
                            </Col>
                            <Col xs={8} className="fw-bold">
                                {e.currency} {cur(e.due_amount, 0).format()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                BALANCE
                            </Col>
                            <Col xs={8} className="fw-bold">
                                {e.currency} {cur(e.balance, 0).format()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                DUE DATE
                            </Col>
                            <Col xs={8}>
                                {moment(e.due_date).format("DD MMM YYYY")}
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-between mt-2 small">
                            <a href={`${APIURL}/invoices/${e.id}/pdf`} title="Print Invoice" className='text-secondary'>
                                <i className="far fa-file-pdf me-1" />Download
                            </a>
                        </div>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )
}

/**
 * module that handles view of clients
 * match path /app/clients
 */
const ViewInvoices = () => {

    const navItems = [{ title: 'Invoices' }];

    const [srt, setSrt] = useState({
        currentField: 'invoice_date',
        currentOrder: 'desc'
    });

    const [searchParams] = useSearchParams();

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'invoice_no' });
    const [view, setView] = useState("all");


    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);

    const [totals, setTotals] = useState({ balance: 0, due: 0, payment: 0 });

    useEffect(() => {

        const _view = searchParams.get("view") || "all";
        setView(_view);

    }, [searchParams])

    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {

        let view_items = [...orginalItems];

        switch (view) {
            case "unpaid":
                view_items = view_items.filter(i => (['unpaid', 'partially-paid', 'overdue'].indexOf(i.status) !== -1));
                break;
            case "overdue":
                view_items = view_items.filter(i => (['overdue'].indexOf(i.status) !== -1));
                break;
            case "sent":
                view_items = view_items.filter(i => (['sent'].indexOf(i.status) !== -1));
                break;
            case "cancelled":
                view_items = view_items.filter(i => (['cancelled'].indexOf(i.status) !== -1));
                break;
            case "draft":
                view_items = view_items.filter(i => (['draft'].indexOf(i.status) !== -1));
                break;
            default:
        }

        const v = search.val, f = search.field;


        if (!(v.length < 2 || f === ""))
            view_items = [...view_items.filter(i => (i[f]?.toLowerCase().indexOf(v.toLowerCase()) !== -1))];

        setItems(view_items);

        setTotals(view_items.reduce(
            (p, c) => ({
                balance: (p.balance + c.balance),
                payment: (p.payment + c.payment_amount),
                due: (p.due + c.due_amount)
            }), { balance: 0, due: 0, payment: 0 }
        ));

    }, [search.val, search.field, orginalItems, view]);


    /**
     * get the items
     */
    useEffect(() => {

        getInvoices(true)
            .then(({ invoices }) => {
                const date_now = moment().format("YYYY-MM-DD")
                invoices = invoices.map(i => ({
                    ...i,
                    status: ((['unpaid', 'partially-paid'].indexOf(i.status) !== -1) && (date_now >= i.due_date)) ? "overdue" : i.status,
                }))
                setOriginalItems(invoices);
                setItems(invoices);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder);

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }



    const views = {
        all: "All",
        unpaid: "Unpaid",
        overdue: "Overdue",
        sent: "Follow Up",
        cancelled: "Cancelled",
        draft: "Unsent"
    }

    const onDownloadClick = () => {


        infoAlert("Preparing download...");

        //add event date, and event title. 
        const headers = {
            "reference": "Reference",
            "invoice_no": "Invoice No",
            "client": "Client",
            "due_date": "Payment Due Date",
            "total_amount": "Total",
            "tax_amount": "Tax Amount",
            "due_amount": "Invoice Amount",
            "payment_amount": "Payment Amount",
            "balance": "Balance",
            "status": "Status"
        };

        const keys = Object.keys(headers);


        let _items = {
            invoices: []
        };

        _items.invoices.push(keys.map(e => headers[e]));

        items.forEach(i => {
            _items.invoices.push(keys.map(e => {
                if (["invoice_date", "due_date"].indexOf(e) !== -1)
                    return moment(i[e]).format("DD MMM YYYY");

                if (e === "status")
                    return UCWords(i[e].replace(/-/g, " "));

                if (["pretax_amount", "management_fee_amount", "discount_amount", "total_amount", "tax_amount", "due_amount", "payment_amount", "balance"].indexOf(e) !== -1)
                    return cur(i[e], 2).format();


                return i[e];
            }));
        })

        createFile(_items, "Legacy UG Invoices.xlsx", `${views[view]} Invoices`);

    }



    if (!loaded) return <PageLoading>Loading all invoices...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Invoices</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn onClick={onDownloadClick} title="Download">
                            <i className="fas fa-cloud-download-alt" />
                        </Btn>
                        <Btn href="/app/invoices/new" title="New Invoice">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>

                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <Row>
                    <Col>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                                View: <span className='fw-bold'>{views[view]}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {Object.keys(views).map(v => <Dropdown.Item as={Link} key={v} to={`/app/invoices?view=${v}`}>{views[v]}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0" style={{ overflowX: "auto" }}>

                    <Col xs={"auto"}>
                        <Form.Control placeholder="-" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col xs={"auto"}>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col xs={"auto"}>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="invoice_no">Invoice No</option>
                            <option value="client">Client</option>
                            <option value="job">Job Title</option>
                            <option value="status">Status</option>
                            <option value="version">Version</option>
                        </Form.Select>
                    </Col>


                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} totals={totals} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>
        </>
    )
}


export default ViewInvoices;