
import { Form } from 'react-bootstrap';
import { SubmitButton } from '../components/btns';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { errorAlert, successAlert } from '../components/toastr';
import { ErrorLoading, PageLoading } from '../components/loading';
import { activateAccount, verifyResetParameters } from '../resources/api/auth';


const { Control, Label } = Form;

/**
 * If a user is not logged in then the user is prompted to log in. 
 * @param {{
 * 
 * }} param0 
 */
const ActivateAccount = ({ loginUser }) => {

    const [details, setDetails] = useState({});
    const [oldpassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfPassword] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [validated, setValidated] = useState(false);

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    const { search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(search),
            i = params.get("i"),
            s = params.get("s");

        if (!s || !i) {
            setError("Invalid activation link.");
            setLoaded(true);
        }

        verifyResetParameters(i, s)
            .then(({ user_details }) => {
                setDetails(user_details);
            })
            .catch(err => setError(err))
            .finally(() => setLoaded(true))


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            errorAlert("Some of your input is incorrect. These errors have been highlighted for you.");
            setValidated(true);
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const data = {
            oldPassword: oldpassword,
            newPassword: password,
            newPassword2: confirmpassword,
            user_id: details.user_id,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        activateAccount(data)
            .then(({ message, user_details }) => {
                successAlert(message);
                loginUser(user_details);
                navigate("/app");
            })
            .catch(err => {
                errorAlert(err);
                setSubmitting(false);
            })


    }

    if (!loaded) return <PageLoading>Verifying the link ...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <>

            <div style={{ height: "100vh" }} className="d-flex p-3 mx-auto flex-column justify-content-center align-items-center">

                <img src="/logo/logo512.png" id="logo" alt="Company Logo" style={{ maxWidth: "90%", maxHeight: "200px" }} />


                <div className="login-container mt-4" style={{ width: '22rem', maxWidth: "90%" }}>

                    <p className='text-center'>
                        Welcome, {details.display_name}. Please fill in the details below to activate your account.
                    </p>

                    <Form noValidate onSubmit={handleSubmit} validated={validated}>

                        <div className="my-2">
                            <Label className="form-field-title">Old/Temporary Password</Label>
                            <Control
                                // placeholder="current/temporary password"
                                type="password"
                                value={oldpassword}
                                onChange={e => setOldPassword(e.currentTarget.value)}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Old password is required.
                            </Control.Feedback>
                        </div>
                        <div className="my-2">
                            <Label className="form-field-title">New Password</Label>
                            <Control
                                // placeholder="your new password"
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.currentTarget.value)}
                                required
                            />
                            <Control.Feedback type="invalid">
                                New password is required.
                            </Control.Feedback>
                        </div>
                        <div className="my-2">
                            <Label className="form-field-title">Confirm New Password</Label>
                            <Control
                                // placeholder="confirm new password"
                                type="password"
                                value={confirmpassword}
                                onChange={e => setConfPassword(e.currentTarget.value)}
                                required
                            />
                            <Control.Feedback type="invalid">
                                Password confirmation is required.
                            </Control.Feedback>
                        </div>

                        <div className="mt-4">
                            <SubmitButton
                                className="w-100 rounded-pill"
                                isSubmitting={submitting}
                                type="submit"
                            >
                                Activate Account
                            </SubmitButton>
                        </div>

                    </Form>

                </div>
            </div>
        </>
    );
}

export default ActivateAccount;