import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from '../../components/pageheader';
import { useNavigate } from 'react-router-dom';
import PriceListForm from './pricelist-form';

const itemObject = {
    name: "", description: "", price: "", category: "", inventory: []
}

const NewPricelistItem = () => {

    const nav_items = [
        { title: 'Price List', href: '/app/pricelist' },
        { title: 'New Item' }
    ];

    // const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleSubmit = (details) => {

        // const action = searchParams.get('action');

        // if (action === "create_event") {
        //     navigate(`/app/events/new?client=${details.id}`);
        // } else {
        //     navigate(`/app/clients/${details.id}`);
        // }

        navigate(`/app/pricelist/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <PriceListForm
                    details={itemObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/pricelist').PricelistObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/pricelist').PricelistObject>>} props.setDetails 
 */
const EditPricelistItem = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Price List', href: '/app/pricelist' },
        { title: details.name, href: `/app/pricelist/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/pricelist/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.name}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <PriceListForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewPricelistItem, EditPricelistItem };