import { useContext } from "react";
import { Routes, Route } from "react-router-dom"
import { AppUserContext } from "../../App";
import { ErrorLoading } from "../../components/loading";
import UserDetails from "./user-details";
import { NewUser } from "./user-manipulation";
import ViewUsers from "./view-users";

const Users = () => {


    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 1) {
        return <ErrorLoading>You do not have the permission to access this module.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/new" element={<NewUser />} />
            <Route path="/:userid/*" element={<UserDetails />} />
            <Route path="/" element={<ViewUsers />} />
        </Routes>
    )
}

export default Users;