import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn } from '../../components/pageheader';
import { Form, Table, Card, Row, Col, Dropdown } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect } from 'react';
import PagePagination, { usePagination } from '../../components/pagination';
import moment from 'moment';
import currency, { UGX } from '../../components/currency';
import { Link, useSearchParams } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { UCWords, sortFunction } from '../../components/resources';
import { getAllowances } from '../../resources/api/allowances';

/**
 * View table items on screens larger than md
 * @param {{
 * srt: {currentField: string, currentOrder: string}
 * handleSort: (field: string) => void
 * items: import('../../resources/api/allowances').AllowanceObject[]
 * currentPage: number
 * noPageItems: number
 * }} param0 
 */
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {
    return (
        <Table responsive hover style={{ minWidth: '700px' }}>

            <thead>
                <tr>
                    <th>#</th>
                    <th>Ref <SortButton field="reference" {...srt} handleSort={handleSort} /></th>
                    <th>Period <SortButton field="period_date" {...srt} handleSort={handleSort} /></th>
                    <th>Date <SortButton field="transaction_date" {...srt} handleSort={handleSort} /></th>
                    <th>Category <SortButton field="category" {...srt} handleSort={handleSort} /></th>
                    <th style={{ textAlign: 'right' }}>Amount <SortButton field="total_amount" {...srt} handleSort={handleSort} /></th>
                    <th>Taxed <SortButton field="isTaxed" {...srt} handleSort={handleSort} /></th>
                    <th>Status <SortButton field="status" {...srt} handleSort={handleSort} /></th>
                </tr>
            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={`/app/payroll/allowances/${e.id}`}>
                                {e.reference}
                            </Link>
                        </td>
                        <td>{UCWords(e.category.replace(/-/g, " "))}</td>
                        <td>{moment(e.period_date).format("MMM YYYY")}</td>
                        <td>{moment(e.transaction_date).format("DD MMM YYYY")}</td>
                        <td style={{ textAlign: 'right' }}>{currency(e.total_amount).format()}</td>
                        <td>{e.isTaxed ? "Yes" : "No"}</td>
                        <td>{UCWords(e.status.replace(/-/g, " "))}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {{
* srt: {currentField: string, currentOrder: string}
* handleSort: (field: string) => void
* items: import('../../resources/api/allowances').AllowanceObject[]
* }} param0 
*/
const ViewMobile = ({ srt, handleSort, items }) => {
   return (
       <div className="my-3">
           <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
               <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                   Reference <SortButton field="reference" {...srt} handleSort={handleSort} />
               </span>
               <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                   Date <SortButton field="transaction_date" {...srt} handleSort={handleSort} />
               </span>
               <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                   Period <SortButton field="period_date" {...srt} handleSort={handleSort} />
               </span>
               <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
                   Amount <SortButton field="total_amount" {...srt} handleSort={handleSort} />
               </span>
           </div>
           {items.map((e, i) => (
               <Card className="my-2 shadow-sm" key={i}>
                   <Card.Body className="px-3">
                       <div className="d-flex justify-content-between mb-3">
                           <Link
                               to={`/app/payroll/allowances/${e.id}`}
                               className="lead text-secondary stretched-link">
                               {e.reference}
                           </Link>
                           <span className="text-muted">{moment(e.date_added).format("DD MMM YYYY")}</span>
                       </div>

                       <div>
                           <h4 className="h4">{UGX(e.total_amount).format()}</h4>
                           <Row>
                               <Col xs={3}>Category</Col>
                               <Col xs={9}>{UCWords(e.category.replace(/-/g, " "))}</Col>
                           </Row>
                       </div>
                       {!!e.expense_id &&
                           <div className="d-flex justify-content-between mt-3">
                               <span className="text-muted">Paid on {moment(e.expense_date).format("DD MMMM YYYY")}</span>
                           </div>
                       }

                   </Card.Body>
               </Card>
           ))}
       </div>
   )
}

const ViewAllowances = () => {

    const navItems = [
        { title: "Payroll", href: "/app/payroll" },
        { title: 'Allowances' }
    ];

    const [srt, setSrt] = useState({
        currentField: 'transaction_date',
        currentOrder: 'desc'
    });
    const [view, setView] = useState("all");

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'reference' });

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    const screenType = useScreenType();
    const [searchParams] = useSearchParams();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);


    useEffect(() => {

        const _view = searchParams.get("view") || "all";
        setView(_view);

    }, [searchParams])


    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {

        let view_items = [...orginalItems];

        switch (view) {
            case "paid":
                view_items = view_items.filter(i => (['paid'].indexOf(i.status) !== -1));
                break;
            case "submitted":
                view_items = view_items.filter(i => (['awaiting-approval'].indexOf(i.status) !== -1));
                break;
            case "approved":
                view_items = view_items.filter(i => (['approved'].indexOf(i.status) !== -1));
                break;
            case "draft":
                view_items = view_items.filter(i => (['draft'].indexOf(i.status) !== -1));
                break;
            default:
        }

        const v = search.val, f = search.field;

        if (!(v.length < 2 || f === ""))
            view_items = [...view_items.filter(i => i[f]?.toLowerCase().indexOf(v.toLowerCase()) !== -1)];

        setItems(view_items);

    }, [search.val, search.field, orginalItems, view]);


    /**
     * get the items
     */
    useEffect(() => {

        getAllowances(true)
            .then(({ allowances }) => {
                setOriginalItems(allowances);
                setItems(allowances);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder);

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }

    const views = {
        all: "All",
        paid: "Paid",
        approved: "Pending Payment",
        submitted: "Pending Approval",
        draft: "Draft",
    }

    if (!loaded) return <PageLoading>Loading all staff allowances...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;


    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Allowances</Title>
                <ActionBar>
                    <Btn href="/app/payroll/allowances/new">
                        <i className="fas fa-plus-circle me-1" />New Payment
                    </Btn>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <Row>
                    <Col>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                                View: <span className='fw-bold'>{views[view]}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {Object.keys(views).map(v => <Dropdown.Item as={Link} key={v} to={`/app/payroll/allowances?view=${v}`}>{views[v]}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>

                <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                    <Col>

                        <Form.Control placeholder="search" size="sm"
                            value={search.val}
                            onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                        />
                    </Col>
                    <Col>
                        <span className="mx-1">in</span>
                    </Col>
                    <Col>
                        <Form.Select as="select" size="sm"
                            value={search.field}
                            onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                        >
                            <option value="reference">Reference</option>
                            <option value="status">Status</option>
                        </Form.Select>
                    </Col>

                </Row>

                {screenType === 'desktop' ?
                    <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                    <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
                }

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                        <Col className="me-2 text-nowrap">No Items:</Col>
                        <Col>
                            <Form.Select
                                value={noPageItems}
                                onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                                size="sm"
                                style={{ maxWidth: '100px' }}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </Form.Select>
                        </Col>

                        <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                    </Row>
                    <PagePagination
                        page={PAGE}
                        no_pages={NOPAGES}
                        setPage={setPage}
                    />
                </div>
            </div>

        </>
    )
}


export default ViewAllowances;