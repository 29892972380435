import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import style from '../expenses/expenses.scss';
import { useContext, useEffect, useState } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
// import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
import { UCWords } from '../../components/resources';
import { ConfirmAction } from '../../components/prompt';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { ExecutePayment } from './execute-payment';
import { changeAdvanceStatus, deleteAdvance, getAdvance } from '../../resources/api/advances';
import { AppUserContext } from '../../App';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/advances').AdvanceObject} props.details
 * 
 */
const ViewPayment = ({ details, setDetails }) => {

    const nav_items = [
        { title: "Payroll", href: "/app/payroll" },
        { title: 'Advances', href: '/app/payroll/advances' },
        { title: details.staff.display_name }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };
    const { profile } = useContext(AppUserContext);
    const navigate = useNavigate();

    const changeStatus = new_status => {

        infoAlert("Updating document...");

        changeAdvanceStatus(details.id, new_status)
            .then(({ message, advance }) => {
                successAlert(message);
                setDetails(d => ({ ...d, ...advance }));
            })
            .catch(errorAlert)
    }

    const deletePayment = () => {
        infoAlert("Deleting payment ...");

        deleteAdvance(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate(`/app/payroll/advances`);
            })
            .catch(errorAlert)
    }

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.staff.display_name}'s Advance</Title>
                <ActionBar>
                    {(details.status === "approved") &&
                        <ButtonToolbar>
                            <ExecutePayment
                                advance_id={details.id}
                                onUpload={advance => setDetails(d => ({ ...d, ...advance }))}
                            />
                        </ButtonToolbar>
                    }


                    {(
                        (details.status === "open") ||
                        (details.status === "awaiting-approval") ||
                        ((details.status === "approved") && (profile.permission_level === 1))
                    ) &&
                        <ConfirmAction
                            as={Btn}
                            title="Delete Payment"
                            confirmationText="Are you sure you want to delete this payment?"
                            handleAction={deletePayment}
                            btnClass='text-danger'
                        >
                            <i className="fas fa-trash-alt" />
                        </ConfirmAction>
                    }

                    <ButtonToolbar>
                        {(details.status === "open") &&
                            <ConfirmAction
                                as={Btn}
                                title="Approve"
                                confirmationText="Are you sure you want to approve this for payment?"
                                handleAction={() => changeStatus('approved')}
                            >
                                <i className="fas fa-check-circle" />
                            </ConfirmAction>
                        }

                        {(details.status === "awaiting-approval") &&
                            <ConfirmAction
                                as={Btn}
                                title="Revert to Open"
                                confirmationText="Are you sure you want to open this payment?"
                                handleAction={() => changeStatus('open')}
                            >
                                <i className="fas fa-pen-ruler" />
                            </ConfirmAction>
                        }
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000 p-1 expenses">

                <div className="text-end">
                    INITIATION DATE: <span style={style1}>{moment(details.payment_date).format("DD MMMM YYYY")}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span><br />

                    {!!details.expense_date &&
                        <>PAID ON: <span style={style1}>{moment(details.expense_date).format("DD MMM YYYY")}</span><br /></>
                    }
                    {!!details.expense_id &&
                        <>EXPENSE: <span style={style1}><Link to={`/app/expenses/${details.expense_id}`}>View Expense</Link></span><br /></>
                    }
                </div>

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <Table size="sm" borderless style={{ maxWidth: '500px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "50%" }} />
                        <col span="1" style={{ width: "50%" }} />
                    </colgroup>

                    <tbody>
                        <tr>
                            <td>Duration</td>
                            <td>{details.duration} months</td>
                        </tr>
                        <tr>
                            <td>Installment</td>
                            <td>{cur(details.installment, 0).format()}</td>
                        </tr>
                        <tr>
                            <td>Paid</td>
                            <td>{cur(details.paid_amount, 0).format()}</td>
                        </tr>
                        <tr>
                            <td>Balance</td>
                            <td>{cur(details.balance, 0).format()}</td>
                        </tr>
                        <tr>
                            <td>Reason</td>
                            <td>{details?.reason || 'n/a'}</td>
                        </tr>
                    </tbody>
                </Table>

                {(details.status === "paid") && <AdvancePayments items={details.payments} />}
            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/advances').AdvancePaymentObject[]} props.items 
 * @returns 
 */
const AdvancePayments = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Payments</h5>

        <Table responsive style={{ minWidth: '300px', maxWidth: '500px' }} hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Period Date</th>
                    <th className='text-end'>Amount</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td>{moment(i.payment_date).format("DD MMM YYYY")}</td>
                        <td><Link to={`/app/payroll/salaries/${i.salary_id}`}>{moment(i.period_date).format("DD MMM YYYY")}</Link></td>
                        <td className='text-end'>{cur(i.amount, 0).format()}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={3} />
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.amount, 0), 0).format()}</th>
                </tr>
            </tfoot>

        </Table>

    </div>
)

const AdvanceDetails = () => {

    const { advanceid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getAdvance(advanceid, ['payments', 'staff'])
            .then(({ advance }) => {
                const period_date = moment(advance.period_date);
                advance.period_month = parseInt(period_date.format("MM"));
                advance.period_year = period_date.format("YYYY");
                setDetails(advance);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [advanceid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading advance details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            {/* <Route path="/edit" element={<EditPayment details={details} setDetails={setDetails} />} /> */}
            <Route path="/" element={<ViewPayment details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default AdvanceDetails;