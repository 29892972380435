import { faker } from "@faker-js/faker";
import moment from "moment";
import { financialAccounts, paymentMethods } from "../../assets/constants";
import { APIURL, deleteData, getData, postData } from "../fetch";


/**
 * @typedef {{
 *  id: string 
 *  expense_id: string
 *  category: string
 *  unit_price: number
 *  quantity: number
 *  notes: string
 *  tax_percent: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 * }} ExpensetItemObject
 */

/**
 * @typedef {{
 *  id: string 
 *  reference: string
 *  user_reference: string
 *  vendor_id: string
 *  vender_old: string 
 *  vendor: string|import("./vendors").VendorObject
 *  expense_date: string
 *  due_date: string
 *  method: string
 *  account: string 
 *  description: string
 *  pretax_amount: number 
 *  tax_amount: number 
 *  total_amount: number 
 *  isTaxInclusive: boolean 
 *  fromRequisition: boolean
 *  fromPayroll: boolean
 *  receipt_file_id: string
 *  client_id: string
 *  job_id: string
 *  client: string|import("./clients").ClientObject
 *  job: string|import("./jobs").JobObject
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  items: ExpensetItemObject[]
 * }} ExpenseObject
 */


export const createExpenseObject = () => {

    let isCorporate = faker.datatype.boolean(),
        total = faker.finance.amount(100000, 10000000, 0);

    return {
        id: faker.datatype.uuid(),
        reference: faker.datatype.string(12).replace(/[^A-Za-z]/g, "").toUpperCase(),
        vendor: isCorporate ? faker.company.name() : null,
        method: paymentMethods[faker.datatype.number({ min: 0, max: paymentMethods.length - 1 })],
        account: financialAccounts[faker.datatype.number({ min: 0, max: financialAccounts.length - 1 })],
        description: faker.lorem.sentences(3),
        isTaxInclusive: isCorporate,
        pretax_amount: faker.finance.amount(1000, total),
        tax_amount: faker.finance.amount(1000, 100000),
        total_amount: total,
        job_id: faker.datatype.uuid(),
        job: "LEG-JOB-CAA-23-" + faker.datatype.number({ min: 1, max: 1000 }).toString().padStart(6, "0"),
        client_id: faker.datatype.uuid(),
        client: faker.company.name(),
        expense_date: moment(faker.date.past()).format("YYYY-MM-DD")
    }
}



/**
 * 
 * @param {ExpenseObject} data 
 * @returns {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const createExpense = data => {
    return postData({
        url: `${APIURL}/expenses`,
        data
    })
}

/**
 * 
 * @param {ExpenseObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const updateExpense = (data, id) => {
    return postData({
        url: `${APIURL}/expenses/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, expenses: ExpenseObject[]}>}
 */
const getExpenses = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/expenses` }
    
    if (getAll) obj.data = { expanded: 1 }
    if (conditions) obj.data = { ...(obj.data || {}), ...conditions }

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, expense: ExpenseObject}>}
 */
const getExpense = (id, includes = null) => {

    const obj = { url: `${APIURL}/expenses/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteExpense = id => getData({ url: `${APIURL}/expenses/${id}`, method: "DELETE" })



/**
 * 
 * @param {string} id 
 * @param {import("./jobs").JobDocObject} data
 * @returns  {Promise<{code: number, message: string, document: import("./jobs").JobDocObject}>}
 */

const uploadDocument = (id, data) => postData({ url: `${APIURL}/expenses/${id}/documents`, data })

const deleteDocument = (jobid, id) => deleteData({ url: `${APIURL}/expenses/${jobid}/documents/${id}` });


export {
    createExpense,
    getExpense,
    getExpenses,
    updateExpense,
    deleteExpense,
    uploadDocument,
    deleteDocument
}