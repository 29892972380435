// import { faker } from "@faker-js/faker";
// import moment from "moment";
import { APIURL, deleteData, getData, postData } from "../fetch";



/**
 * @typedef {{
 * effris_vc: string
 * effris_fdn: string
 * effris_date: string
 * }} EffrisObject
 */

/**
 * @typedef {{
 *  id: string 
 *  invoice_id: string
 *  pricelist_item_id: string
 *  title: string
 *  description: string
 *  unit_price: number
 *  days: number
 *  quantity: number
 *  tax_percent: number
 *  isExpense: boolean 
 *  expense_id: string
 *  position: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string,
 *  isFromPriceList: boolean
 * }} InvoiceItemObject
 */

/**
 * @typedef {{
 *  id: string 
 *  invoice_id: string
 *  total_amount: string
 *  version: string
 *  version_date: string 
 *  items: InvoiceItemObject[]
 *  added_by: string 
 *  date_added: string 
 *  isFromPriceList: boolean
 * }} InvoiceVersionObject
 */


/**
 * @typedef {{
 *  id: string 
 *  reference: string
 *  invoice_no: string
 *  invoice_type: "proforma"|"final"
 *  client_id: string
 *  client: string | import("./clients").ClientObject
 *  job_id: string 
 *  currency: string
 *  exchange_rate: number
 *  job: string | import("./jobs").JobObject
 *  invoice_date: string
 *  due_date: string
 *  subject: string 
 *  isTaxInclusive: number 
 *  pretax_amount: number 
 *  tax_percent: number
 *  tax_amount: number 
 *  total_amount: number 
 *  discount_amount: number
 *  management_fee_amount: number
 *  due_amount: number
 *  base_due_amount: number
 *  balance: number
 *  discount_type: "percent"|"amount"
 *  discount_value: number
 *  management_fee_type: "percent"|"amount"
 *  management_fee_value: number
 *  notes: string[]
 *  tcs: string[]
 *  version: number
 *  version_date: string
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  status: string
 *  clone_id: string|null
 *  items: InvoiceItemObject[]
 *  versions: InvoiceVersionObject[]
 * }} InvoiceObject
 */


/**
 * 
 * @param {InvoiceObject} data 
 * @returns {Promise<{code: number, message: string, invoice: InvoiceObject}>}
 */
const createInvoice = data => postData({ url: `${APIURL}/invoices`, data })

/**
 * 
 * @param {InvoiceObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, invoice: InvoiceObject}>}
 */
const updateInvoice = (data, id) => postData({ url: `${APIURL}/invoices/${id}`, data })


/**
 * 
 * @param {InvoiceItemObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, item: InvoiceItemObject}>}
 */
const updateInvoiceItem = (data, id) => postData({ url: `${APIURL}/invoices/items/${id}`, data })


/**
 * 
 * @param {EffrisObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, invoice: InvoiceObject}>}
 */
const updateInvoiceEffris = (data, id) => postData({ url: `${APIURL}/invoices/${id}/effris`, data })

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, invoices: InvoiceObject[]}>}
 */
const getInvoices = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/invoices`, data: {} }
    if (getAll) obj.data.expanded = 1;
    if (conditions) obj.data = { ...obj.data, ...conditions };

    return getData(obj);
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, invoices: InvoiceObject[]}>}
 */
const getJoblessInvoices = (client_id) => getData({ url: `${APIURL}/invoices/no-jobs?client_id=${client_id}` })


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, invoice: InvoiceObject}>}
 */
const getInvoice = (id, includes = null) => {

    const obj = { url: `${APIURL}/invoices/${id}` };
    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}


/**
 * 
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteInvoice = id => deleteData({ url: `${APIURL}/invoices/${id}` })

const advanceInvoice = (id, status) => postData({ url: `${APIURL}/invoices/${id}/status/${status}` })

const reviseInvoice = (id) => postData({ url: `${APIURL}/invoices/${id}/revisions` })

/**
 * @param {string} id 
 * @param {number} version 
 * @returns  {Promise<{code: number, message: string}>}
 */
const restoreInvoiceVersion = (id, version) => postData({ url: `${APIURL}/invoices/${id}/versions/${version}/restore` });


/**
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const makeInvoiceFinal = (id) => postData({ url: `${APIURL}/invoices/${id}/type/final` });
/**
 * @param {string} id 
 * @returns  {Promise<{code: number, message: string}>}
 */
const reverseInvoice = (id) => postData({ url: `${APIURL}/invoices/${id}/type/proforma` });



/**
 * 
 * @param {InvoiceObject} data 
 * @returns {Promise<{code: number, message: string>}
 */
const sendInvoiceEmail = (data, id) => postData({ url: `${APIURL}/invoices/${id}/email`, data })


export {
    createInvoice,
    getInvoice,
    getInvoices,
    updateInvoice,
    deleteInvoice,
    advanceInvoice,
    restoreInvoiceVersion,
    getJoblessInvoices,
    makeInvoiceFinal,
    reviseInvoice,
    updateInvoiceEffris,
    updateInvoiceItem,
    reverseInvoice,
    sendInvoiceEmail
}