import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Routes, Route, useParams, Link } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import cur from "../../components/currency";
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title } from '../../components/pageheader';
import { sortFunction, UCWords } from "../../components/resources";
import { getStaff, updateStaffContact } from "../../resources/api/payroll";
import { APIURL } from "../../resources/fetch";
import { FileUpload } from "../../components/files";
import { errorAlert, successAlert } from "../../components/toastr";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/payroll").StaffObject} props.details
 */
const ViewDetails = ({ details, onUpdateImage }) => {

    const nav_items = [
        { title: 'Payroll', href: "/app/payroll" },
        { title: 'Staff', href: "/app/payroll/staff" },
        { title: details.display_name }
    ]

    const imgsrc = details.image_id ?
        `${APIURL}/files/${details.image_id}/view/image?w=64&h=64` :
        `https://eu.ui-avatars.com/api/?name=${details.display_name}&size=128`

    // const [payments, setPayments] = useState([]);



    /**
     * 
     * @param {FileObject} file 
     */
    const handleImageUpload = file => {

        if (details.image_id === file.id) {
            onUpdateImage(file.id);
            successAlert("Image sucessfully uploaded.");
            return;
        }

        updateStaffContact({ image_id: file.id }, details.id)
            .then(({ message }) => {
                successAlert(message);
                onUpdateImage(file.id);
            })
            .catch(err => errorAlert(err));
    }

    const [key, setKey] = useState('salary');


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.display_name}</Title>
            </PageHeader>

            <div className="max-1000">
                <Row>
                    <Col sm={9} className="ps-0 pe-1">
                        <img src={imgsrc} height="100" className="rounded-circle" alt="Profile" /> <br />
                        <FileUpload
                            authorisation="public"
                            accept="image"
                            className="text-danger btn-sm"
                            text="Change Image"
                            variant="link"
                            onUpload={handleImageUpload}
                            imageID={details.image_id}
                            fileCategory="web-image"
                            fileTitle={`profile picture for user ${details.id}`}
                        >
                            <span><i className="fas fa-pencil-alt me-1" />Edit</span>
                        </FileUpload>
                    </Col>
                </Row>


                <Row>
                    <Col sm={6}>
                        <dl>
                            <dt>Staff Type</dt>
                            <dd>{UCWords(details.staff_type) || 'n/a'}</dd>
                            <dt>Designation</dt>
                            <dd>{details.designation || 'n/a'}</dd>
                            <dt>NSSF No</dt>
                            <dd>{details.nssf_no || 'n/a'}</dd>
                            <dt>TIN No</dt>
                            <dd>{details.tin_no || 'n/a'}</dd>
                            <dt>Wage/Salary</dt>
                            <dd>{cur(details.pay_rate, 0).format()} per {details.pay_unit}</dd>
                        </dl>
                    </Col>
                    <Col sm={6}>
                        <dl>
                            <dt>Bank</dt>
                            <dd>{details.bank_name || 'n/a'}</dd>
                            <dt>Bank Account No</dt>
                            <dd>{details.bank_account_no || 'n/a'}</dd>
                            <dt>Start Date</dt>
                            <dd>{moment(details.start_date).format("ddd DD MMMM YYYY")}</dd>
                            <dt>End Date</dt>
                            <dd>{details.isActive ? "Active" : moment(details.end_date).format("ddd DD MMMMM YYYY")}</dd>
                        </dl>
                    </Col>
                </Row>



                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >

                    <Tab eventKey="salary" title="Salary">

                        <Table responsive style={{ minWidth: '700px', maxWidth: "900px" }} hover size="sm">
                            <thead>
                                <tr>
                                    <th>Period</th>
                                    <th>Date</th>
                                    <th className="text-end">Gross</th>
                                    <th className="text-end">WHT</th>
                                    <th className="text-end">PAYEE</th>
                                    <th className="text-end">NSSF</th>
                                    <th className="text-end">Advance</th>
                                    <th className="text-end">Other</th>
                                    <th className="text-end">Nett</th>
                                    <th>...</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.salaries
                                    .map(p => (
                                        <tr key={p.id} >
                                            <td><Link to={`/app/payroll/salaries/${p.salary_id}`}>{moment(p.period_date).format("MMM YYYY")}</Link></td>
                                            <td>{moment(p.expense_date).format("DD MMM YYYY")}</td>
                                            <td className="text-end">{cur(p.total_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.wht_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.payee_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.nssf_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.advance_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.other_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.nett_amount, 0).format()}</td>
                                            <td>
                                                <a target="_blank" rel="noreferrer" href={`${APIURL}/staff/${details.id}/payslip/pdf?period=${p.period_date}`} className="text-danger">
                                                    <i className="fas fa-file-pdf" />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="cashflow" title="Allowances">

                        <Table responsive style={{ minWidth: '400px', maxWidth: "500px" }} hover striped size="sm">
                            <thead>
                                <tr>
                                    <th>Ref</th>
                                    <th>Date</th>
                                    <th>Category</th>
                                    <th className="text-end">Cash</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.allowances.map(p => (
                                    <tr key={p.id} >
                                        <td><Link to={`/app/payroll/allowances/${p.allowance_id}`}>{p.reference}</Link></td>
                                        <td>{moment(p.expense_date).format("DD MMM YYYY")}</td>
                                        <td>{UCWords(p.category.replace(/-/g, " & "))}</td>
                                        <td className="text-end">{cur(p.amount, 0).format()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab eventKey="advance" title="Advances">
                        <Table responsive style={{ minWidth: '500px', maxWidth: "800px" }} hover size="sm">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Period</th>
                                    <th className="text-end">Total Amount</th>
                                    <th className="text-end">Installment</th>
                                    <th className="">Duration</th>
                                    <th className="text-end">Paid</th>
                                    <th className="text-end">Balance</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.advances
                                    .map(p => (
                                        <tr key={p.id} >
                                            <td>{moment(p.payment_date).format("DD MMM YYYY")}</td>
                                            <td>{moment(p.period_date).format("MMM YYYY")}</td>
                                            <td className="text-end">{cur(p.total_amount, 0).format()}</td>
                                            <td className="text-end">{cur(p.installment, 0).format()}</td>
                                            <td className="">{p.duration}</td>
                                            <td className="text-end">{cur((p.paid_amount), 0).format()}</td>
                                            <td className="text-end">{cur((p.balance), 0).format()}</td>
                                            <td><Link to={`/app/payroll/advances/${p.id}`}>View</Link></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Tab>

                </Tabs>

            </div >

        </>
    )

}


const StaffDetails = () => {

    const { staffid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setLoaded(false);
        setError();

        getStaff(staffid, ['allowances', 'salaries', 'advances'])
            .then(({ staff }) => {
                staff.allowances.sort((a, b) => sortFunction(a, b, "expense_date", "desc"));
                staff.salaries.sort((a, b) => sortFunction(a, b, "expense_date", "desc"));
                staff.advances.sort((a, b) => sortFunction(a, b, "period_date", "desc"));

                staff.allowances = staff.allowances.filter(i => !!i.expense_date);
                staff.salaries = staff.salaries.filter(i => !!i.expense_date);

                setDetails(staff);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [staffid])

    const handleImageUpdate = (id) => setDetails(d => ({ ...d, image_id: id }))


    if (!isLoaded) return <PageLoading>Loading payment details...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>

    return (
        <Routes>
            <Route path="/" element={<ViewDetails details={details} onUpdateImage={handleImageUpdate} />} />
        </Routes>
    )

}


export default StaffDetails;