import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import ViewAdvances from './view-advances';
import AdvanceDetails from './view-details';

const Advances = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access the staff payments.</ErrorLoading>
    }

    return (
        <Routes>
            {/* <Route path="new" element={<NewPayment />} /> */}
            <Route path=":advanceid/*" element={<AdvanceDetails />} />
            <Route path="/" element={<ViewAdvances />} />
        </Routes>
    )
};

export default Advances;