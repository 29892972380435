import ViewVendors from "./view-vendors";
import { Routes, Route } from 'react-router-dom';
import { useContext } from "react";
import { AppUserContext } from "../../App";
import { ErrorLoading } from "../../components/loading";
import { NewVendor } from "./vendor-manipulation";
import VendorDetails from "./vendor-details";

const Vendors = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 3) {
        return <ErrorLoading>You do not have the permission to access vendors.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/" element={<ViewVendors />} />
            {/* <Route path="/debtors" element={<ViewDebtors />} /> */}
            <Route path="/new" element={<NewVendor />} />
            <Route path="/:vendorid/*" element={<VendorDetails />} />
        </Routes>
    )
}

export default Vendors;