import ClientForm from './client-form';
import Nav from '../../components/breadcrumb';
import PageHeader, { Title } from './../../components/pageheader';
import { useNavigate, useSearchParams } from 'react-router-dom';

const clientObject = {
    title: "", contact_person: "", email: "", telephone: "", address: ""
}

const NewClient = () => {

    const nav_items = [
        { title: 'Clients', href: '/app/clients' },
        { title: 'New Client' }
    ];

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleSubmit = (details) => {

        const action = searchParams.get('action');

        if (action === "create_job") {
            navigate(`/app/jobs/new?client=${details.id}`);
        } else if (action === "create_invoice") {
            navigate(`/app/invoices/new?client=${details.id}`);
        } else {
            navigate(`/app/clients/${details.id}`);
        }
    }


    return (
        <>
            <Nav items={nav_items} />

            <div className="max-800 p-2">
                <ClientForm
                    details={clientObject}
                    onSubmit={handleSubmit}
                    action="create"
                />
            </div>
        </>
    )

}

/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/clients').ClientObject} props.details 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/clients').ClientObject>>} props.setDetails 
 */
const EditClient = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Clients', href: '/app/clients' },
        { title: details.title, href: `/app/clients/${details.id}` },
        { title: 'Edit Details' }
    ];

    const navigate = useNavigate();

    const handleSubmit = (details) => {
        setDetails(d => ({ ...d, ...details }));
        navigate(`/app/clients/${details.id}`);
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="800">
                <Title>{details.title}</Title>
            </PageHeader>

            <div className="max-800 p-2">
                <ClientForm
                    details={details}
                    onSubmit={handleSubmit}
                    action="edit"
                />
            </div>
        </>
    )

}


export { NewClient, EditClient };