// import { useContext } from 'react';
import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import ViewStats from './view-stats';
import Staff from '../staff/staff';
import Salaries from '../salaries/salaries';
import Allowances from '../allowances/allowances';
import Advances from '../advances/advances';
import Deductions from '../deductions/deductions';

const Payroll = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access the payroll module.</ErrorLoading>
    }


    return (
        <Routes>
            <Route path="/deductions/*" element={<Deductions />} />
            <Route path="/advances/*" element={<Advances />} />
            <Route path="/allowances/*" element={<Allowances />} />
            <Route path="/salaries/*" element={<Salaries />} />
            <Route path="/staff/*" element={<Staff />} />
            <Route path="/" element={<ViewStats />} />
        </Routes>
    )
};

export default Payroll;