import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import NewPayment from './salarymanipulation';
import ViewPayments from './view-salaries';
import PaymentDetails from './view-details';

const Salaries = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access the staff payments.</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="new" element={<NewPayment />} />
            <Route path=":salaryid/*" element={<PaymentDetails />} />
            <Route path="/" element={<ViewPayments />} />
        </Routes>
    )
};

export default Salaries;