import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppUserContext } from '../../App';
import { ErrorLoading } from '../../components/loading';
import ViewDeductions from './view-deductions';

const Deductions = () => {

    const { profile } = useContext(AppUserContext);

    if (profile.permission_level > 2) {
        return <ErrorLoading>You do not have the permission to access the staff deductions.</ErrorLoading>
    }

    return (
        <Routes>
            {/* <Route path="new" element={<NewPayment />} /> */}
            <Route path="/" element={<ViewDeductions />} />
        </Routes>
    )
};

export default Deductions;