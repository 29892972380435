import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import style from '../expenses/expenses.scss';
import { useContext, useEffect, useState } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
// import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
import { EditPayment } from './allowancemanipulation';
import { UCWords } from '../../components/resources';
import { ConfirmAction } from '../../components/prompt';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { ExecutePayment } from './execute-payment';
import { changeAllowanceStatus, deleteAllowance, getAllowance } from '../../resources/api/allowances';
import { AppUserContext } from '../../App';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/allowances').AllowanceObject} props.details
 * 
 */
const ViewPayment = ({ details, setDetails }) => {

    const nav_items = [
        { title: "Payroll", href: "/app/payroll" },
        { title: 'Allowances', href: '/app/payroll/allowances' },
        { title: details.reference }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };
    const { profile } = useContext(AppUserContext);
    const navigate = useNavigate();

    const changeStatus = new_status => {

        infoAlert("Updating document...");

        changeAllowanceStatus(details.id, new_status)
            .then(({ message, allowance }) => {
                successAlert(message);
                setDetails(d => ({ ...d, ...allowance }));
            })
            .catch(errorAlert)
    }


    const deletePayment = () => {
        infoAlert("Deleting payment ...");

        deleteAllowance(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate(`/app/payroll/advances`);
            })
            .catch(errorAlert)
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{details.reference}</Title>
                <ActionBar>
                    {(details.status === "approved") &&
                        <ButtonToolbar>
                            <ExecutePayment
                                allowance_id={details.id}
                                onUpload={allowance => setDetails(d => ({ ...d, ...allowance }))}
                            />
                        </ButtonToolbar>
                    }

                    <ButtonToolbar>
                        {(details.status === "draft") &&
                            <ConfirmAction
                                as={Btn}
                                title="Submit"
                                confirmationText="Are you sure you want to submit for approval?"
                                handleAction={() => changeStatus('awaiting-approval')}
                            >
                                <i className="fas fa-upload" />
                            </ConfirmAction>
                        }

                        {
                            (details.status === "awaiting-approval") &&
                            <>
                                <ConfirmAction
                                    as={Btn}
                                    title="Revert to Draft"
                                    confirmationText="Are you sure you want to draft this payment?"
                                    handleAction={() => changeStatus('draft')}
                                >
                                    <i className="fas fa-pen-ruler" />
                                </ConfirmAction>
                                <ConfirmAction
                                    as={Btn}
                                    title="Approve"
                                    confirmationText="Are you sure you want to approval this for payment?"
                                    handleAction={() => changeStatus('approved')}
                                >
                                    <i className="fas fa-check-circle" />
                                </ConfirmAction>
                            </>
                        }
                        {(
                            (details.status === "draft") ||
                            (details.status === "awaiting-approval") ||
                            ((details.status === "approved") && (profile.permission_level === 1))
                        ) &&
                            <ConfirmAction
                                as={Btn}
                                title="Delete Payment"
                                confirmationText="Are you sure you want to delete this payment?"
                                handleAction={deletePayment}
                                btnClass='text-danger'
                            >
                                <i className="fas fa-trash-alt" />
                            </ConfirmAction>
                        }
                    </ButtonToolbar>
                    <ButtonToolbar>
                        {(details.status === "draft") &&
                            <Btn href={`/app/payroll/allowances/${details.id}/edit`} title="Edit Details">
                                <i className="fas fa-pencil-alt" />
                            </Btn>
                        }

                        <Btn href="/app/payroll/allowances/new" title="Initiate Payment">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000 p-1 expenses">

                <div className="text-end">
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    INITIATION DATE: <span style={style1}>{moment(details.transaction_date).format("DD MMMM YYYY")}</span> <br />
                    STATUS: <span style={style1}>{UCWords(details.status.replace(/-/g, " "))}</span><br />

                    {!!details.expense_date &&
                        <>PAID ON: <span style={style1}>{moment(details.expense_date).format("DD MMM YYYY")}</span><br /></>
                    }
                    {!!details.expense_id &&
                        <>EXPENSE: <span style={style1}><Link to={`/app/expenses/${details.expense_id}`}>View Expense</Link></span><br /></>
                    }
                </div>

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <Table size="sm" borderless style={{ maxWidth: '500px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "50%" }} />
                        <col span="1" style={{ width: "50%" }} />
                    </colgroup>

                    <tbody>
                        <tr>
                            <td>Category</td>
                            <td>{UCWords(details.category.replace(/-/g, " "))}</td>
                        </tr>
                    </tbody>
                </Table>

                <WorkerPayments items={details.items} />
            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/allowances').AllowanceItemObject[]} props.items 
 * @returns 
 */
const WorkerPayments = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Breakdown</h5>

        <Table responsive style={{ minWidth: '300px', maxWidth: '500px' }} hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th className='text-end'>Amount</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td><Link to={`/app/payroll/staff/${i.staff_id}`}>{i.display_name}</Link></td>
                        <td className='text-end'>{cur(i.amount, 0).format()}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={2} />
                    <th className='text-end'>{cur(items.reduce((p, c) => p + c.amount, 0), 0).format()}</th>
                </tr>
            </tfoot>

        </Table>

    </div>
)

const PaymentDetails = () => {

    const { allowanceid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getAllowance(allowanceid, ['items'])
            .then(({ allowance }) => {
                const period_date = moment(allowance.period_date);
                allowance.period_month = parseInt(period_date.format("MM"));
                allowance.period_year = period_date.format("YYYY");
                setDetails(allowance);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [allowanceid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading allowance details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditPayment details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewPayment details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default PaymentDetails;