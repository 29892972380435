import Nav from '../../components/breadcrumb';
import PageHeader, {
    Title,
    ActionBar,
    ActionButton as Btn,
    ButtonToolbar
} from '../../components/pageheader';
import { Form, Table, Card, Row, Col, Modal, Button, Dropdown, FormLabel } from 'react-bootstrap';
import { SortButton } from '../../components/btns';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PagePagination, { usePagination } from '../../components/pagination';
import { Link } from 'react-router-dom';
import { useScreenType } from '../../components/custom-hooks';
import { errorAlert, info, infoAlert, successAlert } from '../../components/toastr';
import currency, { UGX } from '../../components/currency';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import bootstrapPlugin from '@fullcalendar/bootstrap' // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { ErrorLoading, PageLoading } from '../../components/loading';
// import { EventColourMaping } from '../../assets/constants';
// import { AppUserContext } from '../../App';
import { getJobs, getJobsPackingList, updateDate, updateJob } from '../../resources/api/jobs';
import { sortFunction, UCWords } from '../../components/resources';
import { Text } from '../../components/utlities';
import { EventColourMaping, confirmedJobStatuses, unconfirmedJobStatuses } from '../../assets/constants';
import { createFile } from '../../components/exceller';
import cur from '../../components/currency';
import { DateSelector } from '../../components/datetimepicker';
import { APIURL } from '../../resources/fetch';
// import { ConfirmAction } from '../../components/prompt';





/**
 * View table items on screens larger than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/jobs').JobObject[]} props.items
 * @param {number} props.currentPage
 * @param {number} props.noPageItems
*/
const ViewDesktop = ({ srt, handleSort, items, currentPage, noPageItems }) => {

    // const { profile } = useContext(AppUserContext);

    const El = ({ field, children, ...props }) => <th style={{ whiteSpace: "nowrap" }} {...props}>{children} <SortButton field={field} {...srt} handleSort={handleSort} /></th>;

    return (
        <Table responsive hover style={{ minWidth: '700px' }}>
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "12%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <El field="job_no">Job No</El>
                    <El field="client">Client</El>
                    <El field="client_contact">Client Contact</El>
                    <El field="event_date">Date</El>
                    <El field="category">Cateory</El>
                    <El field="location">Location</El>
                    <El className="text-end" field="total_amount">Amount</El>
                    <El className="text-end" field="balance">Balance</El>
                    <El field="status">Status</El>

                </tr>

            </thead>
            <tbody>
                {items.map((e, i) => (
                    <tr key={i}>
                        <td>{(currentPage - 1) * noPageItems + i + 1}</td>
                        <td>
                            <Link to={`/app/jobs/${e.id}`}>
                                {e.job_no} - {e.title}
                            </Link>
                        </td>
                        <td>{<Link to={`/app/clients/${e.client_id}`}>{<Text text={e.client} maxLength={20} />}</Link>}</td>
                        <td>{e.client_contact}</td>
                        <td>{moment(e.job_date).format("D MMM YYYY")}</td>
                        <td>{UCWords(e.category.replace(/-/g, " "))}</td>
                        <td>{e.location}</td>
                        <td className="text-end">{currency(e.total_amount, 0).format()}</td>
                        <td className="text-end">{currency(e.balance, 0).format()}</td>
                        <td>{UCWords(e.status.replace(/-/g, " "))}</td>

                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

/**
 * view items on screens smaller than md
 * @param {Object} props
 * @param {{currentField: string, currentOrder: "asc" | "desc"}} props.srt
 * @param {(field: string) => void} props.handleSort
 * @param {import('../../resources/api/jobs').JobObject[]} props.items
 */
const ViewMobile = ({ srt, handleSort, items }) => {


    // const { profile } = useContext(AppUserContext);

    const El = ({ field, children }) => (
        <span className="small text-nowrap m-1 py-1 px-2 bg-teal text-white rounded-pill">
            {children} <SortButton field={field} {...srt} handleSort={handleSort} />
        </span>
    )
    return (
        <div className="my-3">
            <div className="mb-3" style={{ whiteSpace: 'nowrap', overflowX: 'auto', maxWidth: '100%' }}>
                <El field="job_no">Job No</El>
                <El field="client">Client</El>
                <El field="client_contact">Contact</El>
                <El field="job_date">Date</El>
                <El field="location">Location</El>
                <El field="total_amount">Amount</El>
                <El field="balance">Balance</El>
            </div>
            {items.map((e, i) => (
                <Card className="my-2 shadow-sm" key={i}>
                    <Card.Body className="px-3">
                        <div className="d-flex justify-content-between mb-2">
                            <span className="text-secondary font-weight-normal">{moment(e.job_date).format("ddd DD MMM YYYY")}</span>
                            <span className="text-muted font-weight-normal">{e.status.replace(/-/g, " ")}</span>
                        </div>

                        <div className="lead mb-2 fw-normal">
                            <Link to={`/app/jobs/${e.id}`}>{e.job_no} - {e.title}</Link>
                        </div>
                        <Row>
                            <Col xs={4} className="small">
                                CLIENT
                            </Col>
                            <Col xs={8}>
                                <Link to={`/app/clients/${e.client_id}`}>{e.client}</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                CONTACT
                            </Col>
                            <Col xs={8}>
                                {e.client_contact}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                CATEGORY
                            </Col>
                            <Col xs={8}>
                                {UCWords(e.category.replace(/-/g, " "))}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                LOCATION
                            </Col>
                            <Col xs={8}>
                                {e.location}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                AMOUNT
                            </Col>
                            <Col xs={8} className="fw-bold">
                                {UGX(e.total_amount).format()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} className="small">
                                BALANCE
                            </Col>
                            <Col xs={8}>
                                {UGX(e.balance).format()}
                            </Col>
                        </Row>
                        {/* <div className="d-flex justify-content-between mt-3">
                            <small className="text-muted text-uppercase">
                                {e.category}
                            </small>
                        </div> */}
                    </Card.Body>
                </Card>
            ))
            }
        </div >
    )
}


const TableView = ({ events }) => {

    const [srt, setSrt] = useState({
        currentField: 'job_date',
        currentOrder: 'asc'
    });

    const [noPageItems, setNoPageItems] = useState(10);
    const [displayItems, setDisplayItems] = useState([]);
    const [items, setItems] = useState([]);
    const [orginalItems, setOriginalItems] = useState([]);
    const [search, setSearch] = useState({ val: '', field: 'job_no' });

    const screenType = useScreenType();
    const [PAGE, NOPAGES, setPage] = usePagination(items.length, noPageItems);


    useEffect(() => {
        setOriginalItems(events);
        setItems(events);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events.length])

    /**
     * whenever the page changes, scroll back to the top of the list.
     */
    useEffect(() => {
        if (PAGE === 1) return;
        window.scrollTo({ top: 100, behavior: "smooth" });
    }, [PAGE])

    /**
     * whenever the value and field change ie. a search, 
     * filter the items and view on the page.
     */
    useEffect(() => {
        const v = search.val, f = search.field;
        if (v.length < 2 || f === "") return setItems(orginalItems);
        setItems([...orginalItems.filter(i => i[f].toLowerCase().indexOf(v.toLowerCase()) !== -1)]);
    }, [search.val, search.field, orginalItems]);




    /**
     * Update display items whenever 
     * (1) => the page changes 
     * (2) => the number of items a page changes 
     * (3) => items change in any way
     */
    useEffect(() => {

        const ditems = [],
            start = (PAGE * noPageItems) - noPageItems,
            end = (PAGE * noPageItems) > items.length ? items.length : PAGE * noPageItems;

        for (let index = start; index < end; index++) {
            ditems.push(items[index]);
        }

        setDisplayItems(ditems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PAGE, noPageItems, JSON.stringify(items)])

    /**
     * Is triggered when 
     * (1) => user has changed the sort field
     * (2) => user has changed sort order
     * (3) => user has changed length of items (i.e., there are new items in the page)
     */
    useEffect(() => {

        const comp = (a, b) => sortFunction(a, b, srt.currentField, srt.currentOrder);

        setItems(items => [...items].sort(comp));

    }, [srt.currentField, srt.currentOrder, items.length])

    /**
     * handle when someone clicks the sort button
     * @param {string} field 
     */
    const handleSort = field => {
        if (field === srt.currentField) {
            setSrt(d => ({ ...d, currentOrder: d.currentOrder === 'asc' ? 'desc' : 'asc' }));
        } else {
            setSrt(d => ({ currentField: field, currentOrder: 'asc' }));
        }
    }


    const onDownloadClick = () => {


        infoAlert("Preparing download...");

        //title, venue, description

        const headers = {
            "reference": "Reference",
            "job_no": "Invoice No",
            "client": "Client",
            "client_contact": "Client Contact",
            "title": "Job Title",
            "category": "Category",
            "location": "Venue",
            "job_date": "Start Date",
            "end_date": "End Date",
            "deliverables": "Deliverables",
            "total_amount": "Total",
            "balance": "Balance",
            "status": "Status"
        };

        const keys = Object.keys(headers);


        let _items = {
            jobs: []
        };

        _items.jobs.push(keys.map(e => headers[e]));

        items.forEach(i => {
            _items.jobs.push(keys.map(e => {
                if (["job_date", "end_date"].indexOf(e) !== -1)
                    return moment(i[e]).format("DD MMM YYYY");

                if (["status", "category"].indexOf(e) !== -1)
                    return UCWords(i[e].replace(/-/g, " "));

                if (["total_amount", "balance"].indexOf(e) !== -1)
                    return cur(i[e], 2).format();


                return i[e];
            }));
        })

        createFile(_items, "Legacy UG Jobs.xlsx", `Jobs`);

    }



    return (

        <div className="max-1000">
            <Row xs={{ cols: "auto" }} className="align-items-center justify-content-md-end mb-3 flex-nowrap g-0">
                <Col>
                    <Button
                        size="sm"
                        variant="outline-secondary"
                        className='rounded-pill mx-1'
                        onClick={onDownloadClick}
                    >
                        <i className='fas fa-download me-1' />Download
                    </Button>
                </Col>
                <Col>

                    <Form.Control placeholder="Search For" size="sm"
                        value={search.val}
                        onChange={e => setSearch({ ...search, val: e.currentTarget.value })}
                    />
                </Col>
                <Col>
                    <span className="mx-1">in</span>
                </Col>
                <Col>
                    <Form.Select
                        size="sm"
                        value={search.field}
                        onChange={e => setSearch({ ...search, field: e.currentTarget.value })}
                    >
                        <option value="title">Title</option>
                        <option value="job_no">Job No</option>
                        <option value="reference">Reference</option>
                        <option value="client">Client</option>
                        <option value="client_contact">Client Contact</option>
                        <option value="location">Location</option>
                        <option value="job_date">Date</option>
                        <option value="status">Status</option>
                    </Form.Select>
                </Col>


            </Row>

            {screenType === 'desktop' ?
                <ViewDesktop srt={srt} handleSort={handleSort} items={displayItems} noPageItems={noPageItems} currentPage={PAGE} /> :
                <ViewMobile srt={srt} handleSort={handleSort} items={displayItems} />
            }

            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                <Row xs={{ cols: "auto" }} className="align-items-center g-0 flex-nowrap mb-2">

                    <Col className="me-2 text-nowrap">No Items:</Col>
                    <Col>
                        <Form.Select
                            value={noPageItems}
                            onChange={e => setNoPageItems(parseInt(e.currentTarget.value))}
                            size="sm"
                            style={{ maxWidth: '100px' }}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Form.Select>
                    </Col>

                    <Col className="ms-2 text-nowrap">{items.length} of {orginalItems.length}</Col>
                </Row>
                <PagePagination
                    page={PAGE}
                    no_pages={NOPAGES}
                    setPage={setPage}
                />
            </div>
        </div>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/jobs').JobObject[]} props.events 
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/jobs').JobObject[]>>} props.setEvents 
 * @returns 
 */
const CalendarView = ({ events, setEvents, jobs }) => {


    const [show, setShow] = useState(false);
    const [item, setShowItem] = useState({});
    const calendarRef = useRef();
    const navigate = useNavigate();

    const screenType = useScreenType();

    const onClickEvent = ({ event }) => {
        let { id, extendedProps: { __id } } = event;

        id = __id || id;

        const item = jobs.find(e => e.id === (id));
        setShowItem(item);
        setShow(true);

    }

    const onDateClick = ({ dateStr }) => {

        const api = calendarRef.current.getApi(),
            _date = moment(dateStr).format('dddd Do MMMM YYYY');
        api.gotoDate(dateStr);
        api.changeView('listDay');

        info(
            `Click here if you want to create a job on ${_date}`,
            "Create New Job?",
            {
                onclick: () => navigate(`/app/jobs/new?date=${dateStr}`)
            })

        info(
            `Click here if you want to create an expense incurred on ${_date}`,
            "Create New Expense?",
            {
                onclick: () => navigate(`/app/expenses/new?date=${dateStr}`)
            })
    }

    const onEventChange = ({ event }) => {
        let { id, extendedProps: { __id }, start, end } = event;

        start = moment(start).format("YYYY-MM-DD");

        if (end) {
            end = moment(end).subtract(1, 'd').format("YYYY-MM-DD")
        } else {
            end = start;
        }

        // infoAlert(`Updating dates ...is ${moment(start).format("dd DD MMM YYYY")} to ${moment(end).format("dd DD MMM YYYY")}`);
        infoAlert(`Updating dates...`);

        let promise;

        // if __id then it is an event date. 
        // and the __id is the id of the event. 
        // and id is the id of the event date.
        if (__id) {
            promise = updateDate({
                start,
                end
            }, __id, id)
        } else {
            promise = updateJob({
                id,
                job_date: start,
                end_date: end
            }, id)
        }

        promise
            .then(({ job_date, job }) => {

                // console.log(job_date, job);

                let _events = [...events],
                    idx, e;

                if (job_date) {
                    idx = _events.findIndex(e => e.__id === job_date.id);
                    e = _events[idx];
                    _events[idx] = { ...e, job_date: job_date.start, end_date: job_date.end }
                } else if (job) {
                    idx = _events.findIndex(e => e.id === job.id);
                    e = _events[idx];
                    _events[idx] = { ...e, job_date: job.job_date, end_date: job.end_date }
                }

                setEvents(_events);
                successAlert("Successfully updated the event's dates");
            })
            .catch(e => errorAlert(`Failed to update date: ${e}`))
    }


    // const handleJobCancel = () => {
    //     setShow(false);
    // }

    return (
        <div className="max-1000">
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, bootstrapPlugin, listPlugin, interactionPlugin]}
                editable
                themeSystem="bootstrap"
                initialView="listWeek"
                firstDay={1}
                displayEventEnd={true}
                eventDataTransform={e => {
                    if (e.allDay) {
                        e.end = moment(e.end).add(23, 'hour').format("YYYY-MM-DD");
                    }
                    return e;
                }}
                events={events.map(e => {

                    let isUnconfirmed = (unconfirmedJobStatuses.indexOf(e.status) !== -1),
                        isCancelled = (e.status === 'cancelled'),
                        color = EventColourMaping[e.colour],
                        bgColor = "", borderColor = "";


                    if (isCancelled) {
                        bgColor = borderColor = "rgba(108,117,125,.7)";
                    } else if (isUnconfirmed) {
                        bgColor = borderColor = "rgba(" + color.join(",") + ",0.5)";

                    } else {
                        bgColor = borderColor = "rgba(" + color.join(",") + ",1)";
                    }

                    return {
                        ...e,
                        start: e.job_date,
                        end: e.end_date + " 12:00:00",
                        allDay: true,
                        title: `${e.title} - ${e.client} (${e.job_no} )`,
                        backgroundColor: bgColor,
                        borderColor: borderColor,
                        // classNames: EventColourMaping[e.colour]
                    }
                })}
                headerToolbar={
                    {
                        start: 'title', // will normally be on the left. if RTL, will be on the right
                        center: screenType === "desktop" ? 'dayGridMonth,listWeek' : "dayGridMonth",
                        end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
                    }
                }
                height={screenType === 'desktop' ? null : "80vh"}
                eventClick={onClickEvent}
                dateClick={onDateClick}
                eventChange={onEventChange}
                eventOrder="-amount,title"
            />

            <Modal show={show} centered>
                <Modal.Header className="bg-secondary text-light">
                    <Modal.Title>{item.job_no}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Client
                        </Col>
                        <Col sm={8}>
                            <Link to={`/app/clients/${item.client_id}`}>{item.client}</Link>
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Contact
                        </Col>
                        <Col sm={8}>
                            {item.client_contact}
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Date
                        </Col>
                        <Col sm={8}>
                            {moment(item.job_date).format("dddd DD MMMM YYYY")}
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Event
                        </Col>
                        <Col sm={8}>
                            {item.title}
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Category
                        </Col>
                        <Col sm={8}>
                            {UCWords(item.category?.replace(/-/g, " ") || "")}
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Location
                        </Col>
                        <Col sm={8}>
                            {item.location}
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Status
                        </Col>
                        <Col sm={8}>
                            {UCWords(item.status?.replace(/-/g, " "))}
                        </Col>
                    </Row>

                    {/* {(profile.permission_level < 4) &&
                        <> */}


                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Total
                        </Col>
                        <Col sm={8}>
                            {UGX(item.total_amount).format()}
                        </Col>
                    </Row>
                    <Row className="my-1">
                        <Col sm={3} className="text-secondary text-uppercase small">
                            Balance
                        </Col>
                        <Col sm={8}>
                            {UGX(item.balance).format()}
                        </Col>
                    </Row>
                    {/* </>
                    } */}

                </Modal.Body>

                <Modal.Footer className="py-1">
                    <Link to={`/app/jobs/${item.id}/packing-list`} className='small'>
                        <i className="fas fa-store me-1" />Packing List
                    </Link>
                    {(confirmedJobStatuses.indexOf(item.staus) !== -1) &&
                        <Link to={`/app/payments/new?job=${item.id}&client=${item.client_id}`} className="text-secondary btn btn-sm btn-link">
                            <i className="fas fa-credit-card me-1" />Add Payment
                        </Link>
                    }
                    <Link to={`/app/jobs/${item.id}/edit`} className="text-danger btn btn-sm btn-link">
                        <i className="fas fa-pencil-alt me-1" />Edit
                    </Link>
                    <Link to={`/app/jobs/${item.id}`} className="text-primary btn btn-sm btn-link">
                        <i className="fas fa-info-circle me-1" />More Info
                    </Link>

                    {/* <ConfirmAction
                        as={Button}
                        variant="link" size="sm" className="text-danger"
                        title="Cancel"
                        confirmationText="Are you sure you want to cancel this job?"
                        handleAction={handleJobCancel}
                    >
                        <i className="fas fa-times-circle me-1" />Cancel
                    </ConfirmAction> */}
                    <Button variant="link" size="sm" className="text-dark" onClick={() => setShow(false)}>
                        <i className="fas fa-times-circle me-1" />Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

/**
 * module that handles view of clients
 * match path /app/events
 */
const ViewJobs = ({ view = "calendar" }) => {

    const navItems = [{ title: 'Jobs' }];

    const [events, setEvents] = useState([]);
    const [calEvents, setCalEvents] = useState([]);

    const [jobs, setJobs] = useState([]);
    const [job_dates, setJobDates] = useState([]);

    const [searchParams] = useSearchParams();

    const [error, setError] = useState();
    const [loaded, setLoaded] = useState();
    const [jobview, setView] = useState("confirmed");


    const [dateShow, setDateShow] = useState(false);
    const [dateValues, setDateValues] = useState({ start: "", end: "" })

    useEffect(() => {

        const _view = searchParams.get("view") || "default";
        setView(_view);

    }, [searchParams])



    /**
     * get the items
     */
    useEffect(() => {

        getJobs(true)
            .then(({ jobs, job_dates }) => {

                setJobs(jobs);
                setJobDates(job_dates);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))
    }, [])


    useEffect(() => {

        let _jobs = [...jobs], _job_dates = [...job_dates], jobids;
        let datenow = moment().format("YYYY-MM-DD");

        switch (jobview) {
            case "unconfirmed":
                _jobs = _jobs.filter(i => (unconfirmedJobStatuses.indexOf(i.status) !== -1));

                jobids = _jobs.map(j => j.id);
                _job_dates.filter(d => (jobids.indexOf(d.job_id) !== -1));
                break;
            case "future":
                _jobs = _jobs.filter(i => i.job_date >= datenow);
                _job_dates = _job_dates.filter(i => i.start >= datenow);
                break;
            case "past":
                _jobs = _jobs.filter(i => i.job_date < datenow);
                _job_dates = _job_dates.filter(i => i.start < datenow);
                break;
            case "confirmed":
                _jobs = _jobs.filter(i => (confirmedJobStatuses.indexOf(i.status) !== -1));
                jobids = _jobs.map(j => j.id);
                _job_dates.filter(d => jobids.indexOf(d.job_id) !== -1);
                break;
            case "cancelled":
                _jobs = _jobs.filter(i => (['cancelled'].indexOf(i.status) !== -1));
                jobids = _jobs.map(j => j.id);
                _job_dates.filter(d => jobids.indexOf(d.job_id) !== -1);
                break;

            case "default":
                _jobs = _jobs.filter(i => (['cancelled'].indexOf(i.status) === -1));
                jobids = _jobs.map(j => j.id);
                _job_dates.filter(d => jobids.indexOf(d.job_id) !== -1);
                break;

            default:
        }


        setEvents(_jobs);
        setCalEvents([
            ..._jobs,
            ..._job_dates.map(d => ({
                ...jobs.find(j => j.id === d.job_id),
                ...d,
                __id: d.job_id,
                id: d.id,
                job_date: d.start,
                end_date: d.end
            }))
        ])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobview, JSON.stringify(jobs), JSON.stringify(job_dates)]);



    const onDownloadClick = () => {


        infoAlert("Preparing download...");


        const headers = {
            "reference": "Reference",
            "job_no": "Invoice No",
            "client": "Client",
            "client_contact": "Client Contact",
            "title": "Job Title",
            "category": "Category",
            "location": "Venue",
            "job_date": "Start Date",
            "end_date": "End Date",
            "deliverables": "Deliverables",
            "total_amount": "Total",
            "balance": "Balance",
            "status": "Status"
        };

        const keys = Object.keys(headers);


        let _items = {
            jobs: []
        };

        _items.jobs.push(keys.map(e => headers[e]));

        events.forEach(i => {
            _items.jobs.push(keys.map(e => {
                if (["job_date", "end_date"].indexOf(e) !== -1)
                    return moment(i[e]).format("DD MMM YYYY");

                if (["status", "category"].indexOf(e) !== -1)
                    return UCWords(i[e].replace(/-/g, " "));

                if (["total_amount", "balance"].indexOf(e) !== -1)
                    return cur(i[e], 2).format();


                return i[e];
            }));
        })

        createFile(_items, "Legacy UG Jobs.xlsx", `${views[view]} Jobs`);

    }


    const onDownloadPackingListClick = () => {

        if (dateValues.end <= dateValues.start) {
            return errorAlert("End Date must be after start date");
        }

        setDateShow(false);

        infoAlert("Fetching products");

        getJobsPackingList(dateValues.start, dateValues.end)
            .then(({ packing_list }) => {
                const headers = {
                    "job_no": "Reference",
                    "job_date": "Start Date",
                    "end_date": "End Date",
                    "client": "Client",
                    "job_title": "Title",
                    "location": "Job Location",
                    "item": "Item",
                    "quantity": "Quantity",
                    "days": "No Days"
                };

                packing_list.sort((a, b) => sortFunction(a, b, "job_no", "asc"));
                packing_list.sort((a, b) => sortFunction(a, b, "job_date", "asc"));

                const keys = Object.keys(headers);


                let items = {
                    products: []
                };

                items.products.push(keys.map(e => headers[e]));

                packing_list.forEach(i => {
                    items.products.push(keys.map(e => (e === "event_date" || e === "job_date") ? moment(i[e]).format("dddd DD MMM YYYY") : i[e]));
                })

                createFile(items, "Legacy UG Packing List.xlsx", "Weekly Products");
            })
            .catch(e => errorAlert(e))
    }


    if (!loaded) return <PageLoading>Loading the events/jobs ...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    const views = {
        default: "Default",
        all: "All",
        unconfirmed: "Unconfirmed",
        future: "Future",
        past: "Past",
        confirmed: "Confirmed",
        cancelled: "Cancelled"
    }

    return (
        <>
            <Nav items={navItems} />

            <PageHeader maxWidth="1000">
                <Title>Jobs</Title>
                <ActionBar>
                    <ButtonToolbar>

                        {view === "calendar" ?
                            <Btn href="/app/jobs/table" title="Table View">
                                <i className="fas fa-table me-1" />
                            </Btn>
                            :
                            <Btn href="/app/jobs/calendar" title="Calendar View">
                                <i className="far fa-calendar-alt" />
                            </Btn>
                        }
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn onClick={onDownloadClick} title="Download All Events In View">
                            <i className="fas fa-cloud-download-alt" />
                        </Btn>
                        <Btn onClick={() => setDateShow(true)} title="Download Packing List">
                            <i className="fas fa-store" />
                        </Btn>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href="/app/invoices/new">
                            <i className="fas fa-file-invoice me-1" />New Quote
                        </Btn>
                        <Btn href="/app/jobs/new">
                            <i className="fas fa-plus-circle me-1" />New Job
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className='max-1000 mb-2'>

                <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                        View: <strong>{views[jobview]}</strong>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {Object.keys(views).map(v => <Dropdown.Item as={Link} key={v} to={`/app/jobs/${view}?view=${v}`}>{views[v]}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
            </div>


            {view === "calendar" ?
                <CalendarView events={calEvents} setEvents={setCalEvents} jobs={jobs} /> :
                <TableView events={events} />
            }

            <Modal show={dateShow} centered>
                <Modal.Header className="bg-secondary text-light">
                    <Modal.Title>Weekly Packing List</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Row>
                        <Col md={6}>
                            <FormLabel>Start Date</FormLabel>
                            <DateSelector
                                minDate={false}
                                value={dateValues.start}
                                onChange={date => setDateValues(d => ({ ...d, start: date }))}
                            />
                        </Col>
                        <Col md={6}>
                            <FormLabel>End Date</FormLabel>
                            <DateSelector
                                value={dateValues.end}
                                onChange={date => setDateValues(d => ({ ...d, end: date }))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mt-3 mt-md-4 text-end'>
                            <a className='btn rounded-pill btn-sm mx-1 btn-secondary btn-sm'
                                href={`${APIURL}/jobs/packing-list/pdf?start_date=${dateValues.start}&end_date=${dateValues.end}&view=${jobview}`}
                                rel='noreferrer'
                                target='_blank'
                            >
                                <i className='fas fa-file-pdf me-1' />PDF
                            </a>
                            <Button size="sm" className='rounded-pill mx-1' onClick={onDownloadPackingListClick}>
                                <i className='fas fa-file-excel me-1' />Excel
                            </Button>
                            <Button size="sm" className='rounded-pill ms-1' onClick={() => setDateShow(false)} variant="danger">
                                <i className='fas fa-times-circle me-1' />Close
                            </Button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>


        </>
    )
}


export { CalendarView as EventCalendar };

export default ViewJobs;
