import { Form, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import { DateSelector } from './../../components/datetimepicker';
import { errorAlert, infoAlert, successAlert } from './../../components/toastr';
import moment from 'moment';
import { SubmitButton, CancelButton } from './../../components/btns';
import { ExpenseItems } from './../../components/finances';
// import File from './../../components/files';
import { paymentMethods } from '../../assets/constants';
import { UCWords } from '../../components/resources';
import { createExpense, updateExpense } from '../../resources/api/expenses';
import SelectInput from '../../components/select';
import { useEffect } from 'react';
import { getClients } from '../../resources/api/clients';
import FileInput from '../../components/files';
import { getJobs } from '../../resources/api/jobs';
import { getAccounts } from '../../resources/api/accounts';
import { getVendors } from '../../resources/api/vendors';


const { Control, Control: { Feedback }, Label, Text } = Form;


/**
 * 
 * @param {Object} props
 * @param {"create"|"edit"} props.action 
 * @param {import('../../resources/api/expenses').ExpenseObject} props.details 
 * @param {(details: import('../../resources/api/expenses').ExpenseObject) => void} props.onSubmit 
 */
const ExpenseForm = ({ action = "create", details: expenseObject, onSubmit }) => {

    const [details, setDetails] = useState(expenseObject);
    const [items, setItems] = useState(expenseObject.items || []);
    const [deleted_items, setDeletedItems] = useState([]);

    const [files, setFiles] = useState([]);
    const [clients, setClients] = useState([]);
    const [events, setEvents] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [vendors, setVendors] = useState([]);

    const [validated, setValidated] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);


    useEffect(() => {

        infoAlert("Loading the clientele & accounts lists...");

        Promise.all([getClients(), getAccounts(), getVendors()])
            .then(([{ clients }, { accounts }, { vendors }]) => {
                setClients(
                    clients.map(c => ({ ...c, description: c.address }))
                );
                setVendors(
                    vendors.map(c => ({ ...c, description: c.address }))
                );
                setAccounts(accounts.filter(a => a.isActive));

            })
            .catch(e => errorAlert(e))
    }, []);

    useEffect(() => {

        setDetails(d => ({ ...d, isBillable: false, markup: "" }));
        setEvents([]);

        if (!details.client_id) return;

        infoAlert("Loading jobs for this client");

        getJobs(true, { client_id: details.client_id })
            .then(({ jobs }) => {

                setEvents(jobs.map(e => ({
                    ...e,
                    title: `${e.job_no} - ${e.title}`,
                    description: `${e.reference} | ${e.location} | ` + moment(e.job_date).format("DD MMMM YYYY")
                })));
                successAlert("Jobs have been loaded");
            })
            .catch(e => errorAlert(`Could not get client jobs. Reason: ${e}`))

    }, [details.client_id])

    // useEffect(() => {

    //     if (details.isBill) {
    //         setDetails(d => ({ ...d, isItemized: true }))
    //         infoAlert("Given this is a bill, expense must be itemised.")
    //     }

    // }, [details.isBill])



    // const tax = useMemo(() => {

    //     const _tax = parseFloat(details.tax),
    //         _amount = parseFloat(details.amount);

    //     if (_tax === 0 || !_tax || _amount === 0 || !_amount) return 0;


    //     if (details.isTaxInclusive) {
    //         return _amount - (_amount / (1 + _tax / 100));
    //     } else {
    //         return _tax / 100 * _amount;
    //     }
    // }, [details.isTaxInclusive, details.amount, details.tax]);




    /**
     * handle the overall submitting of the form
     * @param {React.FormEvent} e
     */
    const handleSubmit = e => {
        const form = e.currentTarget;

        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("You have errors in your form. These have been highlighted for you.", "Form Errors");
            return;
        }

        setValidated(false);
        setSubmitting(true);

        const data = {
            ...details,
            isBill: details.isBill ? 1 : 0,
            isItemized: details.isItemized ? 1 : 0,
            isBillable: details.isBillable ? 1 : 0,
            isTaxInclusive: details.isTaxInclusive ? 1 : 0,
            tax_percent: details.tax_percent || 0
        }

        let promise;

        if (action === "create") {
            data.items = items;
            promise = createExpense(data);
        } else {
            promise = updateExpense({
                ...data,
                new_items: items.filter(i => i.isNew),
                updated_items: items.filter(i => (!!i.id && i.hasChanged)),
                deleted_items
            }, data.id)
        }

        promise
            .then(({ expense, message }) => {
                successAlert(message);
                onSubmit(expense);
            })
            .catch(e => {
                setSubmitting(false);
                errorAlert(e);
            })

    }


    return (

        <Form className="max-800" validated={validated} noValidate onSubmit={handleSubmit}>
            <h4 className="form-section-label mt-5">Details</h4>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Vendor</Label>
                    <SelectInput
                        value={[details.vendor_id]}
                        onChange={([id]) => setDetails(d => ({
                            ...d,
                            vendor_id: id || ""
                        }))}
                        maxItems={1}
                        options={vendors}
                        placeholder="-"
                    />
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Date</Label>
                    <DateSelector
                        minDate={moment().add(-1, 'months').format('YYYY-MM-DD')}
                        maxDate={0}
                        value={details.expense_date}
                        onChange={val => setDetails(d => ({ ...d, expense_date: val }))}
                        required
                    />
                    <Feedback type="invalid">
                        You must select an expense date.
                    </Feedback>
                </Col>
            </Row>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Your Reference</Label>
                    <Control
                        maxLength={60}
                        value={details.user_reference}
                        onChange={e => setDetails({ ...details, user_reference: e.currentTarget.value })}
                    />
                    <Text muted>
                        Something to remember this expense by. Should be less than 60 characters. (Optional)
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Method</Label>
                    <Form.Select
                        as="select"
                        value={details.method}
                        onChange={e => setDetails({ ...details, method: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {paymentMethods.map(c => <option key={c} value={c}>{UCWords(c).replace(/-/g, " ")}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select a method for payment for this expense.
                    </Feedback>
                </Col>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Payment Account</Label>
                    <Form.Select
                        as="select"
                        value={details.account_id}
                        onChange={e => setDetails({ ...details, account_id: e.currentTarget.value })}
                        required
                    >
                        <option value=""></option>
                        {accounts.map(c => <option key={c.id} value={c.id}>{c.title}</option>)}
                    </Form.Select>
                    <Feedback type="invalid">
                        You must select the account from which this expense is coming.
                    </Feedback>
                </Col>
            </Row>


            <Row>
                <Col sm={12} className="my-1">
                    <Label className="form-field-title">Notes/Description</Label>
                    <Control
                        as="textarea"
                        rows={2}
                        onFocus={e => e.currentTarget.rows = 5}
                        onBlur={e => e.currentTarget.rows = 2}
                        value={details.description || ""}
                        onChange={e => setDetails({ ...details, description: e.currentTarget.value })}
                    />
                    <Feedback type="invalid">
                        If the payment method is not cash, fees should be provided. Put 0 is none.
                    </Feedback>
                </Col>
            </Row>

            <h4 className="form-section-label mt-5">Items</h4>
            <Form.Check
                className=""
                type="switch"
                id="custom-switch"
                checked={details.isTaxInclusive}
                onChange={() => setDetails(d => ({ ...d, isTaxInclusive: !d.isTaxInclusive }))}
                label={details.isTaxInclusive ? "Prices are tax inclusive." : "Prices are not tax inclusive."}
            />

            <ExpenseItems
                expenseitems={{ items, setItems }}
                deleteditems={{ deleted_items, setDeletedItems }}
                isTaxInclusive={details.isTaxInclusive}
            />



            <h4 className="form-section-label mt-5">Other Details</h4>
            <Text muted>
                Add the expense to an a client and even further a job to track particular job expenses.
            </Text>

            <Row>
                <Col sm={6} className="my-1">
                    <Label className="form-field-title">Client</Label>
                    <SelectInput
                        value={[details.client_id]}
                        onChange={([id]) => setDetails(d => ({ ...d, client_id: id || "" }))}
                        options={clients}
                        placeholder="-"
                        maxItems={1}
                        errorText="The client must exist in the existing lists of clients."
                    />
                </Col>

                {!!details.client_id &&
                    < Col sm={6} className="my-1">
                        <Label className="form-field-title">Job</Label>
                        <SelectInput
                            value={[details.job_id]}
                            placeholder="-"
                            onChange={([id]) => setDetails(d => ({ ...d, job_id: id || "" }))}
                            options={events}
                            required
                            maxItems={1}
                            errorText="The client must exist in the existing lists of clients."
                            sortBy="event_date"
                        />
                    </Col>
                }
            </Row>


            <Row>
                <Col sm={6} className="my-2">
                    <Label className="form-field-title">Receipt(s)</Label>
                    <FileInput maxFiles={5} onChange={files => setFiles(files)} value={files} />
                </Col>
            </Row>

            <Row>
                <Col className="mt-4 mb-3 text-end">
                    <SubmitButton isSubmitting={isSubmitting} type="submit">
                        Submit
                    </SubmitButton>
                    <CancelButton isSubmitting={isSubmitting}>
                        Cancel
                    </CancelButton>
                </Col>
            </Row>

        </Form >
    )
}


export default ExpenseForm;