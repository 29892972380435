import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { ListGroup, Table } from 'react-bootstrap';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import currency from '../../components/currency';
import style from './expenses.scss';
import { useEffect, useState } from 'react';
import { deleteDocument, getExpense, uploadDocument } from '../../resources/api/expenses';
import { ErrorLoading, PageLoading } from '../../components/loading';
import { EditExpense } from './expense-manipulation';
import moment from "moment";
import { UCWords } from '../../components/resources';
import cur from '../../components/currency';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { fileIcons } from '../../assets/constants';
import { APIURL } from '../../resources/fetch';
import { ConfirmAction } from '../../components/prompt';
import { FileUpload } from '../../components/files';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/expenses').ExpenseObject} props.details
 * @param {React.Dispatch<React.SetStateAction<import('../../resources/api/expenses').ExpenseObject>>} props.setDetails
 * 
 */
const ViewExpense = ({ details, setDetails }) => {

    const nav_items = [
        { title: 'Expenses', href: '/app/expenses' },
        { title: details.reference }
    ];


    const handleDocUpload = (document) => setDetails(d => ({ ...d, documents: d.documents.concat(document) }))

    const handleDocDelete = id => setDetails(d => ({ ...d, documents: d.documents.filter(dd => dd.id !== id) }));

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.reference} {!!details.user_reference && <>({details.user_reference})</>}
                </Title>
                <ActionBar>


                    <ButtonToolbar>

                        {!(details.fromRequisition || details.fromPayroll) &&
                            <Btn href={`/app/expenses/${details.id}/edit`} title="Edit Expense">
                                <i className="fas fa-pencil-alt" />
                            </Btn>
                        }

                        <Btn href="/app/expenses/new" title="New Expense">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-800 p-1 expenses">

                <div className="text-end">
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    {!!details.user_reference && <>
                        USER REF: <span style={style1}>{details.user_reference}</span> <br />
                    </>}
                    EXPENSE DATE: <span style={style1}>{moment(details.expense_date).format("DD MMMM YYYY")}</span> <br />
                </div>

                {/* <div className="mt-5 mb-2">
                    <h5>Paid By</h5>
                    <span className="lead">Maurice Ntege</span> <br />
                    morsnegs@hotmail.com
                </div> */}

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_amount).format()}</span>
                    </span>
                </div>

                <Table size="sm" borderless style={{ maxWidth: '500px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "50%" }} />
                        <col span="1" style={{ width: "50%" }} />
                    </colgroup>

                    <tbody>
                        {details.tax_amount > 0 &&
                            <>
                                <tr>
                                    <td>Pretax Amount</td>
                                    <td>UGX {currency(details.pretax_amount).format()}</td>
                                </tr>
                                <tr>
                                    <td>Tax</td>
                                    <td>UGX {currency(details.tax_amount).format()}</td>
                                </tr>
                            </>

                        }
                        {!!details.vendor_id &&
                            <tr>
                                <td>Vendor</td>
                                <td><Link to={`/app/vendors/${details.vendor_id}`} > {details.vendor.title}</Link></td>
                            </tr>
                        }
                        <tr>
                            <td>Prices Tax Inclusive?</td>
                            <td>{details.isTaxInclusive ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td>Account</td>
                            <td>{details.account.title} via {UCWords(details.method.replace(/-/g, " "))}</td>
                        </tr>
                        {!!details.client_id &&
                            <tr>
                                <td>Client</td>
                                <td><Link to={`/app/clients/${details.client_id}`} > {details.client.title}</Link> </td>
                            </tr>
                        }
                        {!!details.job_id &&
                            <tr>
                                <td>Job</td>
                                <td><Link to={`/app/jobs/${details.job_id}`} > {details.job.title} - {details.job.job_no}</Link> </td>
                            </tr>
                        }
                        {details.fromRequisition &&
                            <tr>
                                <td colSpan={2} className='fw-bold'>This expense is from a requisition.</td>
                            </tr>
                        }
                        {details.fromPayroll &&
                            <tr>
                                <td colSpan={2} className='fw-bold'>This expense is from a salary payroll.</td>
                            </tr>
                        }
                    </tbody>
                </Table>

                {(details.items.length > 0) && <ExpenseItems items={details.items} />}


                <ViewDocuments documents={details.documents || []} onDocUpload={handleDocUpload} onDocDelete={handleDocDelete} expense_id={details.id} />


            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/expenses').ExpensetItemObject[]} props.items 
 * @returns 
 */
const ExpenseItems = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Items</h5>

        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "35%", textAlign: 'center' }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "15%" }} />
                <col span="1" style={{ width: "22%" }} />
            </colgroup>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Item</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Tax</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td>
                            {UCWords(i.category.replace(/-/g, " "))}
                            <p className='text-muted small my-0' style={{ lineHeight: 1 }} >{i.notes}</p>
                        </td>
                        <td>{i.quantity}</td>
                        <td>{cur(i.unit_price, 0).format()}</td>
                        <td>{i.tax_percent}%</td>
                        <td>{cur(i.quantity * i.unit_price, 0).format()}</td>
                    </tr>
                ))}
            </tbody>

        </Table>

    </div>
)


const ViewDocuments = ({ documents, onDocUpload, onDocDelete, expense_id }) => {



    const deleteDoc = (id) => {
        infoAlert("Deleting Document...");

        deleteDocument(expense_id, id)
            .then(({ message }) => {
                successAlert(message);
                onDocDelete(id);
            })
            .catch(errorAlert)
    }

    const uploadDoc = doc => {
        infoAlert("Attaching document to expense...");

        uploadDocument(expense_id, { file_id: doc.id })
            .then(({ document }) => {
                onDocUpload(document);
            })
            .catch(errorAlert)
    }

    return (
        <>
            <h4 className="mt-3 mt-md-5">Documents</h4>

            {(documents.length === 0) &&
                <>
                    <p className="lead">
                        No documents have been uploaded for this expense.
                    </p>
                </>
            }
            <ListGroup variant="flush">
                {documents.map(d => (
                    <ListGroup.Item key={d.id} className="d-flex align-items-center">

                        <i className={`${fileIcons[d.file_group]} fa-3x me-3 me-sm-4`} />
                        <div>
                            <h6 className="mb-1">{d.file_title}</h6>
                            {/* <p className="mb-0 text-muted">
                                {UCWords(d.category.replace(/-/g, " "))}
                            </p> */}
                            <p className="mb-1 text-muted">
                                {d.display_name} | {moment(d.date_added).format("DD MMM YYYY, HH:mm")}
                            </p>
                            <div className="hstack gap-3 small">
                                <a href={`${APIURL}/files/${d.file_slug}/view`} className="text-secondary" target={`_blank`}>
                                    <i className="fas fa-eye me-1" />View
                                </a>
                                <a href={`${APIURL}/files/${d.file_id}/download`} className="text-primary" target={`_blank`}>
                                    <i className="fas fa-download me-1" />Download
                                </a>
                                <ConfirmAction
                                    as={"a"}
                                    handleAction={() => deleteDoc(d.id)}
                                    href="#."
                                    className="text-danger"
                                    target="_blank"
                                    confirmationText="Are you sure you want to delete this document?"
                                >
                                    <i className="fas fa-trash-alt me-1" />Delete
                                </ConfirmAction>
                            </div>
                        </div>

                    </ListGroup.Item>
                ))}
            </ListGroup>

            <div className="my-2">
                <FileUpload
                    size="sm"
                    variant=""
                    className="rounded-pill"
                    onUpload={uploadDoc}
                >
                    <i className='fas fa-upload me-1' />Upload Document
                </FileUpload>
            </div>
        </>
    )
}

const ExpenseDetails = () => {

    const { expenseid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getExpense(expenseid, ['account', 'items', 'client', 'job', 'documents', 'vendor'])
            .then(({ expense }) => {
                setDetails(expense);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [expenseid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading expense details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditExpense details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewExpense details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default ExpenseDetails;